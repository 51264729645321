import { Fragment, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { useAuth } from 'src/context/auth/use-auth';
import { useConfirmEmailChangeMutation, ProfileDocument } from 'src/graphql/schema';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';

const EmailFormSchema = z
  .object({
    oldEmail: z.string().email(),
    email: z.string().email(),
    confirmEmail: z.string().email(),
  })
  .refine((data) => data.email !== data.oldEmail, {
    message: 'Email has not changed',
    path: ['email'],
  })
  .refine((data) => data.email === data.confirmEmail, {
    message: 'Emails do not match',
    path: ['confirmEmail'],
  });

const CodeFormSchema = z.object({
  code: z.string().min(6).max(6),
});

const EmailCard = ({ email = '' }) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const { changeEmail } = useAuth();
  const [confirmEmailChange, { loading: loadingConfirmation }] = useConfirmEmailChangeMutation({
    refetchQueries: [ProfileDocument],
  });

  const onSubmitEmail = async (dataForm: z.infer<typeof EmailFormSchema>) => {
    try {
      await changeEmail(dataForm.email);
      setIsCodeSent(true);
      setNewEmail(dataForm.email);
    } catch (e: unknown) {
      if (e instanceof Error) toast.error(e.message);
    }
  };

  const onSubmitCode = async ({ code = '' }) => {
    try {
      const { data } = await confirmEmailChange({ variables: { code } });

      if (data?.confirmEmailChange?.success) {
        setIsCodeSent(false);
        setNewEmail('');
        toast.success('Email updated!', {
          description: 'Your email address and login details have been updated.',
        });
      } else {
        throw new Error(data?.confirmEmailChange?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error = e as Error & { code: string };

        if (error.code === 'CodeMismatchException' || error.code === 'InvalidParameterException') {
          toast.error('Invalid confirmation code, please check your email again!');
        } else {
          toast.error(e.message || 'Something went wrong!');
        }
      }
    }
  };

  const emailForm = useForm<z.infer<typeof EmailFormSchema>>({
    resolver: zodResolver(EmailFormSchema),
    defaultValues: {
      oldEmail: email,
      email: '',
      confirmEmail: '',
    },
    mode: 'onChange',
  });

  const codeForm = useForm<z.infer<typeof CodeFormSchema>>({
    resolver: zodResolver(CodeFormSchema),
    defaultValues: {
      code: '',
    },
  });

  return (
    <Fragment>
      <Card className="mb-4">
        <Form {...emailForm}>
          <form onSubmit={emailForm.handleSubmit(onSubmitEmail)}>
            <CardHeader>
              <CardTitle className="text-xl">Change email</CardTitle>
              <CardDescription>Update your email to keep your account up to date.</CardDescription>
            </CardHeader>
            <CardContent className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormField
                control={emailForm.control}
                name="oldEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Current email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={emailForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={emailForm.control}
                name="confirmEmail"
                render={({ field }) => (
                  <FormItem className="sm:col-start-2">
                    <FormLabel>Confirm new email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="border-t border-border justify-end py-4">
              <Button
                type="submit"
                isLoading={emailForm.formState.isSubmitting}
                disabled={!emailForm.formState.isValid}
              >
                Save
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>

      <Dialog open={isCodeSent} onOpenChange={setIsCodeSent}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-base font-bold">Change email</DialogTitle>
            <DialogDescription className="text-xs">
              Please check your email {newEmail} for the confirmation code.
            </DialogDescription>
          </DialogHeader>
          <Form {...codeForm}>
            <form onSubmit={codeForm.handleSubmit(onSubmitCode)}>
              <FormField
                control={codeForm.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirmation code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-between mt-6">
                <Button variant="outline" type="button" onClick={() => setIsCodeSent(false)}>
                  Cancel
                </Button>
                <Button type="submit" isLoading={loadingConfirmation}>
                  Save changes
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EmailCard;
