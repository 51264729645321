import { QueryRef, useBackgroundQuery } from '@apollo/client';
import { useSearchParams } from 'react-router';

import { apolloClient as client } from 'src/graphql/client/apollo-client';
import { createRequiredContext } from 'src/context/createRequiredContext';
import {
  type IEscrowsQuery,
  EscrowsDocument,
  IEscrowsQueryVariables,
  EscrowStatusFilterEnum,
} from 'src/graphql/schema';
import { ESCROWS_LIMIT } from 'src/shared/constants';

import { setValidStatus } from '../lib';

import { useDashboardSlice } from './useDashboardSlice';

const [useDashboard, DashboardProvider] = createRequiredContext<
  ReturnType<typeof useDashboardSlice> & {
    queryRef: QueryRef<IEscrowsQuery>;
    refetchEscrows: () => void;
    status: string;
    setStatusFilter: (status: string) => void;
  }
>();

const DashboardContextProvider = ({ children }: React.PropsWithChildren) => {
  const dashboardSlice = useDashboardSlice();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = setValidStatus(searchParams.get('t')) || 'all';
  const variables: IEscrowsQueryVariables = {
    status: status as EscrowStatusFilterEnum,
    page: dashboardSlice.page,
    perPage: ESCROWS_LIMIT,
    ...(dashboardSlice.query && { query: dashboardSlice.query }),
  };
  const [queryRef] = useBackgroundQuery<IEscrowsQuery, IEscrowsQueryVariables>(EscrowsDocument, {
    variables,
  });

  const refetchEscrows = () => {
    client.cache.evict({ fieldName: 'escrows' });
    client.cache.gc();
    client.refetchQueries({
      include: [EscrowsDocument],
    });
  };

  const setStatusFilter = (status: string) => {
    setSearchParams({ t: status });
    dashboardSlice.setPage(1);
  };

  const values = {
    queryRef,
    refetchEscrows,
    status,
    setStatusFilter,
    ...dashboardSlice,
  };

  return <DashboardProvider value={values}>{children}</DashboardProvider>;
};

export { useDashboard, DashboardContextProvider };
