import { Fragment } from 'react';
import { Button } from 'antd';

import CancelPlanItem, { ICancelPlanItem } from '../../Item/CancelPlanItem';
import IconPlanOffer from '../Icon/IconPlanOffer';

import styles from './AcceptedOffer.module.scss';

type Props = {
  heading: string;
  description: string;
  plans?: ICancelPlanItem[];
  onBack: () => void;
};

const AcceptedOffer = ({ heading, description, plans = [], onBack }: Props) => {
  return (
    <Fragment>
      <div className="text-center">
        <IconPlanOffer size="large" className="mb-4 mx-auto" />
        <h2 className={styles.heading}>{heading}</h2>
        <p className={`mb-8 ${styles.text}`}>{description}</p>
      </div>

      {Boolean(plans.length) && (
        <div className={styles.list}>
          {plans.map((plan, index) => (
            <CancelPlanItem key={index} {...plan} className="mb-2" bgColor="light-blue" withBorder size="large" />
          ))}
        </div>
      )}

      <div className="text-center">
        <Button type="primary" onClick={onBack}>
          Back to Billing Page
        </Button>
      </div>
    </Fragment>
  );
};

export default AcceptedOffer;
