import { useNavigate } from 'react-router';
import { useSuspenseQuery } from '@apollo/client';
import { Fragment } from 'react';

import { Button } from '@/components/ui/button';
import { CompanyActiveSolutionsDocument, CompanyDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';

import type { ICompanyActiveSolutionsQuery, ICompanyQuery } from 'src/graphql/schema';

import { organizationStatusMap, solutionsMap } from './model';
import { HomeBanner, ActionCard, HomeSkeleton } from './ui';

const prioritySolutionsType: Record<'backup' | 'escrow' | 'saas' | 'empty', number> = {
  empty: 0,
  backup: 1,
  escrow: 2,
  saas: 3,
};

const HomeContainer = () => {
  const { data: solutionsData } = useSuspenseQuery<ICompanyActiveSolutionsQuery>(CompanyActiveSolutionsDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: companyData } = useSuspenseQuery<ICompanyQuery>(CompanyDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  const activeSolutionsMap: Record<keyof typeof solutionsMap, number> = {
    backup: solutionsData.activeBackups.nodesCount,
    escrow: solutionsData.activeEscrows.nodesCount,
    saas: solutionsData.activeSaasEnvironments.nodesCount,
    custodian: solutionsData.activeCustodianProfiles.nodesCount,
  };
  const solutionsItems = Object.keys(solutionsMap).map((key) => {
    const activeValue = activeSolutionsMap[key as keyof typeof activeSolutionsMap];

    return {
      id: key,
      ...solutionsMap[key as keyof typeof solutionsMap],
      activeValue,
    };
  });
  const currentPrioritySolution = solutionsItems.reduce((acc, { id, activeValue }) => {
    if (!acc) return id;

    return id in prioritySolutionsType &&
      Boolean(activeValue) &&
      prioritySolutionsType[id as keyof typeof prioritySolutionsType] >
        prioritySolutionsType[acc as keyof typeof prioritySolutionsType]
      ? (id as keyof typeof prioritySolutionsType)
      : acc;
  }, 'empty' as string);
  const currentOrgStatus = organizationStatusMap[currentPrioritySolution as keyof typeof organizationStatusMap];

  const onGetCertificate = () => navigate(currentOrgStatus?.certificateUrl ?? '');

  return (
    <Fragment>
      <div className="flex flex-wrap sm:flex-nowrap w-full lg:grid lg:grid-cols-4 gap-4 mb-6">
        <HomeBanner
          {...currentOrgStatus}
          subtitle={companyData.company.companyName ?? ''}
          className="lg:col-span-3 w-full"
        />

        <div className="shrink-0 w-full sm:w-[232px] lg:w-auto lg:col-span-1 p-6 border border-border rounded-lg shadow-xs">
          <div className="text-base font-bold text-card-foreground mb-1">Assure your customers</div>
          <p className="text-xs text-muted-foreground mb-6">Show your customers that their data is secured.</p>
          <Button variant="outline" disabled={currentPrioritySolution === 'empty'} size="sm" onClick={onGetCertificate}>
            Get Certificate
          </Button>
        </div>
      </div>
      <div className="h-[1px] w-full bg-border mb-6" />

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        {solutionsItems.map(({ id, ...solution }) => (
          <ActionCard key={id} {...solution} />
        ))}
      </div>
    </Fragment>
  );
};

export default withSuspense(HomeContainer, HomeSkeleton);
