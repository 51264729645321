import { useNavigate } from 'react-router';

import { SoftwareDashboardEmpty } from 'src/components/SoftwareDashboard';
import Backups from 'src/assets/images/dashboard/backups.jpg';
import BackupsPlaceholder from 'src/assets/images/dashboard/backups-placeholder.jpg';
const items = [
  'Centralize backups from Github, Gitlab, Bitbucket, and Azure DevOps in one interface.',
  'Protect your digital assets with our state-of-the-art encryption and security measures.',
  'Retrieve lost code effortlessly with our user-friendly recovery interface.',
];

export const Intro = () => {
  const navigate = useNavigate();
  const onNewBackup = () => navigate('/backups/new');

  return (
    <SoftwareDashboardEmpty
      title="Source code and application data backup made easy"
      image={Backups}
      placeholderSrc={BackupsPlaceholder}
      items={items}
      label="software backup"
      textAccent="text-accent-software-backup"
      learnMoreLink="https://codekeeper.co/software-backup"
      newButton={{
        text: 'New Backup',
        onClick: onNewBackup,
      }}
    />
  );
};

export default Intro;
