import { BackupStatusEnum } from 'src/graphql/schema';

export const statusMap = {
  [BackupStatusEnum.Active]: {
    label: 'Active',
    color: 'text-green-800',
    bgColor: 'bg-green-100',
  },
  [BackupStatusEnum.Draft]: {
    label: 'Draft',
    color: 'text-orange-800',
    bgColor: 'bg-orange-100',
  },
  [BackupStatusEnum.Removed]: {
    label: 'Removed',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
  [BackupStatusEnum.Stopped]: {
    label: 'Stopped',
    color: 'text-gray-800',
    bgColor: 'bg-gray-100',
  },
};
