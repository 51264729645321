import { DetailsCard, DetailsDialog } from './ui';
import { OrganizationContextProvider } from './model';

const OrganizationContainer = () => {
  return (
    <OrganizationContextProvider>
      <DetailsCard />
      <DetailsDialog />
    </OrganizationContextProvider>
  );
};

export default OrganizationContainer;
