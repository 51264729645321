import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { useCreateFeedbackMutation } from 'src/graphql/schema';
import { usePageMeta } from 'src/hooks';
import { ClientError, getApiError } from 'src/utils/errors';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

const feedbackSchema = z.object({
  feedback: z.string().min(1, 'Feedback is required'),
  terms: z.boolean().refine((data) => data, {
    message: 'You must agree to the terms and conditions',
  }),
});

type IFeedbackForm = z.infer<typeof feedbackSchema>;

const FeedbackForm = () => {
  usePageMeta('Feedback');
  const [createFeedback] = useCreateFeedbackMutation();
  const form = useForm<IFeedbackForm>({
    resolver: zodResolver(feedbackSchema),
    defaultValues: {
      feedback: '',
      terms: false,
    },
    mode: 'onChange',
  });
  const onSubmit = async ({ feedback }: IFeedbackForm) => {
    try {
      const { data } = await createFeedback({
        variables: {
          message: feedback,
        },
      });

      if (data?.createFeedback?.success) {
        toast.success('Feedback sent!');
        form.reset();
      } else {
        throw new ClientError(data?.createFeedback?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Share your feedback</CardTitle>
        <CardDescription>We’d love to know what’s on your mind!</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="feedback"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>Feedback</FormLabel>
                  <FormControl>
                    <Textarea {...field} className="min-h-[106px]" placeholder="Share your thoughts here." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <div className="flex items-center gap-2">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel className="text-sm font-normal mb-0">
                      I agree with the Codekeeper{' '}
                      <a
                        href="https://legal.codekeeper.co/page?page=TermsConditions"
                        target="_blank"
                        rel="noreferrer"
                        className="underline! text-foreground!"
                      >
                        terms and conditions
                      </a>
                      .
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className="w-full"
              type="submit"
              disabled={!form.formState.isValid}
              isLoading={form.formState.isSubmitting}
            >
              Share your feedback
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default FeedbackForm;
