import { ArrowRight, Mail, MailX, MailOpen } from 'lucide-react';
import { startTransition } from 'react';
import { toast } from 'sonner';
import { useFragment, gql, useSuspenseQuery } from '@apollo/client';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useDashboard } from 'src/containers/Escrows';
import {
  CompanyDocument,
  ICompanyQuery,
  useAcceptEscrowInvitationMutation,
  useRejectEscrowInvitationMutation,
  type IEscrowInvitation,
} from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { withSuspense } from 'src/hoc';

const Party = ({ name = '' }) => (
  <div className="text-base text-foreground font-semibold bg-card rounded-md p-2.5 border border-border grow text-left">
    {name}
  </div>
);

const EscrowInvitationFragment = gql`
  fragment EscrowInvitationFragment on EscrowInvitation {
    id
    companyName
  }
`;

const PartySection = withSuspense(({ escrowInvitationId = '' }) => {
  const {
    data: { company },
  } = useSuspenseQuery<ICompanyQuery>(CompanyDocument);
  const escrowInvitation = useFragment<IEscrowInvitation>({
    fragment: EscrowInvitationFragment,
    from: {
      __typename: 'EscrowInvitation',
      id: escrowInvitationId,
    },
  });

  if (!escrowInvitation.data) return null;

  const senderName = escrowInvitation.data.companyName || '';
  const recipientName = company.companyName || '';

  return (
    <div className="flex items-center gap-2">
      <Party name={senderName} />
      <ArrowRight className="size-5 shrink-0 text-primary" />
      <Party name={recipientName} />
    </div>
  );
});

const InvitationDialog = () => {
  const { invitationDialogOpen, escrowInvitationId, setInvitationDialogOpen, refetchEscrows } = useDashboard();
  const [acceptEscrowInvitation, { loading: acceptInvitationLoading }] = useAcceptEscrowInvitationMutation();
  const [rejectEscrowInvitation, { loading: rejectInvitationLoading }] = useRejectEscrowInvitationMutation();

  const closeDialog = () => startTransition(() => setInvitationDialogOpen(false, null));

  const handleAcceptInvitation = async () => {
    if (!escrowInvitationId) return;

    try {
      const { data } = await acceptEscrowInvitation({ variables: { escrowInvitationId } });
      if (data?.acceptEscrowInvitation?.success) {
        toast.success('Invitation accepted');
        closeDialog();
        refetchEscrows();
      } else {
        new ClientError(data?.acceptEscrowInvitation?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  const handleRejectInvitation = async () => {
    if (!escrowInvitationId) return;

    try {
      const { data } = await rejectEscrowInvitation({ variables: { escrowInvitationId } });
      if (data?.rejectEscrowInvitation?.success) {
        toast.success('Invitation rejected');
        closeDialog();
        refetchEscrows();
      } else {
        new ClientError(data?.rejectEscrowInvitation?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  return (
    <Dialog open={invitationDialogOpen} onOpenChange={closeDialog}>
      <DialogContent className="sm:max-w-[880px] bg-muted text-center md:p-12">
        <div className="size-12 bg-card shadow-sm rounded-md flex items-center justify-center border border-border mx-auto">
          <Mail className="size-6" />
        </div>
        <div>
          <DialogTitle className="text-3xl text-foreground mb-1.5">You’ve been invited as an escrow member</DialogTitle>
          <DialogDescription className="text-sm text-muted-foreground">
            Accept the invitation to start collaborating on the escrow.
          </DialogDescription>
        </div>

        {escrowInvitationId && <PartySection escrowInvitationId={escrowInvitationId} />}

        <div className="flex justify-center gap-4 py-4">
          <Button variant="destructive" isLoading={rejectInvitationLoading} onClick={handleRejectInvitation}>
            <MailX /> Reject invitation
          </Button>
          <Button isLoading={acceptInvitationLoading} onClick={handleAcceptInvitation}>
            <MailOpen /> Accept invitation
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvitationDialog;
