import { ExternalLink } from 'lucide-react';
import { useCallback, useState } from 'react';

import { AlertBanner } from 'src/components/Alert';
import { Button } from '@/components/ui/button';
import { ImageCustodian } from 'src/components/Svg/Images';
import { cookieStore } from 'src/utils/cookieStore';

const CONTINUITY_BANNER_COOKIE_NAME = 'continuity-banner';
const CONTINUITY_BANNER_COOKIE_MAX_AGE = 60 * 60 * 24 * 30; // 30 days

const ContinuityBanner = () => {
  const defaultOpen = cookieStore.get(CONTINUITY_BANNER_COOKIE_NAME)
    ? cookieStore.get(CONTINUITY_BANNER_COOKIE_NAME)?.value === 'true'
    : true;
  const [open, setOpen] = useState(defaultOpen);
  const onClose = useCallback(() => {
    setOpen(false);

    document.cookie = `${CONTINUITY_BANNER_COOKIE_NAME}=false; path=/; max-age=${CONTINUITY_BANNER_COOKIE_MAX_AGE}`;
  }, []);

  const onClick = () => {
    window.open('https://codekeeper.co/continuity-escrow', '_blank');
    onClose();
  };

  if (!open) return null;

  return (
    <AlertBanner
      title="Our solutions have evolved!"
      description="SaaS Escrow is now Continuity Escrow—actively protecting your business by maintaining essential services.
    Stay tuned for more innovations!"
      action={
        <Button onClick={onClick}>
          Learn more <ExternalLink />
        </Button>
      }
      icon={ImageCustodian}
      bgColor="bg-accent"
      textColor="text-primary"
      className="absolute top-[22px] left-[8px] right-[8px] z-[3]"
      onClose={onClose}
    />
  );
};

export default ContinuityBanner;
