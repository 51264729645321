import { z } from 'zod';
import { useFragment } from '@apollo/client';
import { toast } from 'sonner';

import { type IUser, UserRoleEnum, useEditUserMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

import { useUsers, UserFragment } from '../model';

import UserForm, { FormSchema } from './UserForm';

const EditUser = () => {
  const {
    ui: { userId, closeDialog },
    refetchUsers,
  } = useUsers();
  const [editMember, { loading }] = useEditUserMutation({
    onCompleted: refetchUsers,
  });

  const user = useFragment<IUser>({
    fragment: UserFragment,
    from: {
      __typename: 'User',
      id: userId,
    },
  });

  if (!user) return null;

  const onSubmit = async (dataForm: z.infer<typeof FormSchema>) => {
    if (!userId) return;

    try {
      const { data } = await editMember({
        variables: {
          userId,
          userInput: {
            name: dataForm.fullName,
            role: dataForm.role,
            phone: dataForm.phone,
          },
        },
      });
      if (data?.editUser?.success) {
        toast.success('User updated successfully');
        closeDialog();
      } else {
        throw new ClientError(data?.editUser?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  const defaultValues = {
    fullName: user.data.name || '',
    email: user.data.email || '',
    role: user.data.role || UserRoleEnum.Administrator,
    phone: user.data.phone || '',
  };

  const disableFields = ['email'];
  if (user.data.role === UserRoleEnum.Owner) {
    disableFields.push('role');
  }

  return (
    <UserForm
      onSubmit={onSubmit}
      btnText="Save"
      defaultValues={defaultValues}
      disableFields={disableFields}
      isLoading={loading}
      isOwner={user.data.role === UserRoleEnum.Owner}
    />
  );
};

export default EditUser;
