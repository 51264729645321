import Invite from 'src/pages/Invite/Invite';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
import InviteLayout from 'src/pages/Invite/InviteLayout';

import type { RouteObject } from 'react-router';

export const invitationsRoutes: RouteObject = {
  element: (
    <ProtectedRoute>
      <InviteLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'invite',
      element: <Invite />,
    },
  ],
};
