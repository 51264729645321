import type { IActionCard } from '../ui/ActionCard';

import { solutions } from './solutions.enum';

export const solutionsMap: Record<(typeof solutions)[number], IActionCard> = {
  backup: {
    title: 'Software Backup',
    description: 'Secure your assets for software resilience.',
    buttonLink: '/backups',
    activeLabel: 'Backups',
    activeColor: 'text-accent-software-backup',
  },
  escrow: {
    title: 'Software & SaaS Escrow',
    description: 'Securely store your software assets, ready for quick recovery.',
    buttonLink: '/escrows',
    activeLabel: 'Escrows',
    activeColor: 'text-secondary',
  },
  saas: {
    title: 'Continuity Escrow',
    description: 'Ensure continuity protection for your cloud-native applications.',
    buttonLink: '/continuity-escrows',
    activeLabel: 'Escrows',
    activeColor: 'text-accent-software-custodian',
  },
  custodian: {
    title: 'Software Custodian',
    description: 'Get end-to-end accountability for the entire software life cycle.',
    buttonLink: '/custodian-profiles',
    activeLabel: 'Profiles',
    activeColor: 'text-muted-foreground',
  },
};
