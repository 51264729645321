import { Outlet } from 'react-router';

import { EscrowLayoutContainer } from 'src/containers/Escrow';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';
import { withSuspense } from 'src/hoc';
import { EscrowContextProvider } from 'src/context/escrow';

const EscrowSlugLayout = () => {
  return (
    <div className="flex h-screen flex-col">
      <EscrowContextProvider>
        <EscrowLayoutContainer>
          <Outlet />
        </EscrowLayoutContainer>
      </EscrowContextProvider>
    </div>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={4} menuItems={7} />;

export default withSuspense(EscrowSlugLayout, FallBack);
