import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  QUERY = 'query',
  PAGE = 'page',
  SET_INITIAL_DATA = 'set-initial-data',
  SET_REMOVE_DIALOG_OPEN = 'set-remove-dialog-open',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA>
  | IAction<ActionEnum.QUERY, { query: string }>
  | IAction<ActionEnum.PAGE, { page: number }>
  | IAction<ActionEnum.SET_REMOVE_DIALOG_OPEN, { removeDialogOpen: boolean; removeEscrowId: string | null }>;

export type IState = {
  query?: string;
  page: number;
  removeDialogOpen: boolean;
  removeEscrowId: string | null;
};

const initialState: IState = {
  query: undefined,
  page: 1,
  removeDialogOpen: false,
  removeEscrowId: null,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.QUERY:
      return { ...state, query: action.payload.query, page: 1 };
    case ActionEnum.PAGE:
      return { ...state, page: action.payload.page };
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    case ActionEnum.SET_REMOVE_DIALOG_OPEN:
      return {
        ...state,
        removeDialogOpen: action.payload.removeDialogOpen,
        removeEscrowId: action.payload.removeEscrowId,
      };
    default:
      return state;
  }
};

export const useDashboardSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setQuery = (query: string) => dispatch({ type: ActionEnum.QUERY, payload: { query } });

  const setPage = (page: number) => dispatch({ type: ActionEnum.PAGE, payload: { page } });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  const setRemoveDialogOpen = (removeDialogOpen: boolean, removeEscrowId: string | null) =>
    dispatch({ type: ActionEnum.SET_REMOVE_DIALOG_OPEN, payload: { removeDialogOpen, removeEscrowId } });

  return {
    ...state,
    setQuery,
    setPage,
    setInitialData,
    setRemoveDialogOpen,
  };
};
