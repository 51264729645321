import { JourneyModalHeader, JourneyStatusIndicator, JourneyMenu, JourneyFooter } from 'src/components/Journey';
import { Loader } from 'src/components/Loader';

import styles from './JourneyLayout.module.scss';

type Props = {
  indicatorItems?: number;
  menuItems?: number;
};

const JourneyLayoutSkeleton = ({ indicatorItems = 1, menuItems = 1 }: Props) => {
  return (
    <div className="flex h-screen flex-col">
      <JourneyModalHeader.Skeleton />
      <div className={`flex ${styles['skeleton-main']}`}>
        <JourneyStatusIndicator.Skeleton items={indicatorItems} />
        <JourneyMenu.Skeleton items={menuItems} />
        <div className={`flex ${styles['skeleton-content']}`}>
          <Loader className={styles['skeleton-spin']} />
          <JourneyFooter.Skeleton />
        </div>
      </div>
    </div>
  );
};

export default JourneyLayoutSkeleton;
