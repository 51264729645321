import { UserRoleEnum } from 'src/graphql/schema';

export const roleOptions = [
  {
    label: 'Owner',
    value: UserRoleEnum.Owner,
  },
  {
    label: 'Admin',
    value: UserRoleEnum.Administrator,
  },
  {
    label: 'Technical',
    value: UserRoleEnum.Technical,
  },
  {
    label: 'Legal',
    value: UserRoleEnum.Legal,
  },
];

export const roleOptionsDict = roleOptions.reduce(
  (acc, role) => {
    acc[role.value] = role.label;
    return acc;
  },
  {} as Record<UserRoleEnum, string>,
);
