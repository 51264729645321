import classNames from 'classnames';
import { CheckCircleFilled } from '@ant-design/icons';
import { Skeleton } from 'antd';

import formatPrice from 'src/utils/currency-code-to-symbol';

import styles from './CancelPlanItem.module.scss';

export type ICancelPlanItem = {
  id: string;
  name: string;
  period?: 'month' | 'year';
  currency: string;
  price: number;
  discountedPrice?: number;
  showActiveLabel?: boolean;
  discounted?: boolean;
};

type Props = {
  className?: string;
  bgColor?: 'white' | 'light-blue' | 'grey';
  withBorder?: boolean;
  withBoxShadow?: boolean;
  size?: 'medium' | 'large';
} & ICancelPlanItem;

const CancelPlanItemSkeleton = () => (
  <div className={`${styles.item} ${styles['with-box-shadow']} ${styles['with-border']} ${styles['size--large']}`}>
    <Skeleton active />
  </div>
);

const CancelPlanItem = ({
  name,
  period = 'month',
  currency,
  price,
  discountedPrice,
  showActiveLabel = false,
  discounted,
  bgColor = 'white',
  withBorder = false,
  withBoxShadow = true,
  size = 'medium',
  className,
}: Props) => {
  const itemClasses = classNames(styles.item, styles[`bg--${bgColor}`], styles[`size--${size}`], className, {
    [styles['with-border']]: withBorder,
    [styles['with-box-shadow']]: withBoxShadow,
  });

  return (
    <div className={itemClasses}>
      <div className={`flex justify-between ${styles.header}`}>
        <div className={styles.name}>
          <strong>{name}</strong> <span>({period}ly)</span>
        </div>

        {showActiveLabel && (
          <div className={styles.label}>
            <strong>Active</strong>
            <CheckCircleFilled />
          </div>
        )}
      </div>

      <div
        className={classNames(styles.price, {
          [styles.discounted]: discounted,
        })}
      >
        <strong>{formatPrice(currency, price, true)}</strong>
        <span> /{period}</span>
      </div>

      {discounted && (
        <div className={styles['price-new']}>
          <strong>{formatPrice(currency, discountedPrice, true)}</strong>
          <span> /{period}</span>
        </div>
      )}
    </div>
  );
};

CancelPlanItem.Skeleton = CancelPlanItemSkeleton;

export default CancelPlanItem;
