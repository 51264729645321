import { SoftwareDashboardEmpty } from 'src/components/SoftwareDashboard';
import Saas from 'src/assets/images/dashboard/saas.jpg';
import SaasPlaceholder from 'src/assets/images/dashboard/saas-placeholder.jpg';

import CreateNewEnvironmentContainer from './CreateNewEnvironmentContainer';

const items = [
  'If your vendors go down, we cover payments to keep services running until you or a new supplier take over.',
  'Every Codekeeper account includes a customizable, legally sound escrow agreement—no lawyer needed.',
  'Integrated with global hosting and cloud providers. Easily add custom vendors from your Dashboard.',
];

export const Intro = () => {
  return (
    <SoftwareDashboardEmpty
      title="Continuity protection for cloud-native applications"
      image={Saas}
      placeholderSrc={SaasPlaceholder}
      items={items}
      label="Continuity Escrow"
      textAccent="text-accent-software-custodian"
      learnMoreLink="https://codekeeper.co/continuity-escrow"
      newButton={<CreateNewEnvironmentContainer />}
    />
  );
};

export default Intro;
