import { cloneDeep } from '@apollo/client/utilities';
import { gql } from '@apollo/client';
import { PartialDeep } from 'type-fest';

import { CustodianProfileDocument } from 'src/graphql/schema';
import { CUSTODIAN_PROFILES_LIMIT } from 'src/shared/constants';

import { apolloClient as client, apolloCache as cache } from '../apollo-client';

import type {
  ICustodianProfileQuery,
  ICustodianProfilesQuery,
  ICustodianOperation,
  ICustodianProfile,
} from 'src/graphql/schema';

const CustodianOperationFragment = gql`
  fragment CustodianOperationFragment on CustodianProfile {
    custodianOperations {
      id
      custodianServiceEvents
      custodianServiceEventsCustom
      dataRetention
      endOfRetentionPeriod
      endOfRetentionPeriodCustom
      operationType
      operationTypeCustom
      regulatoryRequirements
      regulatoryRequirementsCustom
    }
  }
`;

export const removeCustodianOperationCache = (profileId: string, operationId: string) => {
  const cacheData = cloneDeep<ICustodianProfileQuery | null>(
    client.readQuery({
      query: CustodianProfileDocument,
      variables: {
        id: profileId,
      },
    }),
  );

  if (!cacheData) return;

  const custodianOperations =
    cacheData.custodianProfile?.custodianOperations.filter(({ id }) => id !== operationId) ?? [];

  cache.writeQuery({
    query: CustodianProfileDocument,
    data: {
      custodianProfile: {
        ...cacheData.custodianProfile,
        custodianOperations,
        policy: {
          ...(cacheData?.custodianProfile?.policy ?? {}),
          submitForReview: Boolean(custodianOperations.length),
        },
      },
    },
  });
};

export const updateCustodianOperationCache = (operationId: string, data?: PartialDeep<ICustodianOperation>) => {
  if (!data) return;

  client.writeFragment({
    id: `CustodianOperation:${operationId}`,
    fragment: CustodianOperationFragment,
    data,
  });
};

export const addCustodianOperationCache = (profileId: string, data: ICustodianOperation) => {
  const cacheData = cloneDeep<ICustodianProfileQuery | null>(
    client.readQuery({
      query: CustodianProfileDocument,
      variables: {
        id: profileId,
      },
    }),
  );

  if (!cacheData) return;

  const custodianOperations = [...(cacheData.custodianProfile?.custodianOperations ?? []), data];

  client.writeQuery({
    query: CustodianProfileDocument,
    data: {
      custodianProfile: {
        ...cacheData.custodianProfile,
        custodianOperations,
        policy: {
          ...(cacheData?.custodianProfile?.policy ?? {}),
          submitForReview: true,
        },
      },
    },
  });
};

export const addCustodianProfileCache = (data: ICustodianProfile) => {
  client.cache.modify({
    fields: {
      custodianProfiles: (existingData) => ({
        ...existingData,
        nodes: [data, ...existingData.nodes],
        nodesCount: existingData.nodesCount + 1,
        pageCount: Math.ceil((existingData.nodesCount + 1) / CUSTODIAN_PROFILES_LIMIT),
      }),
    },
  });
};

export const removeCustodianProfileCache = (profileId: string) => {
  cache.modify({
    fields: {
      custodianProfiles: (existingData, { readField }) => ({
        ...existingData,
        nodes: existingData.nodes.filter(
          (node: ICustodianProfilesQuery['custodianProfiles']['nodes'][number]) => readField('id', node) !== profileId,
        ),
        nodesCount: existingData.nodesCount - 1,
        pageCount: Math.ceil((existingData.nodesCount - 1) / CUSTODIAN_PROFILES_LIMIT),
      }),
    },
  });
};
