import { Check, Plus } from 'lucide-react';
import { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

type NewButtonProps = {
  text: string;
  onClick: () => void;
};

type Props = {
  title: string;
  image: string;
  items: string[];
  label: string;
  textAccent: string;
  newButton: NewButtonProps | React.ReactNode;
  learnMoreLink: string;
  className?: string;
  placeholderSrc: string;
};

const isNewButtonProps = (button: Props['newButton']): button is NewButtonProps => {
  return button !== null && typeof button === 'object' && 'onClick' in button && 'text' in button;
};

const SoftwareDashboardEmpty = ({
  title,
  image,
  items,
  label,
  textAccent,
  newButton,
  learnMoreLink,
  className,
  placeholderSrc,
}: Props) => {
  const renderNewButton = isNewButtonProps(newButton) ? (
    <Button onClick={newButton.onClick}>
      <Plus className="size-4" />
      {newButton.text}
    </Button>
  ) : (
    newButton
  );

  return (
    <section className={className}>
      <div className="container mx-auto px-4 sm:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-10">
          <div className="col-span-1 self-center">
            <div className={cn('uppercase text-xs font-semibold mb-2.5', textAccent)}>{label}</div>
            <h1 className="text-3xl font-bold text-foreground mb-7">{title}</h1>
            <ul className="group list-none mb-3.5">
              {items.map((item, index) => (
                <li key={index} className="flex items-start gap-2 not-last-of-type:mb-2">
                  <Check className={cn('size-4.5 shrink-0 mt-0.5', textAccent)} />
                  <span className="text-sm text-muted-foreground">{item}</span>
                </li>
              ))}
            </ul>
            <p className="text-sm text-muted-foreground mb-7">All with the click of a button.</p>
            <div className="flex items-center gap-3">
              {renderNewButton}
              <Button variant="secondary" onClick={() => window.open(learnMoreLink, '_blank')}>
                Learn more
              </Button>
            </div>
          </div>

          <div className="col-span-1 pointer-events-none relative">
            <div className="pb-[100%]">
              <div className="absolute inset-0">
                <LazyLoadImage
                  src={image}
                  alt={label}
                  effect="blur"
                  wrapperClassName="absolute inset-0"
                  className="rounded-xl w-full"
                  placeholderSrc={placeholderSrc}
                  width={'100%'}
                  height={'100%'}
                  wrapperProps={{
                    style: { transitionDelay: '1s' },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(SoftwareDashboardEmpty);
