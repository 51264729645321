import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';

const twoFASchema = z.object({
  code: z
    .string()
    .min(6, 'Code must be 6 digits')
    .max(6, 'Code must be 6 digits')
    .regex(/^\d+$/, 'Code must contain only numbers'),
});

export type ITOTPForm = z.infer<typeof twoFASchema>;

type Props = {
  onSubmit: (values: ITOTPForm) => void;
  onCancel: () => void;
  submitText?: string;
  submitType?: 'default' | 'destructive';
};

const TOTPForm = ({ onSubmit, onCancel, submitText = 'Continue', submitType = 'default' }: Props) => {
  const form = useForm<ITOTPForm>({
    resolver: zodResolver(twoFASchema),
    defaultValues: {
      code: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirmation code</FormLabel>
              <FormControl>
                <Input {...field} type="text" inputMode="numeric" placeholder="******" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-between space-x-2 mt-6">
          <Button variant="outline" onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button type="submit" isLoading={form.formState.isSubmitting} variant={submitType}>
            {submitText}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default TOTPForm;
