import React, { Fragment } from 'react';
import { Select } from 'antd';

import { TextBlock } from 'src/components/Misc';

import styles from '../../Escrow.module.scss';

type Props = {
  selectedDisplay: boolean;
  onChange: (value: boolean) => void;
};

const DepositInfoDisplaySelect: React.FunctionComponent<Props> = ({ selectedDisplay, onChange }) => {
  return (
    <Fragment>
      <TextBlock
        className={styles['mb--20']}
        title="Beneficiary Deposit Information Display"
        text="Should information (names and dates) of the deposited material be visible in the beneficiary's dashboard?"
      />

      <div className={`${styles['select-form-item']} ${styles['mb--40']}`}>
        <Select
          className="w-full"
          value={selectedDisplay}
          onChange={onChange}
          options={[
            {
              label: 'Show',
              value: true,
            },
            {
              label: 'Don’t show',
              value: false,
            },
          ]}
        />
      </div>
    </Fragment>
  );
};

export default DepositInfoDisplaySelect;
