import { Table } from 'antd';

import { EscrowPartyTypeEnum, AgreementFormEnum } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

import { RepresentativeActions, SignatoryActionColumn } from './';

import { useRepresentative } from '../model';

import type { TableColumnsType } from 'antd';
import type { IRepresentative } from '../model/types';

const suggestTypeMap = {
  add: 'Add suggested',
  edit: 'Edit suggested',
  remove: 'Removal suggested',
};

const RepresentativesTable = () => {
  const {
    escrow: { agreement },
  } = useEscrow();
  const { representatives, role, currentPolicyRole, changesRequested } = useRepresentative();
  const isSignatoryMissing = !representatives
    .filter(({ suggestType }) => suggestType !== 'remove')
    .some(({ signatory }) => signatory);

  const columns: TableColumnsType<IRepresentative> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      render: (phone?: string) => phone || '-',
    },
    ...(agreement?.agreementForm === AgreementFormEnum.Tripartite ||
    (agreement?.agreementForm === AgreementFormEnum.Bipartite && role === EscrowPartyTypeEnum.Depositor)
      ? SignatoryActionColumn(isSignatoryMissing)
      : []),
    ...(changesRequested
      ? [
          {
            title: ' ',
            dataIndex: 'suggestType',
            render: (value?: string) =>
              value ? (
                <span className="text-xs" style={{ color: '#eb8d00' }}>
                  {suggestTypeMap[value as keyof typeof suggestTypeMap]}
                </span>
              ) : null,
          },
        ]
      : []),
    ...(currentPolicyRole?.update
      ? [
          {
            title: 'Action',
            dataIndex: 'id',
            render: (
              id: string,
              {
                suggestType,
              }: {
                suggestType?: keyof typeof suggestTypeMap;
              },
            ) => <RepresentativeActions id={id} suggestType={suggestType} />,
          },
        ]
      : []),
  ];

  return (
    <Table
      className="ck-escrow__representatives-table"
      rowHoverable={false}
      columns={columns}
      dataSource={representatives}
      pagination={{
        defaultPageSize: 9,
        hideOnSinglePage: true,
      }}
      rowKey="id"
      locale={{
        emptyText: <p className="text-slate-grey">No representative added</p>,
      }}
      {...(representatives.length
        ? {
            scroll: { scrollToFirstRowOnChange: true, x: 'max-content' },
          }
        : {
            showHeader: false,
          })}
    />
  );
};

export default RepresentativesTable;
