import { useReadQuery } from '@apollo/client';

import { Main } from 'src/components/Layout';
import {
  CustodianProfilesContainer,
  DashboardContextProvider,
  useDashboard,
  filterOptions,
} from 'src/containers/CustodianProfiles';
import { usePageMeta } from 'src/hooks';
import { SoftwareDashboardLayout } from 'src/components/SoftwareDashboard';
import { ICustodianProfilesQuery } from 'src/graphql/schema';

import { Intro, CreateCustodianProfile, RemoveDialog } from './ui';

const Dashboard = () => {
  const { setQuery, queryRef, status, query, setStatusFilter } = useDashboard();
  const {
    data: { custodianProfiles },
  } = useReadQuery<ICustodianProfilesQuery>(queryRef);

  const isEmpty = !custodianProfiles.nodesCount && status === 'all' && !query;

  return (
    <Main title="Software Custodian" noPaddingOnContent>
      <SoftwareDashboardLayout
        title="Profiles"
        createAction={<CreateCustodianProfile />}
        onSearch={!isEmpty ? setQuery : undefined}
        filterOptions={filterOptions}
        activeFilter={status}
        setActiveFilter={!isEmpty ? setStatusFilter : undefined}
      >
        {isEmpty ? <Intro /> : <CustodianProfilesContainer />}
      </SoftwareDashboardLayout>
    </Main>
  );
};

const CustodianPage = () => {
  usePageMeta('Software Custodian');

  return (
    <DashboardContextProvider>
      <Dashboard />
      <RemoveDialog />
    </DashboardContextProvider>
  );
};

export default CustodianPage;
