import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';

import { message } from 'src/components/Misc';
import { getFile } from 'src/utils/file';

import styles from './GeneratedAgreement.module.scss';

type Props = {
  fileName: string;
  termsSignedDownloadPdfUrl?: string;
  terms: string;
};

const GeneratedAgreementContent = ({ fileName, terms, termsSignedDownloadPdfUrl }: Props) => {
  const [signedPdf, setSignedPdf] = useState('');
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (!termsSignedDownloadPdfUrl) return;

    const getSignedPdfUrl = async () => {
      try {
        const responseFile = await getFile(termsSignedDownloadPdfUrl);

        if (!responseFile.ok) {
          setSignedPdf('');
          return;
        }

        const blob = await responseFile.blob();

        setSignedPdf(URL.createObjectURL(blob));
      } catch {
        message.error('Failed to load pdf');
      }
    };

    getSignedPdfUrl();
  }, []);

  const onZoomIn = () => setScale((prevScale) => prevScale + 0.1);
  const onZoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  return (
    <div className={styles.content}>
      <div className={styles['content-header']}>
        <div className={styles['content-heading']}>
          <h6 className="truncate">{fileName}</h6>
        </div>
        <div className={styles['action-group']}>
          <div className={styles['action-item']}>
            <Button type="text" disabled={scale >= 1.8} onClick={onZoomIn} data-testid="zoom-in-button">
              <PlusCircleFilled />
            </Button>
          </div>
          <div className={styles['action-item']}>
            <Button type="text" disabled={scale < 0.8} onClick={onZoomOut} data-testid="zoom-out-button">
              <MinusCircleFilled />
            </Button>
          </div>
        </div>
      </div>
      <div className={styles['content-main']}>
        <div
          className={classNames(styles['content-wrapper'], {
            [styles.left]: scale >= 1,
            [styles.center]: scale < 1,
            [styles['height--100']]: signedPdf,
          })}
          style={{ transform: `scale(${scale})` }}
        >
          {signedPdf ? (
            <object
              data={`${signedPdf}#view=fitH&toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              width="100%"
              height="100%"
              title="Agreement"
              data-testid="pdf-object"
            >
              <p>Failed to load pdf</p>
            </object>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: terms,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneratedAgreementContent;
