import React from 'react';
import { Select } from 'antd';

import { TextBlock } from 'src/components/Misc';

import { escrowJurisdictionOptions } from '../escrowAgreement.enum';
import styles from '../../Escrow.module.scss';

type Props = {
  selectedValue: string | null;
  onChange: (value: string) => void;
};

const EscrowJurisdictionSelect: React.FunctionComponent<Props> = ({ selectedValue, onChange }) => {
  return (
    <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
      <TextBlock
        className={styles['mb--20']}
        title="Jurisdiction"
        titleColor="dark-blue"
        as="h4"
        text="Under which party's legal jurisdiction should the agreement be executed?"
      />

      <div className={`${styles['select-form-item']} ${styles['mb--40']}`}>
        <Select
          className="w-full"
          placeholder="Select jurisdiction"
          value={selectedValue}
          onChange={onChange}
          options={escrowJurisdictionOptions}
        />
      </div>
    </div>
  );
};

export default EscrowJurisdictionSelect;
