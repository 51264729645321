import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';

import { useOrganization } from '../model';

import OrganizationForm from './OrganizationForm';

const DetailsDialog = () => {
  const { detailsDialogOpen, setDetailsDialogOpen } = useOrganization();

  return (
    <Dialog open={detailsDialogOpen} onOpenChange={setDetailsDialogOpen}>
      <DialogContent className="sm:max-w-[976px] gap-0">
        <DialogHeader className="mb-6">
          <DialogTitle className="text-base font-bold">Edit organization details</DialogTitle>
          <DialogDescription className="text-xs">
            Enter the details of the organization. This includes the registered name and address of the entity, and
            these details will automatically be integrated into any agreements you create.
          </DialogDescription>
        </DialogHeader>
        <OrganizationForm />
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
