import { createRequiredContext } from 'src/context/createRequiredContext';
import { apolloClient as client } from 'src/graphql';
import { UsersDocument } from 'src/graphql/schema';

import { useUserDialogSlice } from './useUserDialogSlice';

const [useUsers, UsersProvider] = createRequiredContext<{
  ui: ReturnType<typeof useUserDialogSlice>;
  refetchUsers: () => void;
}>();

const UsersContextProvider = ({ children }: React.PropsWithChildren) => {
  const userDialogSlice = useUserDialogSlice();

  const refetchUsers = () => {
    client.cache.evict({ fieldName: 'users' });
    client.cache.gc();
    client.refetchQueries({
      include: [UsersDocument],
    });
  };

  const value = {
    ui: userDialogSlice,
    refetchUsers,
  };

  return <UsersProvider value={value}>{children}</UsersProvider>;
};

export { useUsers, UsersContextProvider };
