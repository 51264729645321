import { Fragment } from 'react';
import { UserPlus } from 'lucide-react';
import { z } from 'zod';
import { toast } from 'sonner';

import { useAddUserMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

import { useUsers } from '../model';

import UserForm, { FormSchema } from './UserForm';

const AddUser = () => {
  const {
    ui: { closeDialog },
    refetchUsers,
  } = useUsers();
  const [addMember, { loading }] = useAddUserMutation({
    onCompleted: refetchUsers,
  });

  const onSubmit = async (dataForm: z.infer<typeof FormSchema>) => {
    try {
      const { data } = await addMember({
        variables: {
          users: [
            {
              name: dataForm.fullName,
              email: dataForm.email,
              role: dataForm.role,
              phone: dataForm.phone,
            },
          ],
        },
      });

      if (data?.addUser?.success) {
        closeDialog();
        toast.success('Team member added!');
      } else {
        throw new ClientError(data?.addUser?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  return (
    <UserForm
      isLoading={loading}
      onSubmit={onSubmit}
      btnText={
        <Fragment>
          <UserPlus /> Add
        </Fragment>
      }
    />
  );
};

export default AddUser;
