import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { useAuth } from 'src/context/auth/use-auth';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { PASSWORD_PATTERN } from 'src/utils/validation/validation-patterns';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

const FormSchema = z
  .object({
    password: z.string().min(8).regex(PASSWORD_PATTERN),
    newPassword: z.string().min(8).regex(PASSWORD_PATTERN),
    confirmPassword: z.string().min(8).regex(PASSWORD_PATTERN),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

const PasswordCard = () => {
  const { changePassword } = useAuth();

  const onSubmit = async ({ password, newPassword }: z.infer<typeof FormSchema>) => {
    try {
      await changePassword(password, newPassword);
      toast.success('Password updated!', {
        description: 'Your login details have been updated.',
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.name === 'NotAuthorizedException') toast.error('Invalid password');
        else toast.error(e.message);
      }
    }
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  return (
    <Card className="mb-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardHeader>
            <CardTitle className="text-xl">Change password</CardTitle>
            <CardDescription>Update your password to keep your account secure.</CardDescription>
          </CardHeader>
          <CardContent className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Current password</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex justify-between gap-2">
                    Verify current password{' '}
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span className="underline text-muted-foreground font-normal">Password policy</span>
                      </TooltipTrigger>
                      <TooltipContent
                        side="bottom"
                        align="center"
                        className="bg-popover text-popover-foreground max-w-[270px] border border-border"
                      >
                        Your password must be at least 8 characters long, and contain at least one uppercase letter, at
                        least one number, and at least one special character.
                      </TooltipContent>
                    </Tooltip>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="sm:col-start-2">
                  <FormLabel>Confirm password</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="border-t border-border justify-end py-4">
            <Button type="submit" disabled={!form.formState.isValid} isLoading={form.formState.isSubmitting}>
              Save
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};

export default PasswordCard;
