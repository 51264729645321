import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';

import { useUpdateProfileInfoMutation } from 'src/graphql/schema';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ClientError, getApiError } from 'src/utils/errors';

const FormSchema = z.object({
  name: z.string().min(1),
  phone: z.string().optional(),
});

type Props = {
  name?: string;
  phone?: string;
};

const BasicCard = ({ name, phone }: Props) => {
  const [updateProfile, { loading }] = useUpdateProfileInfoMutation();

  const onSubmit = async (dataForm: z.infer<typeof FormSchema>) => {
    try {
      const { data } = await updateProfile({
        variables: dataForm,
      });

      if (data?.updateProfileInfo?.success) {
        toast.success('Profile updated!');
      } else {
        throw new ClientError(data?.updateProfileInfo?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: name || '',
      phone: phone || '',
    },
    mode: 'onChange',
  });

  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle className="text-xl">Basic information</CardTitle>
        <CardDescription>View and update your personal details and account information.</CardDescription>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="sm:mb-4 col-span-1 col-start-1">
                  <FormLabel>Full name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="col-span-1 col-start-1">
                  <FormLabel>
                    Phone number <i>(optional)</i>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="border-t border-border justify-end py-4">
            <Button type="submit" disabled={!form.formState.isValid || !form.formState.isDirty} isLoading={loading}>
              Save
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};

export default BasicCard;
