import { Outlet, useLocation, useNavigate } from 'react-router';
import { useSuspenseQuery } from '@apollo/client';

import { Main, PageHeader } from 'src/components/Layout';
import { withSuspense } from 'src/hoc';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { IProfileQuery, ProfileDocument } from 'src/graphql/schema';

const items = [
  {
    label: 'Profile',
    value: '/profile',
  },
  {
    label: 'Activity',
    value: '/activity',
  },
  {
    label: 'Feedback',
    value: '/feedback',
  },
];

const ProfileLayout = () => {
  const {
    data: { profile },
  } = useSuspenseQuery<IProfileQuery>(ProfileDocument);
  const navigate = useNavigate();
  const location = useLocation();

  const name = profile.name || profile.email || '';

  const onValueChange = (value: string) => navigate(value);

  return (
    <Main title={name} headerChildren={<PageHeader title={name} />} className="bg-muted/60">
      <div className="container mx-auto">
        <Tabs value={location.pathname} className="mb-6" onValueChange={onValueChange}>
          <TabsList>
            {items.map(({ value, label }) => (
              <TabsTrigger value={value} key={value}>
                {label}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <Outlet />
      </div>
    </Main>
  );
};

export default withSuspense(ProfileLayout);
