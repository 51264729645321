import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';

import type { LucideIcon } from 'lucide-react';

export type IHomeBanner = {
  textColor?: 'white' | 'primary';
  bgColor: string;
  title: {
    text: string;
    weight?: 'bold' | 'semibold';
  };
  subtitle: string;
  description: string;
  icon?: LucideIcon;
  className?: string;
};

type Props = IHomeBanner;

export const HomeBannerSkeleton = () => {
  return (
    <div
      className="bg-card rounded-lg shadow-xs h-[134px] border border-border p-6 flex flex-col gap-2"
      aria-label="Loading..."
    >
      <Skeleton className="h-4 w-1/2" />
      <Skeleton className="h-4 w-3/4 mb-4" />
      <Skeleton className="h-4 w-1/3" />
    </div>
  );
};

const HomeBanner = ({ textColor = 'primary', bgColor, title, subtitle, description, icon, className }: Props) => {
  const Icon = icon as LucideIcon;

  return (
    <div
      className={cn(
        'rounded-lg text-foreground p-8 shadow-xs relative overflow-hidden',
        bgColor,
        textColor === 'white' ? 'text-white' : 'text-primary',
        className,
      )}
      role="banner"
    >
      <div className="relative z-2">
        <h4 className="text-sm mb-2 font-medium">{subtitle}</h4>
        <h1
          className={cn('text-3xl mb-6', {
            'font-bold': title.weight === 'bold',
            'font-semibold': title.weight === 'semibold' || !title.weight,
          })}
        >
          {title.text}
        </h1>
        <div className="flex items-start">
          {icon && <Icon className="w-4 h-4 mr-2 shrink-0" />}
          <p className="text-xs">{description}</p>
        </div>
      </div>
      <div className="absolute bottom-0 right-[-1px] z-1 h-full w-[216px] pointer-events-none">
        <div className="absolute top-0 right-0 z-1 h-full w-full bg-[linear-gradient(270deg,rgba(255,255,255,0.5)0%,rgba(255,255,255,0)100%)] [clip-path:polygon(40%_0,100%_0%,100%_100%,0%_100%)]" />
        <div className="absolute top-0 right-0 z-2 h-full w-[162px] bg-[linear-gradient(270deg,rgba(255,255,255,0.5)0%,rgba(255,255,255,0)_133.64%)] [clip-path:polygon(50%_0,100%_0%,100%_100%,0%_100%)]" />
      </div>
    </div>
  );
};

export default HomeBanner;
