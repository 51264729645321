import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

type IAvatarGroup = {
  avatars: {
    src?: string;
    fallback: string;
  }[];
  max?: number;
  total?: number;
  onAvatarClick?: () => void;
};

const AvatarGroup = ({ avatars, max = 3, total = 0, onAvatarClick }: IAvatarGroup) => {
  const avatarsToShow = avatars.slice(0, max);
  const remainingAvatars = total > max ? total - max : 0;

  const handleAvatarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onAvatarClick) {
      e.preventDefault();
      onAvatarClick();
    }
  };

  return (
    <div className="flex items-center">
      {avatarsToShow.map(({ src, fallback }, index) => (
        <Avatar className="w-5 h-5 not-last:-mr-2 border border-card" key={index}>
          <Tooltip>
            <TooltipTrigger asChild>
              <AvatarImage src={src} alt={fallback} onClick={handleAvatarClick} />
            </TooltipTrigger>
            <TooltipContent side="top" align="center" onClick={(e) => e.stopPropagation()}>
              {fallback}
            </TooltipContent>
          </Tooltip>
          <AvatarFallback className="uppercase text-xs">{fallback.charAt(0)}</AvatarFallback>
        </Avatar>
      ))}

      {Boolean(remainingAvatars) && (
        <div className="size-5 rounded-full text-[8px] text-primary border border-card bg-border z-[1] flex items-center justify-center">
          +{remainingAvatars}
        </div>
      )}
    </div>
  );
};

export { AvatarGroup, type IAvatarGroup };
