import { QueryRef, useBackgroundQuery } from '@apollo/client';
import { useSearchParams } from 'react-router';

import { createRequiredContext } from 'src/context/createRequiredContext';
import {
  type ISaasEnvironmentsQuery,
  SaasEnvironmentsDocument,
  ISaasEnvironmentsQueryVariables,
  SaasEnvironmentStatusEnum,
} from 'src/graphql/schema';
import { SAAS_ESCROWS_LIMIT, OtherSaasEnvironmentStatusEnum } from 'src/shared/constants';
import { apolloClient as client } from 'src/graphql/client/apollo-client';

import { setValidStatus } from '../lib';

import { useDashboardSlice } from './useDashboardSlice';

const [useDashboard, DashboardProvider] = createRequiredContext<
  ReturnType<typeof useDashboardSlice> & {
    queryRef: QueryRef<ISaasEnvironmentsQuery>;
    refetchSaasEnvironments: () => void;
    status: string;
    setStatusFilter: (status: string) => void;
  }
>();

const DashboardContextProvider = ({ children }: React.PropsWithChildren) => {
  const dashboardSlice = useDashboardSlice();
  const [searchParams, setSearchParams] = useSearchParams();

  const status = setValidStatus(searchParams.get('t'));
  const variables = {
    page: dashboardSlice.page,
    perPage: SAAS_ESCROWS_LIMIT,
    pendingTermination: status === OtherSaasEnvironmentStatusEnum.TerminationRequested,
    ...(status !== OtherSaasEnvironmentStatusEnum.TerminationRequested && {
      status: status as SaasEnvironmentStatusEnum,
    }),
    ...(dashboardSlice.query && { query: dashboardSlice.query }),
  };
  const [queryRef] = useBackgroundQuery<ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables>(
    SaasEnvironmentsDocument,
    {
      variables,
    },
  );

  const refetchSaasEnvironments = () => {
    client.cache.evict({ fieldName: 'saasEnvironments' });
    client.cache.gc();
    client.refetchQueries({
      include: [SaasEnvironmentsDocument],
    });
  };

  const setStatusFilter = (status: string) => {
    setSearchParams({ t: status });
    dashboardSlice.setPage(1);
  };

  const values = {
    queryRef,
    refetchSaasEnvironments,
    status: status || 'all',
    setStatusFilter,
    ...dashboardSlice,
  };

  return <DashboardProvider value={values}>{children}</DashboardProvider>;
};

export { useDashboard, DashboardContextProvider };
