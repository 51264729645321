import { usePageMeta } from 'src/hooks';

import { FeedbackForm, SurveyCard } from './ui';

const FeedbackPage = () => {
  usePageMeta('Feedback');

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
      <FeedbackForm />
      <div>
        <SurveyCard />
      </div>
    </div>
  );
};

export default FeedbackPage;
