import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

import { useTOTP } from '../model';

const AlertDisableTOTPDialog = () => {
  const { disableAlertOpen, disableTOTP, cancel } = useTOTP();

  return (
    <AlertDialog open={disableAlertOpen} onOpenChange={cancel}>
      <AlertDialogContent className="sm:max-w-[512px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to disable multi-factor authentication?</AlertDialogTitle>
          <AlertDialogDescription>
            Doing so will make your account less secure and may violate your organization’s information security
            standards.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={disableTOTP}>
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertDisableTOTPDialog;
