const getBaseUrl = (url: string) => {
  const match = url.match(/^(https?:\/\/[^/]+)/);

  return match ? match[1] : url;
};

export const getCompanyLogoUrl = async (website: string) => {
  const baseUrl = getBaseUrl(website);
  const normalizedWebsite = baseUrl
    .replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .trim();

  if (!normalizedWebsite) return;

  const clearbitUrl = `https://logo.clearbit.com/${normalizedWebsite}`;

  return clearbitUrl;
};
