import { memo } from 'react';

import type { SVGProps } from 'react';
const SvgIconBadgeValidated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    className={props.className}
    {...props}
  >
    <circle cx={11} cy={11} r={10.25} fill="#C88245" stroke="#904C06" strokeWidth={0.5} />
    <path fill="#C3782C" d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1z" />
    <path
      stroke="#914C06"
      strokeWidth={0.5}
      d="M17 9.438 11 5 5 9.274v3.288m12-3.124v3.288L11 17l-6-4.438m12-3.124-6 4.439-3.943-2.893M5 12.562l2.057-1.578m8.057-.066L11 7.958l-3.943 3.026"
    />
  </svg>
);
const Memo = memo(SvgIconBadgeValidated);
export default Memo;
