import { toast } from 'sonner';
import { useFragment } from '@apollo/client';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { ClientError, getApiError } from 'src/utils/errors';
import { IsUpdateableBillingDocument, useTransferCompanyOwnershipMutation, type IUser } from 'src/graphql/schema';

import { useUsers, UserFragment } from '../model';

const DialogTitle = ({ userId = '' }) => {
  const user = useFragment<IUser>({
    fragment: UserFragment,
    from: {
      __typename: 'User',
      id: userId,
    },
  });

  return (
    <AlertDialogTitle>
      Are you sure you want to transfer account ownership to {user.data.name || user.data.email}?
    </AlertDialogTitle>
  );
};

const TransferOwnershipDialog = () => {
  const {
    ui: { transferOwnershipDialogOpen, userId, closeDialog },
    refetchUsers,
  } = useUsers();
  const [transferCompanyOwnership, { loading }] = useTransferCompanyOwnershipMutation({
    onCompleted: refetchUsers,
    refetchQueries: [IsUpdateableBillingDocument],
  });

  const onOpenChange = (open: boolean) => {
    if (!open) closeDialog();
  };

  const onTransfer = async () => {
    if (!userId) return;

    try {
      const { data } = await transferCompanyOwnership({
        variables: {
          userId,
        },
      });
      if (data?.transferCompanyOwnership?.success) {
        toast.success('Account ownership transferred!', {
          description: `${data.transferCompanyOwnership.user?.name} has now been assigned the account owner role.`,
        });
        closeDialog();
      } else {
        throw new ClientError(data?.transferCompanyOwnership?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  };

  return (
    <AlertDialog open={transferOwnershipDialogOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          {userId && <DialogTitle userId={userId} />}
          <AlertDialogDescription>
            You will no longer be able to access your organization’s billing details, and your permissions will be reset
            to that of an admin.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onTransfer} isLoading={loading}>
            Transfer
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default TransferOwnershipDialog;
