import {
  Home,
  LayoutList,
  LayoutGrid,
  ChevronRight,
  Settings,
  CircleHelp,
  FileQuestion,
  Files,
  MessageCircleMore,
  SquareTerminal,
  FileCheck2,
  FolderSearch,
  Vault,
  ShieldEllipsis,
  CreditCard,
  Shield,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  useSidebar,
  SidebarMenuItem,
  SidebarMenu,
  SidebarMenuButton,
  SidebarGroupLabel,
  SidebarMenuBadge,
} from '@/components/ui/sidebar';
import { IconLogo, Logo, ImageEscrow, ImageBackups, ImageCustodian } from 'src/components/Svg/Images';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';

import { NavUser } from './ui';

const IconHome = () => (
  <span className="flex items-center justify-center w-8 h-8 p-2 bg-secondary rounded-lg shrink-0">
    <Home className="w-full h-full text-white" />
  </span>
);

const mainItems = [
  {
    title: 'Home',
    url: '/',
    icon: IconHome,
  },
  {
    title: 'Software Backup',
    url: '/backups',
    icon: ImageBackups,
  },
  {
    title: 'Software & SaaS Escrow',
    url: '/escrows',
    icon: ImageEscrow,
  },
  {
    title: 'Continuity Escrow',
    url: '/continuity-escrows',
    icon: ImageCustodian,
  },
];

const footerItems = (withBilling = false) => [
  {
    icon: LayoutGrid,
    label: 'Integrations',
    items: [
      { icon: LayoutGrid, label: 'Integrations', to: '/integrations' },
      { icon: FileQuestion, label: 'Explore & FAQs', to: 'https://codekeeper.co/integrations', target: '_blank' },
    ],
  },
  {
    icon: CircleHelp,
    label: 'Support',
    items: [
      { icon: MessageCircleMore, label: 'Support', to: '/support' },
      { icon: Files, label: 'Documentation', to: 'https://codekeeper.co/how-it-works', target: '_blank' },
      { icon: FileCheck2, label: 'Verification', to: 'https://codekeeper.co/verification', target: '_blank' },
      { icon: FolderSearch, label: 'Resources', to: 'https://codekeeper.co/resources', target: '_blank' },
    ],
  },
  {
    icon: Settings,
    label: 'Settings',
    items: [
      { icon: LayoutList, label: 'Organization', to: '/organization' },
      ...(withBilling ? [{ icon: CreditCard, label: 'Billing', to: '/billing' }] : []),
    ],
  },
];

type Props = React.ComponentProps<typeof Sidebar> & {
  withBilling?: boolean;
  user: {
    name: string;
    email: string;
  };
  migrationsCount: number;
};

export function AppSidebar({ withBilling, user, migrationsCount, ...props }: Props) {
  const { pathname } = useLocation();
  const { open, isMobile, toggleSidebar } = useSidebar();
  const navigate = useNavigate();

  const handleNavigate = (url: string) => () => {
    isMobile && toggleSidebar();
    navigate(url);
  };

  return (
    <Sidebar {...props} collapsible="icon">
      <SidebarHeader>
        <Link to="/" onClick={isMobile ? toggleSidebar : undefined}>
          {open || isMobile ? <Logo className="w-[130px] h-[40px]" /> : <IconLogo className="w-8 h-8" />}
        </Link>
      </SidebarHeader>
      <SidebarContent className="p-2">
        <SidebarMenu className="gap-1 group-data-[collapsible=icon]:gap-2">
          {mainItems.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                isActive={item.url === pathname}
                className="py-2 px-2 h-12"
                asChild
                tooltip={item.title}
                onClick={isMobile ? toggleSidebar : undefined}
              >
                <Link
                  to={item.url}
                  className="flex items-center gap-2 group-not-first/menu-item:group-data-[collapsible=icon]:p-1! group-first/menu-item:group-data-[collapsible=icon]:p-0!"
                >
                  {open ? (
                    <span className="flex items-center justify-center w-8 h-8 p-1">
                      <item.icon className="w-full h-full" />
                    </span>
                  ) : (
                    <item.icon className="w-6! h-6!" />
                  )}
                  <span className="text-primary font-semibold">{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}

          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton tooltip="Other solutions">
                  <ShieldEllipsis /> Other solutions
                  <ChevronRight className="ml-auto" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="right" align="start" className="w-max">
                <DropdownMenuItem className="py-2 cursor-pointer" onClick={handleNavigate('/custodian-profiles')}>
                  <Shield /> Software Custodian
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu className="text-primary data-menu-button:hover:text-primary">
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              isActive={pathname === '/deposits'}
              tooltip="Deposits"
              onClick={isMobile ? toggleSidebar : undefined}
            >
              <Link to="/deposits">
                <Vault /> Deposits
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {Boolean(migrationsCount) && (
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                isActive={pathname === '/migrations'}
                tooltip="Migrations"
                onClick={isMobile ? toggleSidebar : undefined}
              >
                <Link to="/migrations">
                  <SquareTerminal /> Migrations
                  <SidebarMenuBadge>{migrationsCount}</SidebarMenuBadge>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
          {footerItems(withBilling).map((menu) => (
            <SidebarMenuItem key={menu.label}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton tooltip={menu.label}>
                    <menu.icon /> {menu.label}
                    <ChevronRight className="ml-auto" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="right" align="start" className="w-max">
                  <SidebarGroupLabel className="h-max py-1">{menu.label}</SidebarGroupLabel>
                  {menu.items.map((item) => {
                    const handleClick = () => {
                      if (isMobile) toggleSidebar();
                      if (item.target) return window.open(item.to, '_blank');

                      navigate(item.to);
                    };

                    return (
                      <DropdownMenuItem key={item.label} className="py-2 cursor-pointer" onClick={handleClick}>
                        <item.icon />
                        <span>{item.label}</span>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
        <NavUser user={user} />
      </SidebarFooter>
    </Sidebar>
  );
}
