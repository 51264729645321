import { Button, Skeleton } from 'antd';
import { Fragment } from 'react';

import { renderLogo } from 'src/utils/renderLogo';
import { providers } from 'src/shared/entities';

import styles from './DepositAddIntegration.module.scss';

type Props = {
  integration: {
    provider: string;
    account: string;
    repositories?: number;
  };
  onConfirm: () => void;
  onBack: () => void;
  isLoading?: boolean;
  confirmButton?: {
    disabled?: boolean;
    loading?: boolean;
  };
  isBackButtonVisible?: boolean;
};

const DepositAddIntegrationLayout = ({
  integration,
  children,
  onConfirm,
  isLoading = false,
  confirmButton,
  onBack,
  isBackButtonVisible = true,
}: React.PropsWithChildren<Props>) => {
  const Icon = renderLogo(integration.provider as (typeof providers)[number]);

  return (
    <Fragment>
      <div className={styles.header}>
        <div className={`flex items-center ${styles['header-row']}`}>
          <div className={styles.logo}>{Icon && <Icon />}</div>
          <div className={styles.info}>
            <h4 className={styles.heading}>{integration.account}</h4>
            {!isLoading && Boolean(integration.repositories) && (
              <div className={styles['info-row']}>
                <span>Repositories:</span>
                <strong>{integration.repositories}</strong>
              </div>
            )}
            {isLoading && (
              <div aria-label="Loading...">
                <Skeleton.Input size="small" active />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.main}>{children}</div>

      <div className={`flex ${isBackButtonVisible ? 'justify-between' : 'justify-end'}`}>
        {isBackButtonVisible && (
          <Button type="text" onClick={onBack}>
            Back
          </Button>
        )}
        <Button type="primary" onClick={onConfirm} disabled={confirmButton?.disabled} loading={confirmButton?.loading}>
          Confirm Deposit
        </Button>
      </div>
    </Fragment>
  );
};

export default DepositAddIntegrationLayout;
