import { Navigate, useLocation, useNavigate } from 'react-router';

import { useCompanyQuery } from 'src/graphql/schema';
import { useAuth } from 'src/context/auth/use-auth';

const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { inProgress, isSignedIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading: isLoading } = useCompanyQuery({
    fetchPolicy: 'cache-and-network',
    skip: inProgress,
    onCompleted: (data) => {
      const requiredFields = [
        data.company.companyName,
        data.company.street,
        data.company.city,
        data.company.postalCode,
        data.company.country,
      ];

      if (requiredFields.some((field) => !field)) navigate('/invite');
    },
  });

  if (inProgress || isLoading) {
    return <></>;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
