import { Fragment } from 'react';
import { useSuspenseQuery } from '@apollo/client';

import { IProfileQuery, ProfileDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { TOTPContextProvider, TOTPDialogContainer } from 'src/containers/TOTP';

import { BasicCard, EmailCard, PasswordCard, TOTPCard } from './ui';

const ProfileContainer = () => {
  const {
    data: { profile },
  } = useSuspenseQuery<IProfileQuery>(ProfileDocument);

  return (
    <Fragment>
      <BasicCard name={profile?.name || ''} phone={profile?.phone || ''} />
      <EmailCard email={profile?.email || ''} />
      <PasswordCard />

      <TOTPContextProvider>
        <TOTPCard />
        <TOTPDialogContainer />
      </TOTPContextProvider>
    </Fragment>
  );
};

export default withSuspense(ProfileContainer);
