import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import { Button } from 'src/components/ui/button';
import { useCreateCustodianProfileMutation } from 'src/graphql/schema';
import { useDashboard } from 'src/containers/CustodianProfiles';

const CreateCustodianProfile = () => {
  const { refetchCustodianProfiles } = useDashboard();
  const [createCustodianProfile, { loading }] = useCreateCustodianProfileMutation({
    onCompleted: () => refetchCustodianProfiles(),
  });
  const navigate = useNavigate();

  const onCreate = async () => {
    try {
      const { data } = await createCustodianProfile();

      if (data?.createCustodianProfile?.success) {
        navigate(`/custodian-profiles/${data.createCustodianProfile.custodianProfile?.id}`);
      }
    } catch (error) {
      toast.error('Failed to create custodian profile');
    }
  };

  return (
    <Button onClick={onCreate} isLoading={loading}>
      + New Profile
    </Button>
  );
};

export default CreateCustodianProfile;
