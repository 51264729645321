import { Modal, ModalProps } from 'antd';

interface Props extends ModalProps {
  text: string;
}

export default function ConfirmationModal({ text, ...rest }: Props) {
  return (
    <Modal centered className="agreement-settings-modal" closable {...rest}>
      <p className="mb-4">
        <strong>{text}</strong>
      </p>
    </Modal>
  );
}
