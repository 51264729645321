import { Link } from 'react-router';
import { Select, Form, Button } from 'antd';

import { useSignedActiveEscrowsQuery, EscrowPartyTypeEnum } from 'src/graphql/schema';
import { ImageEmptyBox } from 'src/components/Svg/Images';
import { useAddDeposit } from 'src/context/deposit';

const EmptyContent = () => (
  <div className="text-center">
    <ImageEmptyBox className="empty__img" />
    <p className="empty__text">You have no signed escrow to add deposit into</p>
    <Link to="/escrows/new" className="empty__link">
      <u>Create New Escrow</u>
    </Link>
  </div>
);

const SelectEscrowContainer = () => {
  const { data, loading } = useSignedActiveEscrowsQuery({
    variables: {
      escrowParty: EscrowPartyTypeEnum.Depositor,
    },
  });
  const {
    ui: { selectEscrow },
  } = useAddDeposit();
  const [form] = Form.useForm();
  const idField = Form.useWatch('id', form);

  const allEscrows = loading
    ? []
    : [...(data?.active.nodes ?? []), ...(data?.signed.nodes ?? [])].map((escrow) => {
        const label = [escrow.depositor?.companyName, escrow.beneficiary?.companyName].filter(Boolean).join(' - ');

        return {
          value: escrow.id,
          label,
        };
      });

  const onSubmit = ({ id }: { id: string }) => selectEscrow(id);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} clearOnDestroy>
      <h2 className="font-bold text-xl text-light-blue mb-8">Select Escrow</h2>

      <Form.Item
        name="id"
        rules={[
          {
            required: true,
            message: 'Please select an escrow',
          },
        ]}
        className="mb-8"
      >
        <Select
          placeholder="Select escrow"
          options={allEscrows}
          loading={loading}
          {...(!allEscrows.length && { dropdownRender: EmptyContent })}
        />
      </Form.Item>

      <div className="text-right">
        <Button htmlType="submit" type="primary" disabled={!idField}>
          Continue to Deposit
        </Button>
      </div>
    </Form>
  );
};

export default SelectEscrowContainer;
