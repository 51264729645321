import { type IEscrowsQuery, EscrowStatusEnum } from 'src/graphql/schema';

const statusTracks = ['createdAt', 'adminReviewAt', 'onboardingAt', 'signedAt', 'activatedAt'] as const;

const statusTrackPriority: Record<(typeof statusTracks)[number], number> = {
  createdAt: 0,
  adminReviewAt: 1,
  onboardingAt: 2,
  signedAt: 3,
  activatedAt: 4,
};

const trackToStatusMap = {
  createdAt: EscrowStatusEnum.Draft,
  adminReviewAt: EscrowStatusEnum.AdminReview,
  onboardingAt: EscrowStatusEnum.Onboarding,
  signedAt: EscrowStatusEnum.Signed,
  activatedAt: EscrowStatusEnum.Active,
};

export function setTrackToStatus(escrow: IEscrowsQuery['escrows']['nodes'][0]) {
  const currentStatusTrack: Record<(typeof statusTracks)[number], Date | null> = {
    createdAt: escrow.createdAt,
    adminReviewAt: escrow.adminReviewAt,
    onboardingAt: escrow.onboardingAt,
    signedAt: escrow.signedAt,
    activatedAt: escrow.activatedAt,
  };

  const lastStatusTrack = statusTracks.reduce((acc, key) => {
    if (currentStatusTrack[key] && statusTrackPriority[key] > statusTrackPriority[acc]) return key;
    return acc;
  }, 'createdAt');

  return trackToStatusMap[lastStatusTrack];
}
