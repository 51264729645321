type Props = {
  title: string;
  description: string;
  icon: React.ElementType;
};

const EmptyBlock = ({ title, description, icon }: Props) => {
  const Icon = icon;

  return (
    <div className="p-6 bg-white rounded-lg border border-dashed border-border text-center">
      <div className="w-12 h-12 bg-white rounded-md border border-border flex items-center justify-center mx-auto mb-6 shadow-xs">
        <Icon className="w-6 h-6 text-foreground" />
      </div>
      <h2 className="text-xl font-semibold text-foreground mb-2">{title}</h2>
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  );
};

export default EmptyBlock;
