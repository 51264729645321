import { LabelText, ILabelTextProps } from 'src/components/Label';

import styles from './CancelCollapse.module.scss';

export type Props = {
  text: string;
  label: ILabelTextProps;
};

const CancelAccordionBody = ({ label, text }: Props) => {
  return (
    <div className={`flex items-center ${styles.body}`}>
      <LabelText {...label} />
      <p>{text}</p>
    </div>
  );
};

export default CancelAccordionBody;
