import Gitlab1 from 'src/assets/images/integrations/gitlab1.svg';
import Gitlab2 from 'src/assets/images/integrations/gitlab2.svg';
import Gitlab3 from 'src/assets/images/integrations/gitlab3.svg';

export function GitlabInfoContent() {
  return (
    <div>
      <div className="flex non-auth-modal__step-container">
        <div className="flex items-start">
          <span className="non-auth-modal__step">1</span>
          <span className="non-auth-modal__step-text">
            Go to your GitLab account and go to <strong>Preferences -&gt; Access Tokens.</strong>
          </span>
        </div>

        <img className="non-auth-modal__step-img" src={Gitlab1} alt="" />
      </div>
      <div className="flex non-auth-modal__step-container">
        <div className="flex items-start">
          <span className="non-auth-modal__step">2</span>
          <span className="non-auth-modal__step-text">
            Under Scopes, select <strong>read_api, read_user, read_repository & read_registry</strong> and then click
            the <strong>Create Personal Access Token</strong> button.
          </span>
        </div>
        <img className="non-auth-modal__step-img" src={Gitlab2} alt="" />
      </div>
      <div className="flex non-auth-modal__step-container">
        <div className="flex items-center">
          <span className="non-auth-modal__step">3</span>
          <span className="non-auth-modal__step-text">Copy your new Personal Access Token.</span>
        </div>
        <img className="non-auth-modal__step-img" src={Gitlab3} alt="" />
      </div>
    </div>
  );
}
