import { useNavigate } from 'react-router';

import { SoftwareDashboardEmpty } from 'src/components/SoftwareDashboard';
import Escrows from 'src/assets/images/dashboard/escrows.jpg';
import EscrowsPlaceholder from 'src/assets/images/dashboard/escrows-placeholder.jpg';

const items = [
  'Every Codekeeper account includes a customizable, legally sound escrow agreement—no lawyer needed.',
  'Integrates directly with developer workflows. Deposits are encrypted and fully automated.',
  'Store all assets that are required to quickly recover in case of a release event.',
];

export const Intro = () => {
  const navigate = useNavigate();
  const onNewEscrow = () => navigate('/escrows/new');

  return (
    <SoftwareDashboardEmpty
      title="Securely store your software assets, ready for quick recovery"
      image={Escrows}
      placeholderSrc={EscrowsPlaceholder}
      items={items}
      label="software & SaaS Escrow"
      textAccent="text-accent-software-escrow"
      learnMoreLink="https://codekeeper.co/software-escrow"
      newButton={{
        text: 'New Escrow',
        onClick: onNewEscrow,
      }}
    />
  );
};

export default Intro;
