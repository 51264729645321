import { Fragment } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from '@/components/ui/dialog';

import { useUsers } from '../model';

import AddUser from './AddUser';
import EditUser from './EditUser';
import RemoveDialog from './RemoveDialog';
import TransferOwnershipDialog from './TransferOwnershipDialog';

const actionMap = {
  add: {
    title: 'Add team member',
    description: 'Invite your team members to join Codekeeper and contribute to your organization’s account.',
    component: AddUser,
  },
  edit: {
    title: 'Edit team member details',
    description: 'Edit your team member permissions below. ',
    component: EditUser,
  },
};

const UserDialogManager = () => {
  const {
    ui: { dialogOpen, closeDialog, type },
  } = useUsers();

  const handleOpenChange = (open: boolean) => {
    if (!open) closeDialog();
  };

  const action = type ? actionMap[type as keyof typeof actionMap] : null;

  return (
    <Fragment>
      <Dialog open={dialogOpen} onOpenChange={handleOpenChange}>
        <DialogTrigger />
        <DialogContent className="sm:max-w-[400px] gap-0">
          <DialogHeader className="mb-6">
            <DialogTitle className="text-base font-bold">{action?.title}</DialogTitle>
            <DialogDescription className="text-xs">{action?.description}</DialogDescription>
          </DialogHeader>
          {action?.component && <action.component />}
        </DialogContent>
      </Dialog>

      <RemoveDialog />

      <TransferOwnershipDialog />
    </Fragment>
  );
};

export default UserDialogManager;
