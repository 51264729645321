import { Outlet } from 'react-router';
import { useReadQuery } from '@apollo/client';

import { EscrowsContainer, DashboardContextProvider, useDashboard, filterOptions } from 'src/containers/Escrows';
import { Main } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';
import { SoftwareDashboardLayout } from 'src/components/SoftwareDashboard';
import { type IEscrowsQuery } from 'src/graphql/schema';

import { NewEscrowAction, Intro, InvitationDialog, RemoveDialog } from './ui';

const Dashboard = () => {
  const { setQuery, queryRef, status, query, setStatusFilter } = useDashboard();
  const {
    data: { escrows },
  } = useReadQuery<IEscrowsQuery>(queryRef);

  const isEmpty = !escrows.nodesCount && status === 'all' && !query;

  return (
    <Main title="Software & SaaS Escrow" noPaddingOnContent>
      <SoftwareDashboardLayout
        title="Escrows"
        createAction={<NewEscrowAction />}
        onSearch={!isEmpty ? setQuery : undefined}
        filterOptions={filterOptions}
        activeFilter={status}
        setActiveFilter={!isEmpty ? setStatusFilter : undefined}
      >
        {isEmpty ? <Intro /> : <EscrowsContainer />}
      </SoftwareDashboardLayout>

      <Outlet />
    </Main>
  );
};

export default function EscrowsPage() {
  usePageMeta('Software & SaaS Escrow');

  return (
    <DashboardContextProvider>
      <InvitationDialog />
      <RemoveDialog />
      <Dashboard />
      <Outlet />
    </DashboardContextProvider>
  );
}
