import { Button } from 'antd';
import { Fragment } from 'react';

import { OtherOfferContent } from 'src/components/Billing/Cancel/Plan';
import { useCancelationPlan } from 'src/context/billing';
import { useSwitchToBackupPlanMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { message } from 'src/components/Misc';

const OtherOfferContainer = () => {
  const {
    ui: { setStep, getBack },
  } = useCancelationPlan();
  const [switchToBackupPlan, { loading }] = useSwitchToBackupPlanMutation();

  const onAcceptOffer = async () => {
    try {
      const { data } = await switchToBackupPlan();

      if (data?.switchToBackupPlan?.errors?.length) {
        throw new ClientError(data.switchToBackupPlan.errors[0]);
      } else setStep('offer-confirmation');
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onDeclineOffer = () => setStep('pre-cancelation');

  return (
    <Fragment>
      <OtherOfferContent />

      <div className="flex justify-between">
        <Button type="text" onClick={getBack}>
          Back
        </Button>
        <div className="flex gap-3">
          <Button onClick={onDeclineOffer}>No Thanks</Button>
          <Button type="primary" onClick={onAcceptOffer} loading={loading}>
            Switch to Software Backup
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default OtherOfferContainer;
