import { UserPlus, PenLine, X, ArrowRightLeft, CircleAlert } from 'lucide-react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSuspenseQuery } from '@apollo/client';
import { useMemo, useState, useTransition } from 'react';

import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from 'src/lib/utils';
import {
  type IUsersQuery,
  type IUserPolicy,
  UserRoleEnum,
  UsersDocument,
  UserColumnsEnum,
  ProfileDocument,
  type IProfileQuery,
} from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { USERS_LIMIT } from 'src/shared/constants';
import Paginator from '@/components/ui/paginator';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

import { roleOptionsDict, useUsers } from '../model';

import CardHeaderWithAction from './CardHeaderWithAction';

type IUserRow = {
  name: string;
  email: string;
  phone: string;
  role: string;
  action: {
    id: string;
    policy: IUserPolicy;
  };
};

const ActionCell = ({ id, policy }: IUserRow['action']) => {
  const {
    ui: { openEditDialog, openRemoveDialog, openTransferOwnershipDialog },
  } = useUsers();

  const onEdit = () => openEditDialog(id);
  const onRemove = () => openRemoveDialog(id);
  const onTransferOwnership = () => openTransferOwnershipDialog(id);

  return (
    <div className="flex justify-end gap-2">
      {policy.update && (
        <Button variant="outline" size="icon" className="size-7 p-2" onClick={onEdit}>
          <PenLine />
        </Button>
      )}

      {policy.remove && (
        <Button variant="outline" size="icon" className="size-7 p-2" onClick={onRemove}>
          <X />
        </Button>
      )}

      {policy.transferOwnership && (
        <Button variant="outline" size="icon" className="size-7 p-2" onClick={onTransferOwnership}>
          <ArrowRightLeft />
        </Button>
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<IUserRow>();
const columns = [
  columnHelper.accessor('name', {
    cell: (props) => <div className="text-foreground text-sm font-semibold">{props.getValue()}</div>,
  }),
  columnHelper.accessor('email', {
    cell: (props) => <div className="text-muted-foreground text-sm">{props.getValue()}</div>,
  }),
  columnHelper.accessor('phone', {
    cell: (props) => <div className="text-muted-foreground text-sm">{props.getValue()}</div>,
  }),
  columnHelper.accessor('role', {
    cell: (props) => (
      <Badge className={cn('whitespace-nowrap bg-ck-gray-100 text-primary')}>
        {roleOptionsDict[props.getValue() as UserRoleEnum] || '-'}
      </Badge>
    ),
  }),
  columnHelper.accessor('action', {
    cell: (props) => <ActionCell {...props.getValue()} />,
  }),
];

const UsersCard = () => {
  const [page, setPage] = useState(1);
  const [isPending, startTransition] = useTransition();
  const {
    data: { profile },
  } = useSuspenseQuery<IProfileQuery>(ProfileDocument);
  const {
    data: { users },
  } = useSuspenseQuery<IUsersQuery>(UsersDocument, {
    variables: {
      page,
      perPage: USERS_LIMIT,
      orderBy: UserColumnsEnum.Role,
    },
  });
  const {
    ui: { openAddDialog },
  } = useUsers();
  const usersMapped = useMemo(
    () =>
      users.nodes.map((user) => ({
        name: user.name || '-',
        email: user.email,
        role: user.role,
        phone: user.phone || '-',
        action: {
          id: user.id,
          policy: user.policy,
        },
      })),
    [users],
  );
  const table = useReactTable({
    data: usersMapped,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleAddUser = () => openAddDialog();

  const onPageChange = (page: number) => startTransition(() => setPage(page));

  return (
    <Card>
      <CardHeaderWithAction
        title="Team members"
        description="These are the team members that have access to your Codekeeper account. "
        action={
          <Button onClick={handleAddUser}>
            <UserPlus /> Add
          </Button>
        }
      />
      <CardContent>
        <Table isLoading={isPending}>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Phone number</TableHead>
              <TableHead>
                <div className="flex items-center gap-2">
                  Permissions
                  {profile.role === UserRoleEnum.Owner && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <CircleAlert className="size-4 text-[#C8D3D9]" />
                      </TooltipTrigger>
                      <TooltipContent
                        side="top"
                        align="center"
                        className="bg-popover text-popover-foreground max-w-[270px] border border-border"
                      >
                        Account ownership can be transferred to another Admin user using the “transfer” action button.
                      </TooltipContent>
                    </Tooltip>
                  )}
                </div>
              </TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Paginator
                  currentPage={page}
                  totalPages={users.pagesCount}
                  onPageChange={onPageChange}
                  showPreviousNext
                  className="justify-end"
                  hideOnSinglePage
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withSuspense(UsersCard);
