import { X } from 'lucide-react';

import { cn } from 'src/lib/utils';
import { Button } from '@/components/ui/button';

type Props = {
  title: string;
  description: string;
  action: React.ReactNode;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.ReactNode>;
  bgColor: string;
  textColor: string;
  className?: string;
  onClose: () => void;
};

const AlertBanner = ({ title, description, action, icon, bgColor, textColor, className, onClose }: Props) => {
  const Icon = icon;

  return (
    <div className={cn('flex items-center py-2 md:py-4 px-3 md:px-5 rounded-md', bgColor, className)}>
      <div className="grow flex gap-2 md:gap-3">
        <Icon className="size-4 mt-0.5 shrink-0" />
        <div className="text-sm">
          <h5 className={cn('mb-1', textColor)}>{title}</h5>
          <p className={cn('opacity-70', textColor)}>{description}</p>
        </div>
      </div>

      {action && <div className="shrink-0 md:px-7">{action}</div>}

      <Button variant="ghost" size="sm" className="absolute right-[8px] top-[4px] h-6 w-6 p-0" onClick={onClose}>
        <X className="size-4 text-foreground" />
      </Button>
    </div>
  );
};

export default AlertBanner;
