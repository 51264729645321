import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { UserRoleEnum } from 'src/graphql/schema';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

import { roleOptions, useUsers } from '../model';

type Props = {
  defaultValues?: {
    fullName: string;
    email: string;
    role: UserRoleEnum;
    phone: string;
  };
  onSubmit: (data: z.infer<typeof FormSchema>) => void;
  btnText?: string | React.ReactNode;
  isLoading?: boolean;
  disableFields?: string[];
  isOwner?: boolean;
};

export const FormSchema = z.object({
  fullName: z.string().min(1, 'Full name is required'),
  email: z.string().email('Invalid email address'),
  role: z.nativeEnum(UserRoleEnum, {
    message: 'Role is required',
  }),
  phone: z.string().optional(),
});

const UserForm = ({
  defaultValues,
  onSubmit,
  btnText,
  isLoading = false,
  disableFields = [],
  isOwner = false,
}: Props) => {
  const {
    ui: { closeDialog },
  } = useUsers();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      fullName: defaultValues?.fullName || '',
      email: defaultValues?.email || '',
      role: defaultValues?.role || undefined,
      phone: defaultValues?.phone || '',
    },
  });
  const options = isOwner ? roleOptions.slice(0, 1) : roleOptions.slice(1);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormLabel>Full name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          disabled={disableFields.includes('email')}
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormLabel>Email address</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem className="mb-2">
              <FormLabel>Phone number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                disabled={disableFields.includes('role')}
              >
                <FormControl>
                  <SelectTrigger className="w-full">
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {options.map(({ label, value }) => (
                    <SelectItem value={value} key={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between mt-6">
          <Button variant="outline" onClick={closeDialog} type="button">
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading}>
            {btnText}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UserForm;
