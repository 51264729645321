import { useCallback, useEffect } from 'react';
import QRCode from 'qrcode';
import { toast } from 'sonner';

import { createRequiredContext } from 'src/context/createRequiredContext';
import { useAuth } from 'src/context/auth/use-auth';

import { useTOTPSlice } from './totpSlice';

const [useTOTP, TOTPProvider] = createRequiredContext<ReturnType<typeof useTOTPSlice>>();

const TOTPContextProvider = ({ children }: React.PropsWithChildren) => {
  const { shouldShowTOTPType, setUpTOTP } = useAuth();
  const { enableTOTP: onEnableTOTP, setQRCodeSrc, ...totpSlice } = useTOTPSlice();

  useEffect(() => {
    const fetchTOTPType = async () => {
      totpSlice.setIsLoading(true);

      try {
        const isEnabled = await shouldShowTOTPType();

        totpSlice.setTOTPEnabled(isEnabled);
      } finally {
        totpSlice.setIsLoading(false);
      }
    };

    fetchTOTPType();
  }, []);

  const generateQRCodeUrl = async (text: string) => {
    try {
      const url = await QRCode.toDataURL(text);

      setQRCodeSrc(url);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const generateQRCode = async () => {
    totpSlice.setIsLoading(true);

    try {
      const { href } = await setUpTOTP();

      await generateQRCodeUrl(href);
    } catch (e: unknown) {
      toast.error('Something went wrong!');
    } finally {
      totpSlice.setIsLoading(false);
    }
  };

  const enableTOTP = useCallback(() => {
    onEnableTOTP();
    generateQRCode();
  }, []);

  const value = {
    ...totpSlice,
    enableTOTP,
    setQRCodeSrc,
  };

  return <TOTPProvider value={value}>{children}</TOTPProvider>;
};

export { TOTPContextProvider, useTOTP };
