import { Activity } from 'lucide-react';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { NotificationContainer, EmptyBlock } from 'src/containers/Notification';
import { usePageMeta } from 'src/hooks';

const EmptyActivity = () => (
  <EmptyBlock
    title="No activities"
    description="Your application continuity activities will be show here."
    icon={Activity}
  />
);

const ActivityPage = () => {
  usePageMeta('Activity');

  return (
    <Card className="container mx-auto">
      <CardHeader>
        <CardTitle>Latest in your Organization</CardTitle>
      </CardHeader>
      <CardContent>
        <NotificationContainer interacted={null} withActionColumn={false} emptyBlock={EmptyActivity} />
      </CardContent>
    </Card>
  );
};

export default ActivityPage;
