import { toast } from 'sonner';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useAuth } from 'src/context/auth/use-auth';

import { useTOTP } from '../model';

import TOTPForm, { type ITOTPForm } from './TOTPForm';

const DisableTOTPDialog = () => {
  const { verifyTOTPSetup, updateTOTP } = useAuth();
  const { disableDialogOpen, destroy, cancel } = useTOTP();

  const onSubmit = async ({ code }: ITOTPForm) => {
    try {
      await verifyTOTPSetup(String(code));
      await updateTOTP(false);

      toast.success('Multi-factor authentication disabled!', {
        description: 'Multi-factor authentication has been disabled for your account.',
      });
      destroy();
    } catch (e: unknown) {
      if (e instanceof Error) toast.error(e.message || 'Something went wrong!');
    }
  };

  return (
    <Dialog open={disableDialogOpen} onOpenChange={cancel}>
      <DialogContent className="sm:max-w-[400px]">
        <DialogHeader>
          <DialogTitle>Disable Multi-factor authentication</DialogTitle>
          <DialogDescription>
            Enter the authentication code generated by the app where your Codekeeper account credentials are stored.
          </DialogDescription>
        </DialogHeader>

        <TOTPForm onSubmit={onSubmit} onCancel={cancel} submitText="Disable" submitType="destructive" />
      </DialogContent>
    </Dialog>
  );
};

export default DisableTOTPDialog;
