import { useReadQuery, useSuspenseQuery } from '@apollo/client';
import { startTransition, Fragment } from 'react';
import { SearchX } from 'lucide-react';

import {
  CompanyDocument,
  IUsersQuery,
  UsersDocument,
  type ICompanyQuery,
  type ICustodianProfilesQuery,
} from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { SimplePagination } from 'src/components/ui/simple-pagination';
import { EmptyBlock } from 'src/components/ui/empty-block';
import { CUSTODIAN_PROFILES_LIMIT, OtherCustodianProfileStatusEnum } from 'src/shared/constants';
import { SoftwareDashboardSkeleton, SoftwareDashboardList } from 'src/components/SoftwareDashboard';
import { getGravatarUrl } from 'src/utils/getGravatarUrl';

import { useDashboard } from './model';
import { DashboardItem } from './ui';

const CustodianProfilesContainer = () => {
  const { setPage, queryRef, page } = useDashboard();
  const {
    data: { company: dataCompany },
  } = useSuspenseQuery<ICompanyQuery>(CompanyDocument);
  const {
    data: { users },
  } = useSuspenseQuery<IUsersQuery>(UsersDocument);
  const {
    data: { custodianProfiles },
  } = useReadQuery<ICustodianProfilesQuery>(queryRef);

  const handlePrevious = () => startTransition(() => setPage(page - 1));

  const handleNext = () => startTransition(() => setPage(page + 1));

  const companyUsers = users.nodes.map(({ id, name, email }) => ({
    id,
    name: name || email,
    avatar: getGravatarUrl(email),
  }));

  return (
    <Fragment>
      {!custodianProfiles.nodesCount ? (
        <EmptyBlock
          title="No results found"
          description="There were no results for your search. Try another filter."
          icon={SearchX}
        />
      ) : (
        <Fragment>
          <SoftwareDashboardList>
            {custodianProfiles.nodes.map((profile) => (
              <DashboardItem
                key={profile.id}
                {...getProfile(profile)}
                company={{
                  id: dataCompany.id,
                  logo: dataCompany.companyWebsite || '',
                }}
                users={{
                  total: users.nodesCount,
                  nodes: companyUsers,
                }}
              />
            ))}
          </SoftwareDashboardList>

          <SimplePagination
            page={page}
            totalPages={custodianProfiles.pagesCount}
            totalItems={custodianProfiles.nodesCount}
            limit={CUSTODIAN_PROFILES_LIMIT}
            onPrevious={handlePrevious}
            onNext={handleNext}
            isPreviousDisabled={!custodianProfiles.hasPreviousPage}
            isNextDisabled={!custodianProfiles.hasNextPage}
            className="py-4 sm:px-6 border-t border-border"
            hideOnSinglePage
          />
        </Fragment>
      )}
    </Fragment>
  );
};

function getProfile({
  id,
  name,
  status,
  custodianTermination,
  policy: { remove: canRemove },
}: ICustodianProfilesQuery['custodianProfiles']['nodes'][0]) {
  return {
    id,
    name,
    status: custodianTermination ? OtherCustodianProfileStatusEnum.TerminationRequested : status,
    previousStatus: custodianTermination ? status : null,
    canRemove,
  };
}

export default withSuspense(CustodianProfilesContainer, SoftwareDashboardSkeleton);
