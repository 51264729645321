import { Empty } from 'antd';

import { ImageEmptyBox } from 'src/components/Svg/Images';

export default function EmptyDeposits({ children }: React.PropsWithChildren) {
  return (
    <Empty
      image={<ImageEmptyBox />}
      description={
        <span className="flex flex-col">
          <span className="deposit__empty-title">Make your first deposit</span>
          <span className="deposit__empty-text">
            Set up your first escrow to protect your code and data.
            <br />
            Customize it to fit your situation.
          </span>
        </span>
      }
    >
      {children}
    </Empty>
  );
}
