import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  SET_INITIAL_DATA = 'set-initial-data',
  SET_DETAILS_DIALOG_OPEN = 'set-details-dialog-open',
}

type IActionReducer = IAction<ActionEnum.SET_INITIAL_DATA> | IAction<ActionEnum.SET_DETAILS_DIALOG_OPEN, boolean>;

export type IState = {
  detailsDialogOpen: boolean;
};

const initialState: IState = {
  detailsDialogOpen: false,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_DETAILS_DIALOG_OPEN:
      return {
        ...state,
        detailsDialogOpen: action.payload,
      };

    case ActionEnum.SET_INITIAL_DATA:
      return {
        ...initialState,
      };
  }
};

export const useOrganizationSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setDetailsDialogOpen = (detailsDialogOpen: boolean) =>
    dispatch({ type: ActionEnum.SET_DETAILS_DIALOG_OPEN, payload: detailsDialogOpen });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  return {
    ...state,
    setDetailsDialogOpen,
    setInitialData,
  };
};
