import { Fragment } from 'react';
import { toast } from 'sonner';
import { Loader2 } from 'lucide-react';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useAuth } from 'src/context/auth/use-auth';

import { useTOTP } from '../model';

import TOTPForm, { ITOTPForm } from './TOTPForm';

const EnableTOTPDialog = () => {
  const { enableDialogOpen, qrCodeSrc, destroy, cancel, isQRCodeLoading } = useTOTP();
  const { verifyTOTPSetup, updateTOTP } = useAuth();

  const onSubmit = async ({ code }: ITOTPForm) => {
    try {
      await verifyTOTPSetup(String(code));
      await updateTOTP(true);
      toast.success('Multi-factor authentication enabled!', {
        description: 'Your account is now secured with multi-factor authentication.',
      });

      destroy();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || 'Something went wrong!');
      }
    }
  };

  return (
    <Dialog open={enableDialogOpen} onOpenChange={cancel}>
      <DialogContent className="sm:max-w-[425px]" forceMount>
        <DialogHeader>
          <DialogTitle>Enable multi-factor authentication</DialogTitle>
          <DialogDescription>
            To activate MFA, please scan this QR code with your preferred authentication app. This will connect the
            Codekeeper app to your account.
            <br />
            <br />
            Once you’ve scanned the QR code, enter the authentication code generated by the app where your Codekeeper
            account credentials are stored.
          </DialogDescription>
        </DialogHeader>

        {isQRCodeLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader2 className="w-10 h-10 animate-spin" />
          </div>
        ) : (
          <Fragment>
            {qrCodeSrc && <img className="mx-auto" src={qrCodeSrc} alt="QR Code" />}
            <TOTPForm onSubmit={onSubmit} onCancel={cancel} submitText="Enable" />
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EnableTOTPDialog;
