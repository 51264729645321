import { useEffect } from 'react';
import { useParams } from 'react-router';

import Loader from 'src/components/Loader/Loader';

import useImpersonate from '../../hooks/use-impersonate';
export default function Impersonate() {
  const { userId } = useParams();

  const { impersonate } = useImpersonate();

  useEffect(() => {
    impersonate(userId!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader size="large" />;
}
