import { Fragment, startTransition } from 'react';
import { useReadQuery } from '@apollo/client';
import { SearchX } from 'lucide-react';

import { ESCROWS_LIMIT } from 'src/shared/constants';
import { withSuspense } from 'src/hoc';
import { SimplePagination } from 'src/components/ui/simple-pagination';
import { EmptyBlock } from '@/components/ui/empty-block';
import { SoftwareDashboardSkeleton, SoftwareDashboardList } from 'src/components/SoftwareDashboard';

import type { IEscrowsQuery } from 'src/graphql/schema';

import { getEscrow } from './lib';
import { useDashboard } from './model';
import { DashboardItem } from './ui';

const EscrowsContainer = () => {
  const { setPage, queryRef, page } = useDashboard();
  const {
    data: { escrows: dataEscrows },
  } = useReadQuery<IEscrowsQuery>(queryRef);

  const handlePrevious = () => startTransition(() => setPage(page - 1));

  const handleNext = () => startTransition(() => setPage(page + 1));

  return (
    <Fragment>
      {!dataEscrows.nodesCount ? (
        <EmptyBlock
          title="No results found"
          description="There were no results for your search. Try another filter."
          icon={SearchX}
        />
      ) : (
        <Fragment>
          <SoftwareDashboardList>
            {dataEscrows.nodes.map((escrow) => (
              <DashboardItem key={escrow.id} {...getEscrow(escrow)} />
            ))}
          </SoftwareDashboardList>

          <SimplePagination
            page={page}
            totalPages={dataEscrows.pagesCount}
            totalItems={dataEscrows.nodesCount}
            limit={ESCROWS_LIMIT}
            onPrevious={handlePrevious}
            onNext={handleNext}
            isPreviousDisabled={!dataEscrows.hasPreviousPage}
            isNextDisabled={!dataEscrows.hasNextPage}
            className="py-4 sm:px-6 border-t border-border"
            hideOnSinglePage
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default withSuspense(EscrowsContainer, SoftwareDashboardSkeleton);
