import { gql } from '@apollo/client';

import { apolloClient as client } from '../apollo-client';

import type { ISaasEnvironmentPolicy } from 'src/graphql/schema';

const SaasEnvironmentPolicyFragment = gql`
  fragment SaasEnvironmentPolicyFragment on SaasEnvironment {
    policy {
      remove
      requestActivation
      requestTermination
      update
    }
  }
`;

export const updateSaasEnvironmentPolicyCache = (id: string, newPolicy: Partial<ISaasEnvironmentPolicy>) => {
  const currentPolicy = client.readFragment({
    id: `SaasEnvironment:${id}`,
    fragment: SaasEnvironmentPolicyFragment,
  }).policy as ISaasEnvironmentPolicy;

  client.writeFragment({
    id: `SaasEnvironment:${id}`,
    fragment: SaasEnvironmentPolicyFragment!,
    data: {
      policy: {
        ...currentPolicy,
        ...newPolicy,
      },
    },
  });
};
