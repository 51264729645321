import { Fragment, LegacyRef } from 'react';
import { Button, Form, Input, Select, Row, Col } from 'antd';

import { countries } from 'src/shared/entities';

import type { FormInstance } from 'antd/lib';

import styles from './EscrowPartyForms.module.scss';

export interface IOrganizationForm {
  name: string;
  website?: string;
  registrationNumber?: string;
  street: string;
  streetNumber?: string;
  country: string;
  city: string;
  state: string;
  zip: string;
}

export type Props = {
  onSubmit: (data: IOrganizationForm) => void;
  onCancel?: () => void;
  initialValues?: IOrganizationForm;
  isLoading?: boolean;
  withSubmitButton?: boolean;
  formRef?: Ref;
};

type Ref = LegacyRef<FormInstance<IOrganizationForm>>;

const OrganizationForm = ({ onSubmit, initialValues, isLoading = false, withSubmitButton = true, formRef }: Props) => {
  const [form] = Form.useForm();

  const countriesOptions: IOptionType[] = countries.map((country) => ({
    label: country.name,
    value: country.name,
  }));

  return (
    <div className={styles.container}>
      <Form
        form={form}
        layout="vertical"
        className="ck-escrow-party__form"
        onFinish={onSubmit}
        ref={formRef}
        initialValues={initialValues}
      >
        <Row className={styles.row}>
          <Col span={12} className={styles.col}>
            <Form.Item
              name="name"
              label="Organization Name"
              rules={[{ required: true, transform: (value) => value?.trim() }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="website" label="Website" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="registrationNumber"
              label={
                <Fragment>
                  Registration Number<sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} className={`${styles.col} ${styles.divider}`}>
            <Form.Item
              name="street"
              label="Street Address"
              rules={[{ required: true, transform: (value) => value?.trim() }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="streetNumber"
              label={
                <Fragment>
                  Number / Suite / Unit / Floor <sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Row wrap={true} className={styles['row-sm']}>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="city" label="City" rules={[{ required: true, transform: (value) => value?.trim() }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="state" label="State/Province/Region" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item
                  name="zip"
                  label="Zip/Postal code"
                  rules={[{ required: true, transform: (value) => value?.trim() }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className={styles['col-sm']}>
                <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select country"
                    options={countriesOptions}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {withSubmitButton && (
          <div className="text-right mt-6">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save details
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

OrganizationForm.displayName = 'OrganizationForm';

export default OrganizationForm;
