import { CardHeader, CardTitle } from '@/components/ui/card';

type Props = {
  title: string;
  description: string;
  action: React.ReactNode;
};

const CardHeaderWithAction = ({ title, description, action }: Props) => {
  return (
    <CardHeader>
      <div className="flex items-center justify-between gap-6">
        <div className="grow">
          <CardTitle className="text-xl mb-1">{title}</CardTitle>
          <p className="text-sm text-muted-foreground">{description}</p>
        </div>
        <div className="shrink-0">{action}</div>
      </div>
    </CardHeader>
  );
};

export default CardHeaderWithAction;
