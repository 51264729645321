import { startTransition } from 'react';
import { toast } from 'sonner';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { useDashboard } from 'src/containers/Escrows';
import { useRemoveEscrowMutation, EscrowsDocument } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

const RemoveDialog = () => {
  const { removeDialogOpen, removeEscrowId, setRemoveDialogOpen, refetchEscrows } = useDashboard();
  const [removeEscrow, { loading }] = useRemoveEscrowMutation({
    refetchQueries: [EscrowsDocument],
  });

  const onCloseDialog = () =>
    startTransition(() => {
      setRemoveDialogOpen(false, null);
    });

  const onRemoveEscrow = async () => {
    if (!removeEscrowId) return;

    try {
      const { data } = await removeEscrow({
        variables: {
          escrowId: removeEscrowId,
        },
      });
      if (data?.removeEscrow?.success) {
        toast.success('Escrow removed!');
        onCloseDialog();
        refetchEscrows();
      } else {
        throw new ClientError(data?.removeEscrow?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, toast.error);
    }
  };

  return (
    <AlertDialog open={removeDialogOpen} onOpenChange={onCloseDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to remove this draft?</AlertDialogTitle>
          <AlertDialogDescription>
            The draft, and all related information will be removed from your dashboard.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onRemoveEscrow} isLoading={loading}>
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveDialog;
