import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import { Button } from 'src/components/ui/button';
import { useCreateSaasEnvironmentMutation } from 'src/graphql/schema';
import { useDashboard } from 'src/containers/SaasEscrows';
const CreateNewEnvironmentContainer = () => {
  const { refetchSaasEnvironments } = useDashboard();
  const navigate = useNavigate();
  const [createSaasEnvironment, { loading }] = useCreateSaasEnvironmentMutation({
    onCompleted: () => refetchSaasEnvironments(),
  });

  const onClick = async () => {
    try {
      const { data } = await createSaasEnvironment();
      if (data?.createSaasEnvironment?.success) {
        navigate(`/continuity-escrows/${data?.createSaasEnvironment?.saasEnvironment?.id}/providers`);
      } else {
        throw new Error(data?.createSaasEnvironment?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) toast.error(error.message);
    }
  };

  return (
    <Button isLoading={loading} onClick={onClick}>
      + New Escrow
    </Button>
  );
};

export default CreateNewEnvironmentContainer;
