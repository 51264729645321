import { QueryRef, useBackgroundQuery } from '@apollo/client';
import { useSearchParams } from 'react-router';

import { createRequiredContext } from 'src/context/createRequiredContext';
import {
  type ICustodianProfilesQuery,
  CustodianProfilesDocument,
  ICustodianProfilesQueryVariables,
  CustodianProfileStatusEnum,
} from 'src/graphql/schema';
import { CUSTODIAN_PROFILES_LIMIT, OtherCustodianProfileStatusEnum } from 'src/shared/constants';
import { apolloClient as client } from 'src/graphql';

import { setValidStatus } from '../lib';

import { useDashboardSlice } from './useDashboardSlice';

const [useDashboard, DashboardProvider] = createRequiredContext<
  ReturnType<typeof useDashboardSlice> & {
    queryRef: QueryRef<ICustodianProfilesQuery>;
    refetchCustodianProfiles: () => void;
    status: string;
    setStatusFilter: (status: string) => void;
  }
>();

const DashboardContextProvider = ({ children }: React.PropsWithChildren) => {
  const dashboardSlice = useDashboardSlice();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = setValidStatus(searchParams.get('t'));
  const variables: ICustodianProfilesQueryVariables = {
    page: dashboardSlice.page,
    perPage: CUSTODIAN_PROFILES_LIMIT,
    pendingTermination: status === OtherCustodianProfileStatusEnum.TerminationRequested,
    ...(status !== OtherCustodianProfileStatusEnum.TerminationRequested && {
      status: status as CustodianProfileStatusEnum,
    }),
    ...(dashboardSlice.query && { query: dashboardSlice.query }),
  };
  const [queryRef] = useBackgroundQuery<ICustodianProfilesQuery, ICustodianProfilesQueryVariables>(
    CustodianProfilesDocument,
    {
      variables,
    },
  );

  const refetchCustodianProfiles = () => {
    client.cache.evict({ fieldName: 'custodianProfiles' });
    client.cache.gc();
    client.refetchQueries({
      include: [CustodianProfilesDocument],
    });
  };

  const setStatusFilter = (status: string) => {
    setSearchParams({ t: status });
    dashboardSlice.setPage(1);
  };

  const values = {
    queryRef,
    refetchCustodianProfiles,
    status: status || 'all',
    setStatusFilter,
    ...dashboardSlice,
  };

  return <DashboardProvider value={values}>{children}</DashboardProvider>;
};

export { useDashboard, DashboardContextProvider };
