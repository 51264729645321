import { Outlet } from 'react-router';

import { withSuspense } from 'src/hoc';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';
import { BackupNewModalLayoutContainer } from 'src/containers/Backup';

const BackupNewLayout = () => {
  return (
    <BackupNewModalLayoutContainer>
      <Outlet />
    </BackupNewModalLayoutContainer>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={5} />;

export default withSuspense(BackupNewLayout, FallBack);
