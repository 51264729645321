import { Fragment } from 'react';

import { EnableTOTPDialog, AlertDisableTOTPDialog, DisableTOTPDialog } from './ui';

const TOTPDialogContainer = () => {
  return (
    <Fragment>
      <EnableTOTPDialog />
      <AlertDisableTOTPDialog />
      <DisableTOTPDialog />
    </Fragment>
  );
};

export default TOTPDialogContainer;
