import { Fragment } from 'react';
import { Button } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { useCancelationPlan } from 'src/context/billing';
import { EXCLUSIVE_OFFER } from 'src/shared/constants';

const OfferAppliedContainer = () => {
  const {
    ui: { setStep, setInitialData },
  } = useCancelationPlan();

  const onContinueCancelation = () => setStep('feedback');

  return (
    <Fragment>
      <TextBlock
        className="mb-8"
        as="h3"
        title={`Your current plan is already at ${EXCLUSIVE_OFFER}% off`}
        text="Are you sure you want to proceed with canceling your plan?"
      />
      <div className="flex justify-end gap-3">
        <Button onClick={onContinueCancelation}>Continue Cancelation</Button>
        <Button type="primary" onClick={setInitialData}>
          Keep Plan
        </Button>
      </div>
    </Fragment>
  );
};

export default OfferAppliedContainer;
