import { Button } from 'antd';
import classNames from 'classnames';

type FormFooterProps = {
  confirm: () => void;
  cancel: () => void;
  disableConfirm?: boolean;
  hideConfirm?: boolean;
  hideCancel?: boolean;
  isLoading?: boolean;
  back?: () => void;
};

const FormFooter: React.FC<FormFooterProps> = ({
  confirm,
  cancel,
  disableConfirm,
  hideConfirm,
  hideCancel,
  isLoading = false,
  back,
}) => {
  return (
    <div className="flex justify-between mt-8">
      {back && (
        <Button type="text" onClick={back}>
          Back
        </Button>
      )}

      <div className="flex justify-end gap-3 grow">
        <Button type="default" onClick={cancel} className={classNames({ 'd-none': hideCancel })}>
          Cancel
        </Button>
        <Button
          type="primary"
          className={classNames({ 'd-none': hideConfirm })}
          disabled={disableConfirm}
          onClick={confirm}
          loading={isLoading}
        >
          Confirm deposit
        </Button>
      </div>
    </div>
  );
};

export default FormFooter;
