import { useReadQuery } from '@apollo/client';
import { Fragment } from 'react/jsx-runtime';
import { Divider, Skeleton } from 'antd';
import dayjs from 'dayjs';

import {
  BillingPlan,
  BillingAddon,
  BillingCoupon,
  BillingTotal,
  BillingCard,
  BillingPlanDropdown,
} from 'src/components/Billing';
import { withSuspense } from 'src/hoc';
import { useCancelationPlan } from 'src/context/billing';

import type { QueryRef } from '@apollo/client';
import type { IBillingQuery, ISubscription } from 'src/graphql/schema';

import { isAllPlansFree } from './lib';
import AddCouponContainer from './AddCouponContainer';

type Props = {
  queryRef: QueryRef<IBillingQuery>;
  subscriptionExclusiveCouponAppliedAt: Date | null;
};

const BillingSubscriptionsContainer = ({ queryRef, subscriptionExclusiveCouponAppliedAt }: Props) => {
  const { data } = useReadQuery<IBillingQuery>(queryRef);
  const {
    ui: { setStep },
    exclusiveOfferApplied,
    cancellationRequested,
    feedbackSubmitted,
  } = useCancelationPlan();

  const subscriptions = (data.billing?.subscriptions ?? []) as ISubscription[];

  if (!subscriptions.length) return null;

  const dropdownItemClick = (type: string) => {
    switch (type) {
      case 'details':
        window.open('https://codekeeper.co/pricing.html', '_blank');
        break;
      case 'cancel': {
        if (feedbackSubmitted) setStep('review-changes');
        else setStep(exclusiveOfferApplied ? 'offer-applied' : 'feedback');
        break;
      }
      default:
        throw new Error('Invalid type');
    }
  };

  const hasExclusiveCouponExpired = subscriptionExclusiveCouponAppliedAt
    ? dayjs().isAfter(dayjs(subscriptionExclusiveCouponAppliedAt).add(30, 'days'))
    : true;

  return (
    <BillingCard title="Current Plan" className="mb-4">
      <BillingPlanDropdown
        onClick={dropdownItemClick}
        {...((!hasExclusiveCouponExpired || cancellationRequested || isAllPlansFree(subscriptions)) && {
          disable: {
            cancel:
              'Changes are still being applied to your account. Please allow 30 days before requesting new changes.',
          },
        })}
      />
      {subscriptions.map(
        (subscription, index) =>
          subscription.status === 'active' && (
            <Fragment key={index + '-' + subscription.currencyCode}>
              {subscription.plans.map((plan) => (
                <BillingPlan plan={plan} key={plan.name} />
              ))}

              {subscription.addons.map((addon) => (
                <BillingAddon className="mb-4" addon={addon} key={addon.name} />
              ))}

              {subscription.coupons?.map((coupon) => <BillingCoupon className="mb-4" key={coupon} coupon={coupon} />)}

              <BillingTotal
                className="mb-4"
                total={subscription.total}
                currency={subscription.currencyCode}
                period={subscription.billingPeriodUnit}
                billingPeriod={subscription.billingPeriod}
              />
            </Fragment>
          ),
      )}

      <AddCouponContainer />
    </BillingCard>
  );
};

const SubscriptionSkeleton = () => (
  <BillingCard title="Current Plan" className="mb-4">
    <Skeleton paragraph={{ rows: 3 }} />
    <Divider />
    <Skeleton paragraph={{ rows: 3 }} />
  </BillingCard>
);

export default withSuspense(BillingSubscriptionsContainer, SubscriptionSkeleton);
