import { Check } from 'lucide-react';

import { IconBadgeVerified, IconBadgeValidated, IconBadgeCertified } from 'src/components/Svg/Images';
import { cn } from '@/lib/utils';

const certificationSteps = [
  {
    id: 'validated',
    label: 'Validated',
    icon: IconBadgeValidated,
  },
  {
    id: 'verified',
    label: 'Verified',
    icon: IconBadgeVerified,
  },
  {
    id: 'certified',
    label: 'Certified',
    icon: IconBadgeCertified,
  },
] as const;

type Props<
  TStep extends {
    value: string;
    activeLabel: string;
    completedLabel: string;
  },
> = {
  mainSteps: TStep[];
  isDisabled?: boolean;
  activeStep: TStep['value'] | (typeof certificationSteps)[number]['id'];
};

const SoftwareItemProgress = <
  TStep extends {
    value: string;
    activeLabel: string;
    completedLabel: string;
  },
>({
  mainSteps,
  isDisabled,
  activeStep,
}: Props<TStep>) => {
  const activeMainStepIndex = mainSteps.findIndex((step) => step.value === activeStep);
  const activeCertificationStep = certificationSteps.find((step) => step.id === activeStep)?.id;

  // TODO: Later will be used for future certification steps
  // const activeCertificationStepIndex = certificationSteps.findIndex((step) => step.id === activeCertificationStep);

  return (
    <div className="flex relative z-[1]">
      <div className="flex grow">
        {mainSteps.map(({ activeLabel, completedLabel, value }, index) => {
          const isActive = index <= activeMainStepIndex || Boolean(activeCertificationStep);
          const isCompleted = index < activeMainStepIndex || Boolean(activeCertificationStep);

          return (
            <div key={value} className="group flex flex-col gap-2 flex-1 items-center py-0.5">
              <div
                className={cn(
                  'relative w-full group-first:before:hidden before:absolute before:bg-input before:w-1/2 before:left-0 before:h-0.5 before:top-1/2 before:-translate-y-1/2 after:absolute after:bg-input after:w-1/2 after:right-0 after:h-0.5 after:top-1/2 after:-translate-y-1/2 after:z-[0]',
                  isActive && 'before:bg-primary',
                  isCompleted && 'after:bg-primary',
                  isDisabled && 'before:bg-input after:bg-input',
                )}
              >
                <span
                  className={cn(
                    'relative mx-auto size-[14px] bg-input rounded-full flex items-center justify-center z-[1]',
                    isActive && 'bg-primary',
                    isDisabled && 'bg-input',
                  )}
                >
                  {isCompleted && <Check className="size-[10px] text-white" />}
                </span>
              </div>
              <span
                className={cn('text-xs', isActive ? 'text-primary' : 'text-ck-gray-300', isDisabled && 'text-input')}
              >
                {isCompleted ? completedLabel : activeLabel}
              </span>
            </div>
          );
        })}
      </div>

      {/* Certification steps */}
      <div className="shrink-0 flex flex-col items-center gap-1">
        <div
          className={cn(
            'relative flex items-center gap-2 before:absolute before:bg-input before:w-full before:h-0.5 before:left-0 before:top-1/2 before:-translate-y-1/2 before:z-[0] after:absolute after:bg-primary after:h-0.5 after:left-0 after:top-1/2 after:-translate-y-1/2 after:z-[0]',
          )}
        >
          {certificationSteps.map(({ id, icon }) => {
            const Icon = icon;

            return (
              <div key={id} className="size-5 bg-white rounded-full relative z-[1]">
                <Icon className={cn('size-5 opacity-30')} />
              </div>
            );
          })}
        </div>

        <div className="text-xs text-center text-input">Certification</div>
      </div>
    </div>
  );
};

// TODO: Later will be used for future certification steps
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FutureCertificationStep = ({
  activeCertificationStepIndex,
  activeCertificationStep,
  isDisabled,
}: {
  activeCertificationStepIndex: number;
  activeCertificationStep: string;
  isDisabled: boolean;
}) => (
  <div className="shrink-0 flex flex-col items-center gap-1">
    <div
      className={cn(
        'relative flex items-center gap-2 before:absolute before:bg-input before:w-full before:h-0.5 before:left-0 before:top-1/2 before:-translate-y-1/2 before:z-[0] after:absolute after:bg-primary after:h-0.5 after:left-0 after:top-1/2 after:-translate-y-1/2 after:z-[0]',
        activeCertificationStep === 'verified' && 'after:w-1/2',
        activeCertificationStep === 'certified' && 'after:w-full',
        isDisabled && 'after:w-0',
      )}
    >
      {certificationSteps.map(({ id, icon }, index) => {
        const Icon = icon;
        const isActive = id === activeCertificationStep;
        const isCompleted = index < activeCertificationStepIndex;

        return (
          <div key={id} className="size-5 bg-white rounded-full relative z-[1]">
            <Icon className={cn('size-5', ((!isActive && !isCompleted) || isDisabled) && 'opacity-30')} />
          </div>
        );
      })}
    </div>

    <div
      className={cn(
        'text-xs text-center',
        activeCertificationStep ? 'text-primary' : 'text-ck-gray-300',
        activeCertificationStep && !isDisabled && 'font-bold',
        isDisabled && 'text-input',
      )}
    >
      {certificationSteps[activeCertificationStepIndex]?.label ?? 'Certification'}
    </div>
  </div>
);

export default SoftwareItemProgress;
