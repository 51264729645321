export const collapseItems = [
  {
    id: 'invoices',
    header: {
      heading: 'All Invoices are paid',
      text: 'Please make sure you have settled all your invoices. Unpaid invoices may affect how your account is processed.',
      action: {
        text: 'Open Payment Info Page',
        url: '?t=payment-info',
      },
    },
    body: {
      label: {
        bgColor: 'green',
        text: 'Paid',
      } as const,
      text: 'Any unpaid invoices need to be settled for the account to be in good standing.',
    },
  },
  {
    id: 'escrows',
    header: {
      heading: 'Terminate active Software Escrows',
      text: 'Please make sure all escrows in your account have been terminated.',
      action: {
        text: 'Open Software Escrows Page',
        url: '/escrows',
      },
    },
    body: {
      label: {
        bgColor: 'red',
        text: 'Terminated',
      } as const,
      text: 'Both parties need to agree to escrow termination, and the legal notice period needs to be observed. After the notice period ends, the escrow will show this status indicating it has been legally terminated.',
    },
  },
  {
    id: 'backups',
    header: {
      heading: 'Remove active Backups',
      text: 'Please make sure you remove all active backups in your account.',
      action: {
        text: 'Open Backups Page',
        url: '/backups',
      },
    },
    body: {
      label: {
        bgColor: 'red',
        text: 'Removed',
      } as const,
      text: "Any active backups should be removed and Codekeeper's access to your source code management platform should be revoked.",
    },
  },
  {
    id: 'saas-escrows',
    header: {
      heading: 'Terminate active Continuity Escrows',
      text: 'Please make sure you terminate all active escrows in your account.',
      action: {
        text: 'Open Continuity Escrows Page',
        url: '/continuity-escrows',
      },
    },
    body: {
      label: {
        bgColor: 'red',
        text: 'Terminated',
      } as const,
      text: "All active SaaS escrows need to be terminated and Codekeeper's access to your providers should be revoked.",
    },
  },
];
