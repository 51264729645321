import LostBenefitItem from './LostBenefitItem';
import { benefitList } from './model/lost-benefit.enum';
import styles from './LostBenefit.module.scss';

const LostBenefitList = () => {
  return (
    <ul className={`flex flex-wrap list-none mb-8 ${styles.list}`}>
      {benefitList.map(({ id, text, title }) => (
        <li key={id}>
          <LostBenefitItem title={title} text={text} />
        </li>
      ))}
    </ul>
  );
};

export default LostBenefitList;
