import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

type Props = {
  title: string;
  description: string;
  icon: React.ElementType;
  btnText: string;
  onClick: () => void;
};

const SupportCard = ({ title, description, icon, btnText, onClick }: Props) => {
  const Icon = icon;

  return (
    <Card className="flex flex-col justify-between">
      <CardHeader>
        <CardTitle className="text-xl">{title}</CardTitle>
        <CardDescription className="">{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <Button onClick={onClick} className="w-full">
          <Icon />
          {btnText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SupportCard;
