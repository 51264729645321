import { Fragment } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';

import { IconSecureUpload } from 'src/components/Svg/Icons';

import styles from './SecureUploadSection.module.scss';

type Props = {
  isSelected?: boolean;
  onClick: () => void;
};

const SecureUploadSection = ({ isSelected = false, onClick }: Props) => {
  const buttonClasses = classNames('flex items-center', styles.button, {
    [styles.selected]: isSelected,
  });

  return (
    <Fragment>
      <h4 className={styles.heading}>Manual Upload</h4>

      <div className={buttonClasses} onClick={onClick} role="button" tabIndex={0}>
        <div className={styles.radio}>{isSelected && <CheckCircleFilled />}</div>
        <div className={styles.icon}>
          <IconSecureUpload />
        </div>
        <div className={styles['button-info']}>
          <strong className={styles['button-heading']}>Secure Upload</strong>
          <p className={styles['button-text']}>Directly upload your files and zip folders into escrow.</p>
        </div>
      </div>
    </Fragment>
  );
};

export default SecureUploadSection;
