import { usePageMeta } from 'src/hooks';
import { Main } from 'src/components/Layout';
import { OrganizationContainer } from 'src/containers/Organization';
import { UsersContainer } from 'src/containers/Users';

const Header = () => (
  <div className="py-4 px-6 border-t border-border">
    <div className="container mx-auto">
      <h1 className="text-2xl text-foreground">Organization</h1>
    </div>
  </div>
);

const OrganizationPage = () => {
  usePageMeta('Organization');

  return (
    <Main title="Organization" headerChildren={<Header />} className="bg-muted/60">
      <div className="container mx-auto">
        <OrganizationContainer />
        <UsersContainer />
      </div>
    </Main>
  );
};

export default OrganizationPage;
