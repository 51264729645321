import classNames from 'classnames';

import styles from './InfoDetailBlock.module.scss';

type Props = {
  items: { label: string; value: string }[];
  className?: string;
};

const InfoDetailBlock = ({ items = [], className }: Props) => {
  const listClassNames = classNames('list-none', styles.list, className);

  return (
    <ul className={listClassNames}>
      {items.map(({ label, value }) => (
        <li key={label}>
          <span>{label}</span>
          <strong>{value}</strong>
        </li>
      ))}
    </ul>
  );
};

export default InfoDetailBlock;
