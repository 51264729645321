import { ArrowUpRight } from 'lucide-react';
import { Link } from 'react-router';

import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';

export type IActionCard = {
  title: string;
  activeValue?: number;
  description: string;
  buttonLink: string;
  activeLabel: string;
  activeColor?: string;
};

type Props = IActionCard;

export const ActionCardSkeleton = () => {
  return (
    <div
      className="bg-card rounded-lg shadow-xs h-[134px] border border-border p-6 flex flex-col gap-2"
      aria-label="Loading..."
    >
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-1/2" />
      <Skeleton className="h-4 w-3/4" />
    </div>
  );
};

const ActionCard = ({ title, activeValue, description, buttonLink, activeLabel, activeColor }: Props) => {
  return (
    <div className="bg-card hover:bg-accent rounded-lg shadow-xs min-h-[118px] border border-border transition-colors">
      <Link to={buttonLink} className="p-6 flex flex-col gap-2 justify-between items-start h-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex items-center gap-2 min-w-0">
            <div className="text-sm font-medium text-card-foreground truncate">{title}</div>
          </div>
          <ArrowUpRight className="text-muted-foreground w-4 h-4 shrink-0" />
        </div>

        {activeValue ? (
          <div className="flex flex-col gap-0.5">
            <span className={cn('text-2xl font-bold leading-5', activeColor ?? 'text-foreground')}>{activeValue}</span>
            <span className="text-xs text-muted-foreground leading-4">Active {activeLabel}</span>
          </div>
        ) : (
          <p className="text-xs text-muted-foreground">{description}</p>
        )}
      </Link>
    </div>
  );
};

export default ActionCard;
