import { useState } from 'react';
import { Typography } from 'antd';

import {
  useToggleIntegrationMutation,
  IntegrationsDocument,
  IOauthIntegration,
  ICustomIntegration,
  IShareFileIntegration,
  IIntegrationsQuery,
} from 'src/graphql/schema';
import { externalInfoPaths } from 'src/shared/entities';
import { PROVIDER_KEY_TO_NAME } from 'src/shared/constants';
import { message } from 'src/components/Misc';

import ConfirmationModal from './ConfirmationModal';
import ActiveIntegrationCard from './ActiveIntegrationCard';
import UpdateCredentialsModal from './UpdateCredentialsModal';

const { Text } = Typography;

export type IActiveIntegration = IOauthIntegration & ICustomIntegration & IShareFileIntegration;

type Props = {
  integrations: IIntegrationsQuery['integrations']['nodes'];
};

export default function ActiveIntegrations({ integrations }: Props) {
  const [isRemoveModalVisible, setRemoveModalVisibility] = useState(false);
  const [integrationId, setIntegrationId] = useState('');
  const [confirmUpdateModalVisible, setConfirmUpdateModalVisible] = useState(false);
  const [openUpdateCredentials, setOpenUpdateCredentials] = useState(false);
  const [integration, setIntegration] = useState<IActiveIntegration>();

  const [toggleIntegration, { loading: loadingToggle }] = useToggleIntegrationMutation({
    refetchQueries: [IntegrationsDocument],
  });

  const toggleRemove = () => {
    setRemoveModalVisibility((isRemoveModalVisible) => !isRemoveModalVisible);
  };

  const deleteIntegration = async () => {
    try {
      const { data } = await toggleIntegration({ variables: { integrationId } });
      if (data?.toggleIntegration?.success) {
        message.success('Integration removed!');
      } else {
        message.error(data?.toggleIntegration?.errors?.[0] || "Integration wasn't disconnected!");
      }
    } catch (error) {
      message.error('Something went wrong');
      console.log(error);
    } finally {
      toggleRemove();
    }
  };

  const onMoreInfoClick = (provider: keyof typeof PROVIDER_KEY_TO_NAME) => () => {
    const path = externalInfoPaths[provider] ?? provider.toLowerCase().split(' ').join('_');

    window.open(`https://codekeeper.co/integrations/${path}.html`, '_blank');
  };

  const onReconnectIntegration = async (integrationId: string) => {
    setIntegrationId(integrationId);

    try {
      const { data } = await toggleIntegration({
        variables: {
          integrationId,
        },
      });

      if (data?.toggleIntegration?.success) {
        message.success('Integration re-connected!');
      } else {
        message.error(data?.toggleIntegration?.errors?.[0] || 'Something went wrong!');
      }
    } catch (error) {
      message.error('Something went wrong');
      console.log(error);
    } finally {
      setIntegrationId('');
    }
  };

  const toggleConfirmUpdateModal = () => {
    setConfirmUpdateModalVisible((confirmUpdateModalVisible) => !confirmUpdateModalVisible);
  };

  const toggleUpdateCredentials = () => {
    setConfirmUpdateModalVisible(false);
    setOpenUpdateCredentials((prevState) => !prevState);
  };

  const renderIntegrationDropdownMenu = (item: IActiveIntegration) => {
    return [
      ...(item.__typename === 'CustomIntegration'
        ? [
            {
              label: (
                <div
                  onClick={() => {
                    toggleConfirmUpdateModal();
                    setIntegration(item);
                  }}
                >
                  <Text>Update credentials</Text>
                </div>
              ),
              key: 'update',
            },
          ]
        : []),
      {
        label: (
          <div onClick={onMoreInfoClick(item.provider as keyof typeof PROVIDER_KEY_TO_NAME)}>
            <Text>More info</Text>
          </div>
        ),
        key: 'more',
      },
      ...(item.active && item.status !== 'pending'
        ? [
            {
              label: (
                <div
                  onClick={() => {
                    toggleRemove();
                    setIntegrationId(item.id);
                  }}
                >
                  <Text type="danger">Disconnect</Text>
                </div>
              ),
              key: 'remove',
            },
          ]
        : []),
    ];
  };

  const activateIntegration = (item: IActiveIntegration) => {
    onReconnectIntegration(item.id);
  };

  return (
    <div className="flex active-integrations__content">
      {integrations.map((integration) => {
        return (
          <ActiveIntegrationCard
            key={integration.id}
            integration={integration as IActiveIntegration}
            loading={integrationId === integration.id && loadingToggle}
            dropdownItems={renderIntegrationDropdownMenu(integration as IActiveIntegration)}
            handleOnClick={() => activateIntegration(integration as IActiveIntegration)}
          />
        );
      })}

      <ConfirmationModal
        title="Disconnect Integration"
        text="Are you sure you want to disconnect this integration?"
        okText="Disconnect"
        cancelText="Cancel"
        open={isRemoveModalVisible}
        onCancel={toggleRemove}
        onOk={deleteIntegration}
      />

      <ConfirmationModal
        title="Update credentials"
        text={'Are you sure you want to update your credentials for this integration?'}
        cancelText="Cancel"
        okText="Update"
        open={confirmUpdateModalVisible}
        onCancel={toggleConfirmUpdateModal}
        onOk={toggleUpdateCredentials}
      />

      {integration && (
        <UpdateCredentialsModal
          onFinish={() => setOpenUpdateCredentials(false)}
          integration={integration!}
          open={openUpdateCredentials}
          onCancel={toggleUpdateCredentials}
        />
      )}
    </div>
  );
}
