import { toast } from 'sonner';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { useDashboard } from 'src/containers/CustodianProfiles';
import { useRemoveCustodianProfileMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

const RemoveDialog = () => {
  const { removeDialogOpen, removeEscrowId, setRemoveDialogOpen, refetchCustodianProfiles } = useDashboard();
  const [removeCustodianProfile, { loading }] = useRemoveCustodianProfileMutation({
    onCompleted: () => refetchCustodianProfiles(),
  });

  const onCloseDialog = () => setRemoveDialogOpen(false, null);

  const onRemoveEscrow = async () => {
    if (!removeEscrowId) return;

    try {
      const { data } = await removeCustodianProfile({
        variables: {
          id: removeEscrowId,
        },
      });
      if (data?.removeCustodianProfile?.success) {
        toast.success('Profile deleted successfully');
        onCloseDialog();
      } else {
        throw new ClientError(data?.removeCustodianProfile?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, toast.error);
    }
  };

  return (
    <AlertDialog open={removeDialogOpen} onOpenChange={onCloseDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to remove this draft?</AlertDialogTitle>
          <AlertDialogDescription>
            The draft, and all related information will be removed from your dashboard.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onRemoveEscrow} isLoading={loading}>
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveDialog;
