import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  SET_TOTP_ENABLED = 'set-totp-enabled',
  ENABLE_TOTP = 'enable-totp',
  SHOW_DISABLE_WARNING = 'show-disable-warning',
  DISABLE_TOTP = 'disable-totp',
  DESTROY = 'destroy',
  SET_IS_LOADING = 'set-is-loading',
  CANCEL = 'cancel',
  SET_QR_CODE_SRC = 'set-qr-code-src',
}

type IActionReducer =
  | IAction<ActionEnum.SET_TOTP_ENABLED, boolean>
  | IAction<ActionEnum.ENABLE_TOTP>
  | IAction<ActionEnum.DISABLE_TOTP>
  | IAction<ActionEnum.SHOW_DISABLE_WARNING>
  | IAction<ActionEnum.DESTROY>
  | IAction<ActionEnum.SET_IS_LOADING, boolean>
  | IAction<ActionEnum.CANCEL>
  | IAction<ActionEnum.SET_QR_CODE_SRC, string>;

type IState = {
  isTOTPEnabled: boolean;
  enableDialogOpen: boolean;
  disableDialogOpen: boolean;
  disableAlertOpen: boolean;
  isLoading: boolean;
  qrCodeSrc: string;
  isQRCodeLoading: boolean;
};

const initialState: IState = {
  isTOTPEnabled: false,
  enableDialogOpen: false,
  disableDialogOpen: false,
  disableAlertOpen: false,
  isLoading: false,
  qrCodeSrc: '',
  isQRCodeLoading: false,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_TOTP_ENABLED:
      return { ...state, isTOTPEnabled: action.payload };
    case ActionEnum.ENABLE_TOTP:
      return { ...state, isTOTPEnabled: true, enableDialogOpen: true, isQRCodeLoading: true };
    case ActionEnum.DISABLE_TOTP:
      return { ...state, disableAlertOpen: false, disableDialogOpen: true };
    case ActionEnum.SHOW_DISABLE_WARNING:
      return { ...state, isTOTPEnabled: false, disableAlertOpen: true };
    case ActionEnum.DESTROY:
      return {
        ...state,
        enableDialogOpen: false,
        disableDialogOpen: false,
        disableAlertOpen: false,
        qrCodeSrc: '',
      };
    case ActionEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionEnum.CANCEL:
      return {
        ...state,
        isTOTPEnabled: !state.isTOTPEnabled,
        enableDialogOpen: false,
        disableDialogOpen: false,
        disableAlertOpen: false,
        qrCodeSrc: '',
        isQRCodeLoading: false,
      };
    case ActionEnum.SET_QR_CODE_SRC:
      return { ...state, qrCodeSrc: action.payload, isQRCodeLoading: false };
    default:
      return state;
  }
};

export const useTOTPSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setTOTPEnabled = (totpEnabled: boolean) =>
    dispatch({ type: ActionEnum.SET_TOTP_ENABLED, payload: totpEnabled });
  const enableTOTP = () => dispatch({ type: ActionEnum.ENABLE_TOTP, payload: {} });
  const disableTOTP = () => dispatch({ type: ActionEnum.DISABLE_TOTP, payload: {} });
  const showDisableAlert = () => dispatch({ type: ActionEnum.SHOW_DISABLE_WARNING, payload: {} });
  const destroy = () => dispatch({ type: ActionEnum.DESTROY, payload: {} });
  const setIsLoading = (isLoading: boolean) => dispatch({ type: ActionEnum.SET_IS_LOADING, payload: isLoading });
  const cancel = () => dispatch({ type: ActionEnum.CANCEL, payload: {} });
  const setQRCodeSrc = (qrCodeSrc: string) => dispatch({ type: ActionEnum.SET_QR_CODE_SRC, payload: qrCodeSrc });

  return {
    ...state,
    setTOTPEnabled,
    enableTOTP,
    disableTOTP,
    showDisableAlert,
    destroy,
    setIsLoading,
    cancel,
    setQRCodeSrc,
  };
};
