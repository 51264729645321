import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import countries from 'src/shared/entities/countries';
import { Card } from '@/components/ui/card';
import { useUpdateCompanyMutation, CompanyDocument, ICompanyQuery } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { apolloClient as client } from 'src/graphql';

import { useOrganization } from '../model';

const FormSchema = z.object({
  companyName: z.string().min(1, 'Company name is required'),
  street: z.string().min(1, 'Street is required'),
  city: z.string().min(1, 'City code is required'),
  postalCode: z.string().min(1, 'Postal code is required'),
  country: z.string().min(1, 'Country is required'),
  streetNumber: z.string().optional(),
  region: z.string().optional(),
  companyWebsite: z.string().optional(),
  companyRegistration: z.string().optional(),
});

const OrganizationForm = () => {
  const { setDetailsDialogOpen } = useOrganization();
  const data = client.readQuery<ICompanyQuery>({
    query: CompanyDocument,
  });
  const [updateCompany, { loading }] = useUpdateCompanyMutation();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      companyName: data?.company.companyName || '',
      street: data?.company.street || '',
      streetNumber: data?.company.streetNumber || '',
      city: data?.company.city || '',
      postalCode: data?.company.postalCode || '',
      country: data?.company.country || '',
      region: data?.company.region || '',
      companyWebsite: data?.company.companyWebsite || '',
      companyRegistration: data?.company.companyRegistration || '',
    },
  });

  async function onSubmit(dataForm: z.infer<typeof FormSchema>) {
    try {
      const { data } = await updateCompany({
        variables: {
          companyInput: dataForm,
        },
      });
      if (data?.updateCompany?.success) {
        toast.success('Details updated!');
        setDetailsDialogOpen(false);
      } else {
        throw new ClientError('Something went wrong');
      }
    } catch (error) {
      getApiError(error, toast.error);
    }
  }

  const onCancel = () => setDetailsDialogOpen(false);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="p-6 mb-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 space-y-4">
              <FormField
                control={form.control}
                name="companyName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="companyWebsite"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Website <i>(optional)</i>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="companyRegistration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Registration number <i>(optional)</i>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="col-span-1 space-y-4">
              <FormField
                control={form.control}
                name="street"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Street address</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="streetNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Number / Suite / Unit / Floor <i>(optional)</i>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="region"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      State / Province / Region <i>(optional)</i>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="postalCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Zip / Postal code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {countries.map(({ code, name }) => (
                          <SelectItem value={name} key={code}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </Card>

        <div className="flex justify-between">
          <Button variant="outline" onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button type="submit" isLoading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default OrganizationForm;
