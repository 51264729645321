import { useEffect } from 'react';
import { MessageCircle } from 'lucide-react';

import { Button } from '@/components/ui/button';

const SupportBanner = () => {
  useEffect(() => {
    const status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }

    return () => window.HubSpotConversations.widget.remove();
  }, []);

  const openCSChat = () => window.HubSpotConversations.widget.open();

  return (
    <section className="bg-primary p-6 rounded-lg shadow-sm mb-6">
      <div className="flex items-center justify-between gap-4">
        <div className="grow">
          <h2 className="text-base text-primary-foreground font-semibold mb-1">Need support?</h2>
          <p className="text-primary-foreground/80">Use the chat function below to get in touch. </p>
        </div>
        <Button variant="outline" onClick={openCSChat} className="shrink-0">
          <MessageCircle />
          Contact us
        </Button>
      </div>
    </section>
  );
};

export default SupportBanner;
