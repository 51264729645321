import {
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  SidebarContent,
  SidebarFooter,
  useSidebar,
} from '@/components/ui/sidebar';
import { useIsMobile } from 'src/hooks/use-mobile';
import { cn } from 'src/lib/utils';

const SidebarSkeleton = () => {
  const { open } = useSidebar();
  const isMobile = useIsMobile();

  if (isMobile) return null;

  return (
    <SidebarMenu
      className={cn(
        'bg-white border-r border-border shrink-0 px-2 py-3',
        open ? 'w-[var(--sidebar-width)]' : 'w-[var(--sidebar-width-mobile)]',
      )}
    >
      <SidebarContent>
        {Array.from({ length: 4 }).map((_, index) => (
          <SidebarMenuItem key={index}>
            <SidebarMenuSkeleton showIcon={open} fullWidth={!open} />
          </SidebarMenuItem>
        ))}
      </SidebarContent>
      <SidebarFooter>
        {Array.from({ length: 3 }).map((_, index) => (
          <SidebarMenuItem key={index}>
            <SidebarMenuSkeleton showIcon={open} className="p-0" fullWidth={!open} />
          </SidebarMenuItem>
        ))}
      </SidebarFooter>
    </SidebarMenu>
  );
};

export default SidebarSkeleton;
