import { useRef, useTransition } from 'react';
import { Search, Loader2, XIcon, ListFilter } from 'lucide-react';
import { debounce } from 'lodash';

import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

import { SoftwareDashboardItemSkeleton } from '../Item/SoftwareDashboardItem';

type Props = {
  title: string;
  createAction?: React.ReactNode;
  onSearch?: (value: string) => void;
  filterOptions: {
    value: string;
    label: string;
  }[];
  activeFilter: string;
  setActiveFilter?: (value: string) => void;
};

export const SoftwareDashboardSkeleton = ({ count = 4 }) => (
  <div className="container mx-auto p-6">
    <div className="grid grid-cols-2 gap-4 mb-6">
      {Array.from({ length: count }).map((_, index) => (
        <SoftwareDashboardItemSkeleton key={index} />
      ))}
    </div>
  </div>
);

const SoftwareDashboardLayout = ({
  title,
  createAction,
  onSearch,
  children,
  filterOptions,
  activeFilter,
  setActiveFilter,
}: React.PropsWithChildren<Props>) => {
  const [isPending, startTransition] = useTransition();
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedOnSearch = debounce((e) => startTransition(() => onSearch?.(e.target.value)), 500);

  const onClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      onSearch?.('');
    }
  };

  const Icon = isPending ? Loader2 : Search;

  return (
    <div className="flex flex-col h-full bg-muted/60">
      <div className="bg-background border-b border-border py-4 shrink-0">
        <div className="container mx-auto px-4 sm:px-6 flex flex-col gap-6">
          <div className="flex justify-between md:items-center gap-2 sm:gap-6 md:flex-row flex-col">
            <h1 className="text-xl sm:text-2xl font-bold text-foreground shrink-0">{title}</h1>

            <div className="flex items-center gap-2 grow justify-end">
              <div className="relative md:max-w-[320px] w-full">
                <Icon
                  className={cn(
                    'absolute left-2 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4',
                    isPending && 'animate-spin',
                  )}
                />
                <Input placeholder="Search" className="pl-8" onChange={debouncedOnSearch} ref={inputRef} />
                {inputRef.current?.value && (
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7"
                    onClick={onClear}
                  >
                    <XIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
              <Select onValueChange={setActiveFilter} value={activeFilter}>
                <SelectTrigger className="w-[200px]" icon={ListFilter}>
                  <SelectValue placeholder="Filter" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.map(({ value, label }) => (
                      <SelectItem value={value} key={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {createAction}
            </div>
          </div>
        </div>
      </div>

      <div className="grow overflow-y-auto">
        <div className="container mx-auto p-4 sm:p-6">{children}</div>
      </div>
    </div>
  );
};

export default SoftwareDashboardLayout;
