import { useSuspenseQuery } from '@apollo/client';
import { Fragment, useState, startTransition } from 'react';
import { Pagination, Button } from 'antd';

import { IntegrationsDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { IntegrationRadioGroup } from 'src/components/Integration/Radio';
import { SecureUploadSection } from 'src/components/Deposit/Add';
import { TextBlock } from 'src/components/Misc';
import { useAddDeposit } from 'src/context/deposit';

import type { IIntegrationsQuery } from 'src/graphql/schema';

const INTEGRATIONS_LIMIT = 8;

const methodTypeMap = {
  CustomIntegration: 'custom',
  OauthIntegration: 'oauth',
};

const IntegrationList = withSuspense(() => {
  const [page, setPage] = useState(1);

  const { data } = useSuspenseQuery<IIntegrationsQuery>(IntegrationsDocument, {
    variables: { active: true, perPage: INTEGRATIONS_LIMIT, page },
  });
  const {
    ui: { integration, selectMethod },
  } = useAddDeposit();

  const integrations = data.integrations.nodes
    // Filter out ShareFileIntegration as it's not supported
    .filter(({ __typename }) => __typename !== 'ShareFileIntegration')
    .map(({ id, provider, accountName, __typename }) => ({
      id,
      platform: provider,
      name: accountName ?? '',
      type: methodTypeMap[__typename as keyof typeof methodTypeMap] as 'custom' | 'oauth',
    }));

  const onIntegrationClick = (id: string) => {
    const integration = integrations.find((integration) => integration.id === id);

    if (!integration) return;
    const { type, ...rest } = integration;

    selectMethod(type, rest);
  };

  const onPageChange = (newPage: number) => startTransition(() => setPage(newPage));

  return (
    <div className="mb-8">
      <div className="mb-4">
        <IntegrationRadioGroup
          heading="Automated Integrations"
          items={integrations}
          onClick={onIntegrationClick}
          selectedItemId={integration?.id}
        />
      </div>
      <Pagination
        align="end"
        pageSize={INTEGRATIONS_LIMIT}
        hideOnSinglePage
        total={data.integrations.nodesCount}
        onChange={onPageChange}
      />
    </div>
  );
}, IntegrationRadioGroup.Skeleton);

const SelectDepositMethodContainer = () => {
  const {
    ui: { setStep, method, history, selectMethod, getBack },
  } = useAddDeposit();

  const onSelectSecure = () => selectMethod('secure');
  const confirmMethod = () => setStep(method as NonNullable<typeof method>);

  return (
    <Fragment>
      <TextBlock
        className="mb-8"
        title="Deposit Method"
        text="Please choose the deposit method from the options below."
      />

      <div className="mb-8">
        <IntegrationList />
        <SecureUploadSection isSelected={method === 'secure'} onClick={onSelectSecure} />
      </div>

      <div className="flex justify-between">
        {history.length > 1 && (
          <Button type="text" onClick={getBack}>
            Back
          </Button>
        )}

        <div className="flex justify-end grow">
          <Button type="primary" disabled={!method} onClick={confirmMethod}>
            Next
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectDepositMethodContainer;
