import { Mail, Clock } from 'lucide-react';

import { Main, PageHeader } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';

import { SupportCard, SupportBanner } from './ui';

export default function SupportContent() {
  usePageMeta('Support');

  const openSalesChat = () => window.open('https://codekeeper.co/contact-sales', '_blank');

  const openMail = (url: string) => () => window.open(`mailto:${url}`, '_blank');

  return (
    <Main title="Support" className="bg-muted/60" headerChildren={<PageHeader title="Support" />}>
      <div className="container mx-auto">
        <SupportBanner />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <SupportCard
            title="Need more help?"
            description="Send an email to our Customer Success team, who will reach out as soon as possible."
            btnText="Contact Customer Success"
            icon={Mail}
            onClick={openMail('service@codekeeper.co')}
          />
          <SupportCard
            title="Need to talk to Sales?"
            description="Use our website chat feature, or reach out to us using the button below."
            btnText="Contact Sales"
            onClick={openSalesChat}
            icon={Mail}
          />
          <SupportCard
            title="Release request"
            description="Send a release request email by clicking the button below. Our emergency channel is monitored 24/7/365."
            btnText="Request a release"
            icon={Clock}
            onClick={openMail('release@codekeeper.co')}
          />
        </div>
      </div>
    </Main>
  );
}
