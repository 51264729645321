import { Button, Dropdown, Input } from 'antd';
import {
  SearchOutlined,
  CheckOutlined,
  MoreOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';

import { AssetsOrderByEnum } from 'src/graphql/schema';

import styles from './DepositAssetsActions.module.scss';

type Props = {
  filter: AssetsOrderByEnum | null;
  order: string;
  onOrderChange: () => Promise<void>;
  onFilterChange: (value: AssetsOrderByEnum | null) => Promise<void>;
  onSearchChange: IDebouncedFunc<(value: string) => Promise<void>>;
};

const DepositAssetsActions: React.FunctionComponent<Props> = ({
  filter,
  order,
  onOrderChange,
  onFilterChange,
  onSearchChange,
}) => {
  const depositsOrderByItems = Object.values(AssetsOrderByEnum).map((value: AssetsOrderByEnum, key: number) => {
    return {
      label: (
        <div className="capitalize" onClick={() => onFilterChange(value)} key={key}>
          {value.replace(/_/g, ' ')} {filter === value && <CheckOutlined />}
        </div>
      ),
      key: value,
    };
  });

  return (
    <div className={styles.row}>
      <div className="flex items-center justify-between active-deposits__toolbar">
        <Input
          placeholder="Search deposits"
          className="w-[256px]!"
          onChange={(e) => onSearchChange(e.target.value)}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          allowClear
        />
        <div className="flex active-deposits__btns">
          <Button className="ml-2 flex items-center" onClick={onOrderChange}>
            {order === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
          </Button>
          <Dropdown menu={{ items: depositsOrderByItems }} trigger={['click']} placement="bottomRight">
            <Button className="ml-2 flex items-center">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default DepositAssetsActions;
