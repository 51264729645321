import { BackupStatusEnum } from 'src/graphql/schema';

export const filterOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: BackupStatusEnum.Draft,
    label: 'Drafts',
  },
  {
    value: BackupStatusEnum.Active,
    label: 'Active',
  },
];
