import { Outlet } from 'react-router';
import { useReadQuery } from '@apollo/client';
import { Fragment } from 'react';

import { BackupsContainer, DashboardContextProvider, useDashboard, filterOptions } from 'src/containers/Backups';
import { Main } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';
import { SoftwareDashboardLayout } from 'src/components/SoftwareDashboard';

import type { IBackupsQuery } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';

import { NewBackupAction, Intro, RemoveDialog } from './ui';

export type IBackupOutletContext = {
  queryRef: QueryRef<IBackupsQuery>;
};

const Dashboard = () => {
  const { setQuery, queryRef, status, query, setStatusFilter } = useDashboard();
  const {
    data: { backups },
  } = useReadQuery<IBackupsQuery>(queryRef);

  const isEmpty = !backups.nodesCount && status === 'all' && !query;

  return (
    <Fragment>
      <Main title="Software Backup" noPaddingOnContent>
        <SoftwareDashboardLayout
          title="Backups"
          createAction={<NewBackupAction />}
          onSearch={!isEmpty ? setQuery : undefined}
          filterOptions={filterOptions}
          activeFilter={status}
          setActiveFilter={!isEmpty ? setStatusFilter : undefined}
        >
          {isEmpty ? <Intro /> : <BackupsContainer />}
        </SoftwareDashboardLayout>
      </Main>

      <Outlet />
    </Fragment>
  );
};

const BackupsPage = () => {
  usePageMeta('Software Backup');

  return (
    <DashboardContextProvider>
      <Dashboard />
      <RemoveDialog />
      <Outlet />
    </DashboardContextProvider>
  );
};

export default BackupsPage;
