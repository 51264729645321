import { JourneyLayout } from 'src/components/Journey';
import { useSaasEscrow } from 'src/context/saas';
import { usePageMeta } from 'src/hooks';

import SaasFooterContainer from './SaasFooterContainer';
import SaasHeaderContainer from './SaasHeaderContainer';
import SaasMenuContainer from './SaasMenuContainer';
import SaasStatusIndicatorContainer from './SaasStatusIndicatorContainer';
import SaasAlertBannerContainer from './SaasAlertBannerContainer';
import SaasNavigationContainer from './SaasNavigationContainer';

const SaasLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const {
    environment: { name },
    error,
  } = useSaasEscrow();
  usePageMeta(name);

  return (
    <JourneyLayout
      header={<SaasHeaderContainer />}
      error={error && { goTo: '/continuity-escrows', ...error }}
      statusIndicator={<SaasStatusIndicatorContainer />}
      menu={<SaasMenuContainer />}
    >
      <SaasAlertBannerContainer />
      {children}
      <SaasFooterContainer />
      <SaasNavigationContainer />
    </JourneyLayout>
  );
};

export default SaasLayoutContainer;
