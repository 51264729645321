import { IJourneyMenuItem } from 'src/components/Journey/Menu';
import { IconProvider, IconCertificate } from 'src/components/Svg/Icons';

export const saasMenuItems: Array<IJourneyMenuItem & { path: string }> = [
  {
    path: 'providers',
    label: 'Providers',
    icon: IconProvider,
  },
  {
    path: 'certificate',
    label: 'Certificate',
    icon: IconCertificate,
    isDisabled: true,
  },
];
