import { CustodianProfileStatusEnum } from 'src/graphql/schema';
import { OtherCustodianProfileStatusEnum } from 'src/shared/constants';

export const filterOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: CustodianProfileStatusEnum.Active,
    label: 'Active',
  },
  {
    value: CustodianProfileStatusEnum.Draft,
    label: 'Drafts',
  },
  {
    value: CustodianProfileStatusEnum.AccessSetup,
    label: 'Access Setup',
  },
  {
    value: OtherCustodianProfileStatusEnum.TerminationRequested,
    label: 'Pending termination',
  },
];
