import { QueryRef, useBackgroundQuery } from '@apollo/client';
import { useSearchParams } from 'react-router';

import { createRequiredContext } from 'src/context/createRequiredContext';
import { type IBackupsQuery, BackupsDocument, IBackupsQueryVariables, BackupStatusEnum } from 'src/graphql/schema';
import { BACKUPS_LIMIT } from 'src/shared/constants';
import { apolloClient as client } from 'src/graphql/client/apollo-client';

import { setValidStatus } from '../lib';

import { useDashboardSlice } from './useDashboardSlice';

const [useDashboard, DashboardProvider] = createRequiredContext<
  ReturnType<typeof useDashboardSlice> & {
    queryRef: QueryRef<IBackupsQuery>;
    status: string;
    setStatusFilter: (status: string) => void;
    refetchBackups: () => void;
  }
>();

const DashboardContextProvider = ({ children }: React.PropsWithChildren) => {
  const dashboardSlice = useDashboardSlice();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = setValidStatus(searchParams.get('t'));

  const variables = {
    page: dashboardSlice.page,
    perPage: BACKUPS_LIMIT,
    ...(status && { status: status as BackupStatusEnum }),
    ...(dashboardSlice.query && { query: dashboardSlice.query }),
  };
  const [queryRef] = useBackgroundQuery<IBackupsQuery, IBackupsQueryVariables>(BackupsDocument, {
    variables,
  });

  const setStatusFilter = (status: string) => {
    setSearchParams({ t: status });
    dashboardSlice.setPage(1);
  };

  const refetchBackups = () => {
    client.cache.evict({ fieldName: 'backups' });
    client.cache.gc();
    client.refetchQueries({
      include: [BackupsDocument],
    });
  };

  const values = {
    queryRef,
    status: status || 'all',
    setStatusFilter,
    refetchBackups,
    ...dashboardSlice,
  };

  return <DashboardProvider value={values}>{children}</DashboardProvider>;
};

export { useDashboard, DashboardContextProvider };
