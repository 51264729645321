import { useNavigate } from 'react-router';

import { Button } from '@/components/ui/button';

const NewEscrowAction = () => {
  const navigate = useNavigate();

  const handleCreateBackup = () => navigate('/escrows/new');

  return <Button onClick={handleCreateBackup}>+ New Escrow</Button>;
};

export default NewEscrowAction;
