import { Button } from 'antd';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';

import { CancelCollapse } from 'src/components/Billing/Cancel/Plan';
import { collapseItems } from 'src/components/Billing/Cancel/Plan/Collapse/model/cancel-collapse.enum';
import { useCancelationPlan } from 'src/context/billing';
import { useCanCancelSubscriptionQuery, useCancelSubscriptionMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';
import { message } from 'src/components/Misc';

type Props = {
  onBack: () => void;
};

const PreCancelationContainer = ({ onBack }: Props) => {
  const {
    ui: { setStep, setInitialData, getBack },
  } = useCancelationPlan();
  const navigate = useNavigate();
  const stepsToFinish: string[] = [];
  const { data, loading } = useCanCancelSubscriptionQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [cancelSubscription, { loading: isCanceling }] = useCancelSubscriptionMutation();

  Boolean(data?.invoices.nodes.length) && stepsToFinish.push('invoices');
  data?.allBackups.nodesCount !== data?.removedBackups.nodesCount && stepsToFinish.push('backups');
  data?.allEscrows.nodesCount !== data?.terminatedEscrows.nodesCount && stepsToFinish.push('escrows');
  data?.allSaasEnvironments.nodesCount !== data?.terminatedSaasEnvironments.nodesCount &&
    stepsToFinish.push('saas-escrows');

  const onFinishCancelation = async () => {
    try {
      const { data } = await cancelSubscription();

      if (data?.cancelSubscription?.errors?.length) {
        throw new ClientError(data.cancelSubscription.errors[0]);
      } else setStep('cancelation-finished');
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  return (
    <Fragment>
      <div className="mb-8">
        {collapseItems.map(({ header, body, id }) => {
          const onClick = () => {
            onBack();
            navigate(header.action.url);
          };
          const action = stepsToFinish.includes(id) ? { ...header.action, onClick } : undefined;

          return (
            <CancelCollapse
              key={id}
              className="mb-4"
              header={{
                ...header,
                action,
                isLoading: loading,
              }}
              body={body}
            />
          );
        })}
      </div>

      <div className="flex justify-between">
        <Button type="text" onClick={getBack}>
          Back
        </Button>
        <div className="flex gap-3">
          <Button
            onClick={onFinishCancelation}
            type="primary"
            ghost
            disabled={loading || Boolean(stepsToFinish.length)}
            loading={isCanceling}
          >
            Finish Cancelation
          </Button>
          <Button type="primary" onClick={setInitialData}>
            Keep Plan
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default PreCancelationContainer;
