import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  SET_INITIAL_DATA = 'set-initial-data',
  EDIT_USER = 'edit-user',
  ADD_USER = 'add-user',
  REMOVE_USER = 'remove-user',
  SET_REMOVE_DIALOG_OPEN = 'set-remove-dialog-open',
  TRANSFER_OWNERSHIP = 'transfer-ownership',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA>
  | IAction<ActionEnum.ADD_USER>
  | IAction<ActionEnum.EDIT_USER, string>
  | IAction<ActionEnum.REMOVE_USER, string>
  | IAction<ActionEnum.SET_REMOVE_DIALOG_OPEN, boolean>
  | IAction<ActionEnum.TRANSFER_OWNERSHIP, string>;

export type IState = {
  dialogOpen: boolean;
  userId: string | null;
  type: 'add' | 'edit' | 'transfer-ownership' | null;
  removeDialogOpen: boolean;
  transferOwnershipDialogOpen: boolean;
};

const initialState: IState = {
  dialogOpen: false,
  userId: null,
  type: null,
  removeDialogOpen: false,
  transferOwnershipDialogOpen: false,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.ADD_USER:
      return {
        ...state,
        dialogOpen: true,
        type: 'add',
      };
    case ActionEnum.EDIT_USER:
      return {
        ...state,
        dialogOpen: true,
        type: 'edit',
        userId: action.payload,
      };
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    case ActionEnum.REMOVE_USER:
      return {
        ...state,
        removeDialogOpen: true,
        userId: action.payload,
      };
    case ActionEnum.SET_REMOVE_DIALOG_OPEN:
      return {
        ...state,
        removeDialogOpen: action.payload,
      };
    case ActionEnum.TRANSFER_OWNERSHIP:
      return {
        ...state,
        transferOwnershipDialogOpen: true,
        userId: action.payload,
      };
    default:
      return state;
  }
};

export const useUserDialogSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openAddDialog = () => dispatch({ type: ActionEnum.ADD_USER, payload: {} });
  const openEditDialog = (userId: string) => dispatch({ type: ActionEnum.EDIT_USER, payload: userId });
  const openRemoveDialog = (userId: string) => dispatch({ type: ActionEnum.REMOVE_USER, payload: userId });
  const closeDialog = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });
  const closeRemoveDialog = () => dispatch({ type: ActionEnum.SET_REMOVE_DIALOG_OPEN, payload: false });
  const openTransferOwnershipDialog = (userId: string) =>
    dispatch({ type: ActionEnum.TRANSFER_OWNERSHIP, payload: userId });

  return {
    ...state,
    openAddDialog,
    openEditDialog,
    openRemoveDialog,
    closeDialog,
    closeRemoveDialog,
    openTransferOwnershipDialog,
  };
};
