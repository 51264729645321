import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';
import { OtherSaasEnvironmentStatusEnum } from 'src/shared/constants';

export const statusMap = {
  [SaasEnvironmentStatusEnum.Active]: {
    label: 'Active',
    color: 'text-green-800',
    bgColor: 'bg-green-100',
  },
  [SaasEnvironmentStatusEnum.Draft]: {
    label: 'Draft',
    color: 'text-orange-800',
    bgColor: 'bg-orange-100',
  },
  [SaasEnvironmentStatusEnum.Pending]: {
    label: 'Pending Activation',
    color: 'text-gray-800',
    bgColor: 'bg-gray-100',
  },
  [SaasEnvironmentStatusEnum.Terminated]: {
    label: 'Terminated',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
  [OtherSaasEnvironmentStatusEnum.TerminationRequested]: {
    label: 'Pending Termination',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
};
