import { SoftwareDashboardEmpty } from 'src/components/SoftwareDashboard';
import Profiles from 'src/assets/images/dashboard/profiles.jpg';
import ProfilesPlaceholder from 'src/assets/images/dashboard/profiles-placeholder.jpg';

import CreateCustodianProfile from './CreateCustodianProfile';

const items = [
  'As custodian we handle data operations in catastrophic situations where you or vendors can’t.',
  'Some industries demand strict data handling—even during disruptions. We manage compliance and continuity.',
  'As custodian of your software and data, we handle full accountability to stakeholders. ',
];

export const Intro = () => {
  return (
    <SoftwareDashboardEmpty
      title="End-to-end accountability for the entire software life cycle"
      image={Profiles}
      placeholderSrc={ProfilesPlaceholder}
      items={items}
      label="software custodian"
      textAccent="text-muted-foreground"
      learnMoreLink="https://codekeeper.co/software-custodian"
      newButton={<CreateCustodianProfile />}
    />
  );
};

export default Intro;
