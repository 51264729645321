import { SidebarTrigger } from '@/components/ui/sidebar';
import { cn } from '@/lib/utils';

type Props = {
  title: string;
  headerActions?: React.ReactNode;
  headerChildren?: React.ReactNode;
  className?: string;
  mainContentClassName?: string;
  noPaddingOnContent?: boolean;
};

const Main = ({
  title,
  headerActions,
  headerChildren,
  children,
  noPaddingOnContent = false,
  mainContentClassName,
  className,
}: React.PropsWithChildren<Props>) => {
  const mainClasses = cn('flex flex-col grow min-h-0 min-w-0', className);
  const mainContentClasses = cn('overflow-y-auto grow', mainContentClassName, {
    'p-0': noPaddingOnContent,
    'p-6': !noPaddingOnContent,
  });

  return (
    <main className={mainClasses}>
      <div className="shrink-0 bg-white border-b border-border relative">
        <div className="flex justify-between items-center h-12 sm:h-16 py-4 px-4 sm:px-6 gap-2">
          <div className="flex items-center">
            <SidebarTrigger className="text-foreground" />
            <div className="w-px h-[15px] bg-border rounded-full mr-2" />
            <div className="text-sm text-foreground">{title}</div>
          </div>

          {headerActions && <div className="shrink-0">{headerActions}</div>}
        </div>

        {headerChildren}
      </div>

      <div className={mainContentClasses}>{children}</div>
    </main>
  );
};

export default Main;
