import { Fragment, useMemo, useState, useTransition } from 'react';
import { FetchResult, useSuspenseQuery } from '@apollo/client';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowUpRight } from 'lucide-react';
import { useNavigate } from 'react-router';
import relativeTime from 'dayjs/plugin/relativeTime';

import { NotificationsDocument, useMarkAsInteractedNotificationMutation } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';
import { NOTIFICATIONS_LIMIT } from 'src/shared/constants';
import { Table, TableRow, TableBody, TableCell } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { SimplePagination } from '@/components/ui/simple-pagination';

import type { IMarkAsInteractedNotificationMutation, INotificationsQuery } from 'src/graphql/schema';

import { getNotificationContent, notificationTypeMap, eventTypes } from './model';

dayjs.extend(relativeTime);

type INotificationRow = {
  info: {
    title: string;
    description: string;
  } | null;
  createdAt: string;
  type: keyof typeof notificationTypeMap;
  action: {
    notificationId: string;
    parentId: string | null;
  };
};

const columnHelper = createColumnHelper<INotificationRow>();
const columns = (
  markAsInteracted: (
    options: Parameters<typeof useMarkAsInteractedNotificationMutation>[0],
  ) => Promise<FetchResult<IMarkAsInteractedNotificationMutation>>,
  navigate: (path: string) => void,
  withActionColumn: boolean,
) => [
  columnHelper.accessor('info', {
    cell: (props) => {
      const value = props.getValue();

      if (!value) return null;

      return (
        <div className="min-w-[250px]">
          <div className="text-foreground text-sm font-semibold">{value.title}</div>
          <p className="text-muted-foreground text-sm">{value.description}</p>
        </div>
      );
    },
  }),
  columnHelper.accessor('type', {
    cell: (props) => (
      <Badge className={cn('whitespace-nowrap', notificationTypeMap[props.getValue()].bgBadge)}>
        {notificationTypeMap[props.getValue()].badge}
      </Badge>
    ),
  }),
  columnHelper.accessor('createdAt', {
    cell: (props) => (
      <span className="text-foreground text-xs whitespace-nowrap">{dayjs(props.getValue(), 'YYYYMMDD').fromNow()}</span>
    ),
  }),
  ...(withActionColumn
    ? [
        columnHelper.accessor('action', {
          cell: (props) => {
            const { notificationId, parentId } = props.getValue();
            const baseUrl = notificationTypeMap[props.row.getValue('type') as keyof typeof notificationTypeMap].baseUrl;
            const url = parentId ? `${baseUrl}/${parentId}` : baseUrl;
            const handleClick = () => {
              markAsInteracted({ variables: { id: notificationId } });
              navigate(url);
            };

            return (
              <Button variant="link" onClick={handleClick} data-testid="notification-link">
                <ArrowUpRight />
              </Button>
            );
          },
        }),
      ]
    : []),
];

type Props = {
  interacted?: boolean | null;
  withActionColumn?: boolean;
  emptyBlock: React.ElementType;
};

const NotificationContainer = ({ interacted, withActionColumn = true, emptyBlock }: Props) => {
  const [page, setPage] = useState(1);
  const [isPending, startTransition] = useTransition();
  const [markAsInteracted] = useMarkAsInteractedNotificationMutation();
  const navigate = useNavigate();
  const { data } = useSuspenseQuery<INotificationsQuery>(NotificationsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page,
      perPage: NOTIFICATIONS_LIMIT,
      ...(typeof interacted === 'boolean' && { interacted }),
    },
  });
  const notifications: INotificationRow[] = useMemo(
    () =>
      data.notifications.nodes.map(({ id, eventType, payload, createdAt, parentId, parentType }) => ({
        type: parentType as keyof typeof notificationTypeMap,
        info: getNotificationContent(eventType as (typeof eventTypes)[number], payload),
        createdAt,
        action: {
          notificationId: id,
          parentId: eventType === 'escrow_invitation_sent' ? null : parentId,
        },
      })),
    [data.notifications.nodes],
  );
  const table = useReactTable({
    data: notifications,
    columns: columns(markAsInteracted, navigate, withActionColumn),
    getCoreRowModel: getCoreRowModel(),
  });

  const hasNotifications = Boolean(notifications.length);
  const EmptyBlock = emptyBlock;

  if (!hasNotifications) return <EmptyBlock />;

  const handlePrevious = () => startTransition(() => setPage((prevPage) => prevPage - 1));

  const handleNext = () => startTransition(() => setPage((prevPage) => prevPage + 1));

  return (
    <Fragment>
      <Table className="mb-6" isLoading={isPending}>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className={cell.column.id === 'type' ? 'text-center' : ''}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <SimplePagination
        page={page}
        totalPages={data.notifications.pagesCount}
        totalItems={data.notifications.nodesCount}
        limit={NOTIFICATIONS_LIMIT}
        onPrevious={handlePrevious}
        onNext={handleNext}
        isPreviousDisabled={!data.notifications.hasPreviousPage}
        isNextDisabled={!data.notifications.hasNextPage}
      />
    </Fragment>
  );
};

export default withSuspense(NotificationContainer);
