import { EscrowStatusEnum } from 'src/graphql/schema';
import { OtherEscrowStatusEnum } from 'src/shared/constants';

export const statusMap = {
  [EscrowStatusEnum.Active]: {
    label: 'Active',
    color: 'text-green-800',
    bgColor: 'bg-green-100',
  },
  [EscrowStatusEnum.Draft]: {
    label: 'Draft',
    color: 'text-orange-800',
    bgColor: 'bg-orange-100',
  },
  [EscrowStatusEnum.AdminReview]: {
    label: 'Codekeeper Review',
    color: 'text-gray-800',
    bgColor: 'bg-gray-100',
  },
  [EscrowStatusEnum.Onboarding]: {
    label: 'Review',
    color: 'text-fuchsia-800',
    bgColor: 'bg-fuchsia-100',
  },
  [EscrowStatusEnum.Signed]: {
    label: 'Signed',
    color: 'text-sky-800',
    bgColor: 'bg-sky-100',
  },
  [EscrowStatusEnum.Terminated]: {
    label: 'Terminated',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
  [EscrowStatusEnum.PendingTermination]: {
    label: 'Pending Termination',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
  [OtherEscrowStatusEnum.AwaitingSignature]: {
    label: 'Awaiting Signature',
    color: 'text-slate-800',
    bgColor: 'bg-slate-100',
  },
  [OtherEscrowStatusEnum.TerminationRequested]: {
    label: 'Termination Requested',
    color: 'text-rose-800',
    bgColor: 'bg-rose-100',
  },
};
