import { createRequiredContext } from 'src/context/createRequiredContext';

import { useOrganizationSlice } from './organizationSlice';
const [useOrganization, OrganizationProvider] = createRequiredContext<ReturnType<typeof useOrganizationSlice>>();

const OrganizationContextProvider = ({ children }: React.PropsWithChildren) => {
  const organizationSlice = useOrganizationSlice();

  const value = {
    ...organizationSlice,
  };

  return <OrganizationProvider value={value}>{children}</OrganizationProvider>;
};

export { useOrganization, OrganizationContextProvider };
