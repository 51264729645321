'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const labelVariants = cva(
  'text-sm text-foreground mb-2 font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 inline-block',
);

const Label = ({
  className,
  ...props
}: React.ComponentProps<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>) => (
  <LabelPrimitive.Root className={cn(labelVariants(), className)} {...props} />
);

export { Label };
