import { SquarePen } from 'lucide-react';
import { useSuspenseQuery } from '@apollo/client';

import { Card, CardContent } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { CompanyDocument, ICompanyQuery } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';

import { useOrganization } from '../model';

import CardHeaderWithAction from './CardHeaderWithAction';

const DetailsCard = () => {
  const { setDetailsDialogOpen } = useOrganization();
  const {
    data: { company },
  } = useSuspenseQuery<ICompanyQuery>(CompanyDocument);

  const fullAddress =
    `${company.street} ` +
    [company.streetNumber, company.city, company.postalCode, company.region, company.country]
      .filter((value) => value)
      .join(',\n');

  const onEdit = () => setDetailsDialogOpen(true);

  return (
    <Card className="mb-6">
      <CardHeaderWithAction
        title="Organization Details"
        description="View and update your organization details here. "
        action={
          company.policy.update && (
            <Button onClick={onEdit}>
              <SquarePen /> Edit
            </Button>
          )
        }
      />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-bold w-[190px]">Organization name</TableCell>
              <TableCell className="w-[85px]">:</TableCell>
              <TableCell>{company?.companyName || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-bold w-[190px]">Website</TableCell>
              <TableCell className="w-[85px]">:</TableCell>
              <TableCell>{company.companyWebsite || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-bold w-[190px]">Registration number</TableCell>
              <TableCell className="w-[85px]">:</TableCell>
              <TableCell>{company?.companyRegistration || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-bold w-[190px]">Address</TableCell>
              <TableCell className="w-[85px]">:</TableCell>
              <TableCell>{fullAddress}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withSuspense(DetailsCard);
