import { EscrowStatusFilterEnum } from 'src/graphql/schema';

export const filterOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: EscrowStatusFilterEnum.Active,
    label: 'Active',
  },
  {
    value: EscrowStatusFilterEnum.Invited,
    label: 'Invitations',
  },
  {
    value: EscrowStatusFilterEnum.Configuration,
    label: 'Configuration',
  },
  {
    value: EscrowStatusFilterEnum.Onboarding,
    label: 'Review & signature ',
  },
  {
    value: EscrowStatusFilterEnum.Signed,
    label: 'Deposits & Activation',
  },
  {
    value: EscrowStatusFilterEnum.ChangesSuggested,
    label: 'Suggested changes',
  },
  {
    value: EscrowStatusFilterEnum.TerminationRequested,
    label: 'Termination requests',
  },
];
