import { redirect } from 'react-router';

import { SaasEscrows, saasRouteElements, SaasRoutesEnum, SaasEnvironmentLayout } from 'src/pages/Saas';

import type { RouteObject } from 'react-router';

type InferPath<T> = T extends { path: infer P extends string } ? { path: `continuity-${P}` } : never;

export const saasEscrowsRoutes: RouteObject & InferPath<{ path: 'escrows' }> = {
  path: 'continuity-escrows',
  element: <SaasEscrows />,
};

export const saasSingleRoutes: RouteObject = {
  element: <SaasEnvironmentLayout />,
  path: 'continuity-escrows',
  children: [
    {
      loader: ({ params }) => {
        const { id = null, '*': splat = '' } = params;

        if (id && !splat) return redirect(`/continuity-escrows/${id}/${SaasRoutesEnum.Providers}`);

        const path = splat?.split('/').length > 1 ? splat?.split('/')[0] : splat;

        if (!Object.values(SaasRoutesEnum).includes(path as SaasRoutesEnum)) {
          return redirect('/404');
        }

        return null;
      },
      path: ':id/*',
      children: saasRouteElements,
    },
  ],
};
