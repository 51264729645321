import { startTransition } from 'react';
import { Shield } from 'lucide-react';

import {
  SoftwareDashboardItem,
  SoftwareDashboardItemFooter,
  SoftwareItemProgress,
} from 'src/components/SoftwareDashboard';
import { CustodianProfileStatusEnum } from 'src/graphql/schema';

import { statusMap, useDashboard, type IDashboardItem } from '../model';

const mainSteps = [
  {
    value: 'configuration' as const,
    activeLabel: 'Configuration',
    completedLabel: 'Configured',
  },
  {
    value: 'access' as const,
    activeLabel: 'Access setup',
    completedLabel: 'Access confirmed',
  },
  {
    value: 'activation' as const,
    activeLabel: 'Activation',
    completedLabel: 'Activated',
  },
];

const statusToActiveStep = {
  [CustodianProfileStatusEnum.Draft]: 'configuration',
  [CustodianProfileStatusEnum.AccessSetup]: 'access',
  [CustodianProfileStatusEnum.Active]: 'validated',
  [CustodianProfileStatusEnum.Terminated]: 'validated',
} as const;

const Progress = ({ status, previousStatus }: Pick<IDashboardItem, 'status' | 'previousStatus'>) => {
  return (
    <SoftwareDashboardItemFooter>
      <SoftwareItemProgress
        mainSteps={mainSteps}
        activeStep={
          statusToActiveStep[(previousStatus ? previousStatus : status) as keyof typeof statusToActiveStep] ||
          'configuration'
        }
        isDisabled={status === CustodianProfileStatusEnum.Terminated}
      />
    </SoftwareDashboardItemFooter>
  );
};

const DashboardItem = ({ id, status, company, name, previousStatus, canRemove, users }: IDashboardItem) => {
  const { setRemoveDialogOpen } = useDashboard();

  const onRemove = () =>
    startTransition(() => {
      setRemoveDialogOpen(true, id);
    });

  const Icon = (props: React.SVGProps<SVGSVGElement>) => <Shield stroke="#607A8A" {...props} />;

  return (
    <SoftwareDashboardItem
      key={id}
      id={id}
      baseUrl="/custodian-profiles"
      statusBadge={statusMap[status as keyof typeof statusMap]}
      companies={[
        {
          ...company,
          name,
          shouldFetchLogo: true,
        },
      ]}
      labelValues={['Software Custodian']}
      softwareIcon={Icon}
      footer={<Progress status={status} previousStatus={previousStatus} />}
      onRemove={canRemove ? onRemove : undefined}
      users={users}
    />
  );
};

export default DashboardItem;
