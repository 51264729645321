import { Fragment } from 'react';
import { Form, Input, Button, Spin } from 'antd';

export type IResetPasswordForm = {
  code: string;
  password: string;
};

type Props = {
  email: string;
  onFinish: (data: IResetPasswordForm) => Promise<void>;
  isLoading?: boolean;
};

const ResetPasswordForm: React.FunctionComponent<Props> = ({ email, onFinish, isLoading = false }) => {
  const [confirmationForm] = Form.useForm();

  return (
    <Fragment>
      <p className="contents">Please check your email ({email}) for the confirmation code.</p>

      <Form layout="vertical" form={confirmationForm} onFinish={onFinish}>
        <Form.Item
          name="code"
          label="Confirmation code"
          rules={[
            {
              required: true,
              message: 'Please input your confirmation code',
            },
          ]}
        >
          <Input type="tel" disabled={isLoading} />
        </Form.Item>

        <Form.Item
          name="password"
          label="New password"
          tooltip="The password must be at least 8 characters long, it must contain at least one uppercase letter, at least one digit and at least one special character."
          rules={[
            { required: true, message: 'Please input your new password' },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
              message: 'New password does not meet password policy!',
            },
          ]}
        >
          <Input type="password" disabled={isLoading} />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm password"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  // eslint-disable-next-line no-undef
                  return Promise.resolve();
                }
                // eslint-disable-next-line no-undef
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input type="password" disabled={isLoading} />
        </Form.Item>

        {isLoading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          <div className="flex justify-center">
            <Button type="primary" className="invite-login-screen__btn" htmlType="submit">
              Confirm
            </Button>
          </div>
        )}
      </Form>
    </Fragment>
  );
};

export default ResetPasswordForm;
