import { Form, Input, Switch } from 'antd';
import classNames from 'classnames';

import { SubmitButton } from 'src/components/Button';

type Props = {
  isLoading?: boolean;
  submitText: string;
  onSubmit: (values: IRepresentativeForm) => void;
  removeButton?: React.ReactNode;
  initialValues?: IRepresentativeForm;
  withSignatory?: boolean;
};

export type IRepresentativeForm = {
  name: string;
  email: string;
  phone?: string;
  signatory: boolean;
};

const RepresentativeForm = ({
  submitText,
  isLoading = false,
  onSubmit,
  removeButton,
  initialValues,
  withSignatory = true,
}: Props) => {
  const [form] = Form.useForm<IRepresentativeForm>();
  const actionClasses = classNames('flex', {
    'justify-between': removeButton,
    'justify-end': !removeButton,
  });

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical" name="validateOnly" initialValues={initialValues}>
      <h3 className="font-bold text-base text-dark-blue mb-4">Representative Details</h3>

      <Form.Item
        required={false}
        name="name"
        label="Name"
        rules={[{ required: true, type: 'string', transform: (value) => value?.trim() }]}
      >
        <Input />
      </Form.Item>
      <Form.Item required={false} name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label={
          <div className="flex items-end">
            Phone Number
            <i
              className="text-xs text-slate-grey"
              style={{
                marginLeft: 6,
                paddingBottom: 2,
              }}
            >
              optional
            </i>
          </div>
        }
        className={!withSignatory ? 'mb-8' : ''}
      >
        <Input />
      </Form.Item>

      {withSignatory && (
        <div className="border-t border-cloud-grey border-dashed py-8 mt-8">
          <h3 className="font-bold text-base text-dark-blue mb-4">Signatory</h3>
          <div className="flex gap-2">
            <Form.Item name="signatory" valuePropName="checked" noStyle>
              <Switch />
            </Form.Item>
            <div className="text-dark-blue text-sm">
              Send an e-signature request to this contact to sign the approved escrow agreement
            </div>
          </div>
        </div>
      )}
      <div className={actionClasses}>
        {removeButton}
        <SubmitButton
          form={form}
          buttonProps={{
            loading: isLoading,
          }}
        >
          {submitText}
        </SubmitButton>
      </div>
    </Form>
  );
};

export default RepresentativeForm;
