import { Outlet, useNavigate } from 'react-router';

import { withSuspense } from 'src/hoc';
import { BackupContextProvider, useBackup } from 'src/context/backup';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';
import { BackupRemoveContainer, BackupLayoutContainer } from 'src/containers/Backup';
import { BackupStatusEnum } from 'src/graphql/schema';

const RemoveBackup = () => {
  const {
    backup: { id: backupId, status },
    ui,
    setRemoveOpen,
  } = useBackup();
  const navigate = useNavigate();

  const closeModal = () => setRemoveOpen(false);

  const successCallback = () => navigate('/backups');

  return (
    <BackupRemoveContainer
      isOpen={ui.isRemoveOpen}
      backupId={backupId}
      closeModal={closeModal}
      {...(status === BackupStatusEnum.Draft && { successCallback })}
    />
  );
};

const BackupSlugLayout = () => {
  return (
    <div className="flex h-screen flex-col">
      <BackupContextProvider>
        <BackupLayoutContainer>
          <Outlet />
          <RemoveBackup />
        </BackupLayoutContainer>
      </BackupContextProvider>
    </div>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={5} />;

export default withSuspense(BackupSlugLayout, FallBack);
