import { Outlet } from 'react-router';
import { Fragment } from 'react';

import { cookieStore } from 'src/utils/cookieStore';
import { SidebarProvider } from '@/components/ui/sidebar';
import { Toaster } from '@/components/ui/sonner';

type Props = {
  sidebar: React.ElementType;
};

const Layout = ({ sidebar: Sidebar }: Props) => {
  const defaultOpen = cookieStore.get('sidebar:state') ? cookieStore.get('sidebar:state')?.value === 'true' : true;

  return (
    <Fragment>
      <div className="flex h-screen">
        <SidebarProvider
          style={
            {
              '--sidebar-width': '256px',
              '--sidebar-width-mobile': '48px',
            } as React.CSSProperties
          }
          defaultOpen={defaultOpen}
        >
          <Sidebar />

          <Outlet />
        </SidebarProvider>
      </div>

      <Toaster
        position="top-right"
        richColors
        toastOptions={{
          style: {
            fontFamily: "'Mulish', sans-serif",
          },
          classNames: {
            toast: 'p-4! bg-white! border border-border! rounded-md!',
            title: 'text-foreground! text-sm!',
            description: 'text-muted-foreground/90! text-sm!',
          },
        }}
        duration={3000}
      />
    </Fragment>
  );
};
export default Layout;
