import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';
import { OtherSaasEnvironmentStatusEnum } from 'src/shared/constants';

export const filterOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: SaasEnvironmentStatusEnum.Active,
    label: 'Active',
  },
  {
    value: SaasEnvironmentStatusEnum.Draft,
    label: 'Drafts',
  },
  {
    value: SaasEnvironmentStatusEnum.Pending,
    label: 'Access setup',
  },
  {
    value: OtherSaasEnvironmentStatusEnum.TerminationRequested,
    label: 'Pending termination',
  },
];
