import { Fragment } from 'react';
import { Button } from 'antd';
import { capitalize } from 'lodash';

import { TextBlock } from 'src/components/Misc';
import env from 'env';
import { AgreementFormEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';

import type { IRepresentativeContextProps } from './model';

import { RepresentativesTable, RepresentativeModalActions } from './ui';
import { RepresentativeContextProvider, useRepresentative } from './model';

type Props = IRepresentativeContextProps & {
  suggestedChangesNode?: React.ReactNode;
};

const agreementTypeTextMap = {
  [AgreementFormEnum.Tripartite]:
    'List the key individuals that will be acting as points of contact for this organization. These contacts will be listed in the escrow agreement.\nAssign at least one (1) representative who will be responsible for signing the agreement.',
  [AgreementFormEnum.Bipartite]:
    'List the key individuals that will be acting as points of contact for this organization.\nThese contacts will be listed in the escrow agreement.',
};

const RepresentativesContainer = ({ suggestedChangesNode }: { suggestedChangesNode?: React.ReactNode }) => {
  const { addRepresentative, role, currentPolicyRole, representatives, agreementType } = useRepresentative();

  const isBeneficiaryBipartite =
    role === EscrowPartyTypeEnum.Beneficiary && agreementType === AgreementFormEnum.Bipartite;

  return (
    <Fragment>
      <div className="flex items-center justify-between gap-3 mb-4">
        <TextBlock
          title={`${capitalize(role)} Representatives`}
          segment={!representatives.length && <i className="ck-segment--required">Required</i>}
          titleColor="dark-blue"
          as="h4"
          text={isBeneficiaryBipartite ? agreementTypeTextMap['bipartite'] : agreementTypeTextMap['tripartite']}
        />
        {currentPolicyRole?.update && (
          <Button type="primary" ghost onClick={addRepresentative}>
            + Add Representative
          </Button>
        )}
      </div>

      {!isBeneficiaryBipartite && (
        <p className="text-dark-blue text-xs mb-4">
          To make the escrow legally binding, we will be sending an e-signature request to the contacts marked as
          signatories. This will occur after all parties approve
          <br /> the escrow agreement. If an alternative signature method or a hardcopy agreement is required, please{' '}
          <a href={`mailto: ${env.CODEKEEPER_SERVICE_EMAIL}`}>contact our support</a>.
        </p>
      )}

      {suggestedChangesNode}

      <RepresentativesTable />
    </Fragment>
  );
};

const EscrowRepresentativesContainer = ({ suggestedChangesNode, ...rest }: Props) => {
  return (
    <RepresentativeContextProvider {...rest}>
      <RepresentativesContainer suggestedChangesNode={suggestedChangesNode} />
      <RepresentativeModalActions />
    </RepresentativeContextProvider>
  );
};

export default EscrowRepresentativesContainer;
