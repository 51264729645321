import { Fragment, useState } from 'react';

import { TextBlock } from 'src/components/Misc';
import { RepresentativeForm } from 'src/components/Escrow/Representative';
import { useEscrow } from 'src/context/escrow';
import { AgreementFormEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';

import { useRepresentative } from '../model';

import type { IRepresentativeForm } from 'src/components/Escrow/Representative';

type Props = {
  role: EscrowPartyTypeEnum;
};

const AddRepresentative = ({ role }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    escrow: { agreement },
  } = useEscrow();
  const { submit, setInitialData } = useRepresentative();

  const onSubmit = async (values: IRepresentativeForm) => {
    setIsLoading(true);

    try {
      await submit?.add(values);
      setInitialData();
    } finally {
      setIsLoading(false);
    }
  };

  const showSignatory =
    agreement?.agreementForm === AgreementFormEnum.Tripartite ||
    (agreement?.agreementForm === AgreementFormEnum.Bipartite && role === EscrowPartyTypeEnum.Depositor);

  return (
    <Fragment>
      <TextBlock
        title="Add Representative"
        text="This person will be listed in the escrow agreement as a point of contact."
        className="mb-8"
      />

      <RepresentativeForm
        submitText="Add Representative"
        onSubmit={onSubmit}
        withSignatory={showSignatory}
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default AddRepresentative;
