import { useNavigate } from 'react-router';

import { Button } from '@/components/ui/button';

const NewBackupAction = () => {
  const navigate = useNavigate();

  const handleCreateBackup = () => navigate('/backups/new');

  return <Button onClick={handleCreateBackup}>+ New Backup</Button>;
};

export default NewBackupAction;
