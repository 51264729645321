import { CheckCircleFilled } from '@ant-design/icons';
import { PartialDeep } from 'type-fest';

import env from 'env';
import formatPrice from 'src/utils/currency-code-to-symbol';

import type { IPlan } from 'src/graphql/schema';

import styles from './BillingPlan.module.scss';

type Props = {
  plan: PartialDeep<IPlan>;
};

export default function BillingPlan({ plan }: Props) {
  return (
    <div className={`mb-4 ${styles.section}`}>
      <div className="flex justify-between">
        <p className={styles.plan}>{plan.name}</p>
        <p className={styles.active}>
          Active <CheckCircleFilled />
        </p>
      </div>
      <p className={styles.ready}>Ready for Recovery</p>

      <p className={styles.price}>
        {formatPrice(plan.currencyCode, plan.price)}
        <span>
          /{Number(plan.period) > 1 && `${plan.period} `}
          {plan.periodUnit}
          {Number(plan.period) > 1 && 's'}
        </span>
      </p>

      <p className={styles.info}>
        If you want to change your plan please contact us at&nbsp;
        <a href={`mailto:${env.CODEKEEPER_SERVICE_EMAIL}`}>{env.CODEKEEPER_SERVICE_EMAIL}</a>
      </p>
    </div>
  );
}
