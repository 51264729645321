import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { useTOTP } from 'src/containers/TOTP';
import { Skeleton } from '@/components/ui/skeleton';

const ProfileTOTPContainer = () => {
  const { isTOTPEnabled, enableTOTP, showDisableAlert, isLoading } = useTOTP();

  const onChange = (checked: boolean) => {
    checked ? enableTOTP() : showDisableAlert();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Multi-factor authentication</CardTitle>
        <CardDescription>Add an extra layer of security to your account.</CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="h-[20px] w-30" />
        ) : (
          <div className="flex items-center space-x-2">
            <Switch id="mfa" checked={isTOTPEnabled} onCheckedChange={onChange} />
            <Label htmlFor="mfa" className="mb-0">
              {isTOTPEnabled ? 'Enabled' : 'Disabled'}
            </Label>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileTOTPContainer;
