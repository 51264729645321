import { useNavigate } from 'react-router';

import { JourneyHeader } from 'src/components/Journey';
import { labelStatusTypeEnum as statusTypeEnum } from 'src/components/Escrow/escrow.enum';
import { OtherEscrowStatusEnum } from 'src/shared/constants';
import { useEscrow } from 'src/context/escrow';
import { useClientChangesRequestedQuery, EscrowStatusEnum } from 'src/graphql/schema';

const EscrowHeaderContainer = () => {
  const { escrow } = useEscrow();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();
  const navigate = useNavigate();

  const onBack = () => navigate('/escrows');

  let status: EscrowStatusEnum | OtherEscrowStatusEnum = escrow.status;

  if (escrow?.pendingActivation) status = OtherEscrowStatusEnum.ActivationProcess;

  if (changesRequestedData?.clientChangesRequested.isRequested) status = OtherEscrowStatusEnum.ChangesRequested;

  if (escrow.status === EscrowStatusEnum.Onboarding && escrow.agreement?.approved)
    status = OtherEscrowStatusEnum.AwaitingSignature;

  if (
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow.status) &&
    escrow.escrowTermination
  )
    status = OtherEscrowStatusEnum.TerminationRequested;

  const statusLabel: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
    status && status in statusTypeEnum ? statusTypeEnum[status as keyof typeof statusTypeEnum] : undefined;
  const escrowName = [escrow.depositor?.companyName, escrow.beneficiary?.companyName].filter(Boolean).join(' - ');

  return (
    <JourneyHeader
      breadcrumbItems={[{ title: 'Escrows', path: '/escrows' }, { title: escrowName }]}
      onBack={onBack}
      statusLabel={statusLabel}
    />
  );
};

export default EscrowHeaderContainer;
