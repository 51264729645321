import { Fragment, startTransition } from 'react';
import { useSuspenseQuery, useReadQuery } from '@apollo/client';
import { Code, SearchX } from 'lucide-react';
import { useNavigate } from 'react-router';

import { getGravatarUrl } from 'src/utils/getGravatarUrl';
import { withSuspense } from 'src/hoc';
import { CompanyDocument, BackupStatusEnum, type IBackupsQuery, type ICompanyQuery } from 'src/graphql/schema';
import { BACKUPS_LIMIT } from 'src/shared/constants';
import {
  SoftwareDashboardItem,
  SoftwareDashboardSkeleton,
  SoftwareDashboardList,
} from 'src/components/SoftwareDashboard';
import { ImageBackups } from 'src/components/Svg/Images';
import { SimplePagination } from 'src/components/ui/simple-pagination';
import { EmptyBlock } from '@/components/ui/empty-block';
import { getIntegrationImage, logoMap } from 'src/utils/getIntegrationImage';

import { statusMap, useDashboard } from './model';

const BackupsContainer = () => {
  const { setPage, queryRef, page, setRemoveDialogOpen } = useDashboard();
  const navigate = useNavigate();
  const { data: dataCompany } = useSuspenseQuery<ICompanyQuery>(CompanyDocument);
  const {
    data: { backups: dataBackups },
  } = useReadQuery<IBackupsQuery>(queryRef);

  const handlePrevious = () => startTransition(() => setPage(page - 1));

  const handleNext = () => startTransition(() => setPage(page + 1));

  const onRemoveBackup = (id: string) => startTransition(() => setRemoveDialogOpen(true, id));

  return (
    <Fragment>
      {!dataBackups.nodesCount ? (
        <EmptyBlock
          title="No results found"
          description="There were no results for your search. Try another filter."
          icon={SearchX}
        />
      ) : (
        <Fragment>
          <SoftwareDashboardList>
            {dataBackups.nodes.map((backup) => {
              const { id, status, integration, users, canRemove } = getBackup(backup);
              const onAvatarClick = () => navigate(`/backups/${id}/members`);

              return (
                <SoftwareDashboardItem
                  key={id}
                  id={id}
                  baseUrl="/backups"
                  statusBadge={statusMap[status]}
                  companies={[
                    {
                      id: dataCompany.company.id,
                      name: dataCompany.company.companyName || '',
                      logo: dataCompany.company.companyWebsite || '',
                      shouldFetchLogo: true,
                    },
                    integration,
                  ]}
                  users={{
                    total: users.length,
                    nodes: users,
                  }}
                  labelValues={['Software Backup']}
                  softwareIcon={ImageBackups}
                  separator={Code}
                  onRemove={canRemove ? onRemoveBackup : undefined}
                  onAvatarClick={onAvatarClick}
                />
              );
            })}
          </SoftwareDashboardList>

          <SimplePagination
            page={page}
            totalPages={dataBackups.pagesCount}
            totalItems={dataBackups.nodesCount}
            limit={BACKUPS_LIMIT}
            onPrevious={handlePrevious}
            onNext={handleNext}
            isPreviousDisabled={!dataBackups.hasPreviousPage}
            isNextDisabled={!dataBackups.hasNextPage}
            className="py-4 sm:px-6 border-t border-border"
            hideOnSinglePage
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default withSuspense(BackupsContainer, SoftwareDashboardSkeleton);

type IBackupItem = {
  id: string;
  status: BackupStatusEnum;
  integration: {
    id: string;
    name: string;
    logo?: string;
  };
  users: {
    id: string;
    name: string;
    avatar: string;
  }[];
  canRemove: boolean;
};

function getBackup(backup: IBackupsQuery['backups']['nodes'][0]): IBackupItem {
  return {
    id: backup.id,
    status: backup.status,
    integration: {
      id: backup.integration.id,
      name: backup.integration.accountName || '',
      logo: getIntegrationImage(backup.integration.provider as keyof typeof logoMap),
    },
    users: backup.backupMembers.map((member) => ({
      id: member.id,
      name: member.user.name || '',
      avatar: getGravatarUrl(member.user.email || ''),
    })),
    canRemove: backup.status === BackupStatusEnum.Draft,
  };
}
