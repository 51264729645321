import { Fragment } from 'react';

import LostBenefitList from './LostBenefitList';
import { solutionsList } from './model/lost-benefit.enum';
import styles from './LostBenefit.module.scss';

const LostBenefit = () => {
  return (
    <Fragment>
      <LostBenefitList />

      <ul className={`flex flex-wrap list-none mb-8 ${styles['solution-list']}`}>
        {solutionsList.map(({ id, text, title }) => (
          <li key={id}>
            <div className={styles['solution-item']}>
              <h4>{title}</h4>
              <p dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default LostBenefit;
