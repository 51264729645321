import { ChevronLeft, ChevronRight } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

type Props = {
  page: number;
  totalPages: number;
  totalItems: number;
  limit: number;
  onPrevious: () => void;
  onNext: () => void;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  className?: string;
  hideOnSinglePage?: boolean;
};

const SimplePagination = ({
  page,
  totalPages,
  totalItems,
  limit,
  onPrevious,
  onNext,
  isPreviousDisabled,
  isNextDisabled,
  className,
  hideOnSinglePage = false,
}: Props) => {
  if (hideOnSinglePage && totalPages === 1) return null;

  return (
    <div className={cn('flex items-center justify-between gap-2', className)}>
      <Button variant="outline" onClick={onPrevious} disabled={isPreviousDisabled}>
        <ChevronLeft />
        Previous
      </Button>
      <div className="text-foreground text-sm">
        {page * limit - limit + 1}-{page === totalPages ? totalItems : page * limit}{' '}
        <span className="text-muted-foreground">of {totalItems}</span>
      </div>
      <Button variant="outline" onClick={onNext} disabled={isNextDisabled}>
        Next <ChevronRight />
      </Button>
    </div>
  );
};

export { SimplePagination };
