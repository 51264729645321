import { useState } from 'react';
import { Button, Form, Input } from 'antd';

import { IOauthIntegration, OauthIntegrationProviderEnum } from 'src/graphql/schema';

import useIntegrationAuth from '../../hooks/use-integration-auth';

export type IAzureForm = {
  tenantId: string;
};

type Props = {
  integration: Partial<IOauthIntegration>;
};

export default function AzureForm({ integration }: Props) {
  const { initAuth } = useIntegrationAuth(integration.provider as OauthIntegrationProviderEnum);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (options: IAzureForm) => {
    setIsLoading(true);

    try {
      await initAuth(options);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="tenantId"
        rules={[
          {
            required: true,
            message: 'Tenant ID is required',
          },
        ]}
      >
        <Input placeholder="Tenant ID" type="text" disabled={isLoading} />
      </Form.Item>

      <Button
        type="primary"
        className="flex items-center integration-modal-second__btn"
        htmlType="submit"
        loading={isLoading}
      >
        {`Connect Azure App`}
      </Button>
    </Form>
  );
}
