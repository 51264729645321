import { useEffect, useState, useTransition } from 'react';
import { debounce } from 'lodash';
import { Table, Button, Input } from 'antd';
import { useNavigate } from 'react-router';
import { PartialDeep } from 'type-fest';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useReadQuery } from '@apollo/client';
import classNames from 'classnames';

import {
  ILegacyRepo,
  IEscrow,
  LegacyRepoPlatformEnum,
  QueryOrderEnum,
  LegacyRepoMigrationStatusEnum,
} from 'src/graphql/schema';
import { useMigrations } from 'src/context/migrations';
import { LEGACY_REPOS_LIMIT } from 'src/shared/constants';

import type { IIntegrationsQuery } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';
import type { TableColumnsType } from 'antd';

import { methodLogoMap, MIGRATION_STATUS_TEXT_MAP } from './model/migrations.enum';

export type IMigrationRow = {
  id: string;
  escrow?: PartialDeep<IEscrow>;
  method?: LegacyRepoPlatformEnum;
  deposit: {
    url: string;
    platform: string;
  };
  title: string;
  status: LegacyRepoMigrationStatusEnum | '';
};

const MigrationsTableContainer = ({ queryRef }: { queryRef: QueryRef<IIntegrationsQuery> }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const {
    legacy: { count: migrationsTotal, repos: migrationsRepos, variables: legacyVariables },
    ui,
    onLegacyVariablesChange,
  } = useMigrations();
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const { data: activeIntegrations } = useReadQuery<IIntegrationsQuery>(queryRef);

  // This effect deselects all rows when failed migrating multiple assets
  useEffect(() => {
    if (ui.selection === 'none') setSelectedRowKeys([]);
  }, [ui.selection]);

  const connectedProviders = activeIntegrations.integrations.nodes.map(({ provider }) => provider);

  const columns: TableColumnsType<IMigrationRow> = [
    {
      title: 'Method',
      dataIndex: 'method',
      render: (text: keyof typeof methodLogoMap) => (
        <img className="user-migrations__logo" src={methodLogoMap[text]} alt={text} />
      ),
    },
    {
      title: 'Escrow',
      dataIndex: 'escrow',
      render: (text: IEscrow) => (
        <span>
          {text.depositor?.companyName}
          {text.beneficiary?.companyName && text.depositor?.companyName ? ' - ' : ''} {text.beneficiary?.companyName}
        </span>
      ),
    },
    {
      title: 'Deposit Title',
      dataIndex: 'title',
    },
    {
      title: 'Deposit Source',
      dataIndex: 'deposit',
      render: ({ url, platform }: IMigrationRow['deposit']) =>
        url && platform !== 'gcp' ? (
          <a className="active-homepage__content-link" href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        ) : (
          <p className="active-homepage__content-link">{url}</p>
        ),
    },
    {
      title: 'Migration Status',
      dataIndex: 'status',
      render: (text: LegacyRepoMigrationStatusEnum) => {
        const statusClasses = classNames('active-homepage__content-status', {
          '-unpaid': !text,
        });

        return <span className={statusClasses}>{MIGRATION_STATUS_TEXT_MAP[text] || 'No Configuration'}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: unknown, { id, deposit }: IMigrationRow) => {
        const isConnectedProvider = connectedProviders.includes(deposit.platform);
        const handleClick = () => {
          if (isConnectedProvider) {
            ui.selectSingle(id);
          } else navigate('/integrations');
        };

        return (
          <Button
            onClick={handleClick}
            type="primary"
            ghost={!isConnectedProvider}
            disabled={Boolean(selectedRowKeys.length) || !queryRef}
            size="small"
          >
            {isConnectedProvider ? 'Migrate' : 'Add integration'}
          </Button>
        );
      },
    },
  ];

  const updatePagination = async ({ current }: { current: number }) => {
    startTransition(() => onLegacyVariablesChange({ page: current }));
  };

  const onChangeSort = async () => {
    const orderMap = {
      [QueryOrderEnum.Asc]: QueryOrderEnum.Desc,
      [QueryOrderEnum.Desc]: QueryOrderEnum.Asc,
    };

    startTransition(() => onLegacyVariablesChange({ order: orderMap[legacyVariables!.order as QueryOrderEnum] }));
  };

  const onSearchChange = debounce(async (value: string) => {
    startTransition(() => onLegacyVariablesChange({ page: 1, queryFilter: value }));
  }, 500);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record: IMigrationRow) => ({
      disabled: !connectedProviders.includes(record.deposit.platform),
    }),
  };

  const handleMultipleMigration = () => ui.selectMultiple(selectedRowKeys as string[]);

  return (
    <div className="user-migrations__container">
      <div className="flex items-center justify-between mb-4">
        <Input
          placeholder="Search repository"
          className="w-[256px]!"
          prefix={<SearchOutlined />}
          onChange={(e) => onSearchChange(e.target.value)}
          allowClear
        />

        <div className="flex">
          {Boolean(selectedRowKeys.length) && (
            <Button type="primary" onClick={handleMultipleMigration}>
              Migrate {selectedRowKeys.length} assets
            </Button>
          )}

          <Button className="ml-2" onClick={onChangeSort}>
            {legacyVariables?.order === QueryOrderEnum.Asc ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
          </Button>
        </div>
      </div>

      <Table
        loading={isPending}
        onChange={({ current }) => updatePagination({ current: Number(current) })}
        rowSelection={rowSelection}
        pagination={{
          current: legacyVariables?.page || 1,
          pageSize: LEGACY_REPOS_LIMIT,
          total: migrationsTotal,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        columns={columns}
        dataSource={migrationsRepos.map(getMigration)}
        scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
        rowKey="id"
      />
    </div>
  );
};

function getMigration(item: PartialDeep<ILegacyRepo>): IMigrationRow {
  return {
    id: item.id || '',
    escrow: item.escrow,
    method: item.platform,
    deposit: {
      url: item.url || '',
      platform: item.platform || '',
    },
    title: item.title || '',
    status: item.migrationStatus || '',
  };
}

export default MigrationsTableContainer;
