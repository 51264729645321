export const notificationTypeMap = {
  Escrow: {
    bgBadge: 'bg-secondary',
    badge: 'Software Escrow',
    baseUrl: '/escrows',
  },
  SaasEnvironment: {
    bgBadge: 'bg-accent-software-custodian',
    badge: 'Continuity Escrow',
    baseUrl: '/continuity-escrows',
  },
  CustodianProfile: {
    bgBadge: 'bg-muted-foreground',
    badge: 'Software Custodian',
    baseUrl: '/custodian-profiles',
  },
};
