import React, { Fragment } from 'react';
import { Select, Row } from 'antd';
import { capitalize } from 'lodash';

import { TextBlock } from 'src/components/Misc';
import {
  DisruptionNotificationEnum,
  DisruptionNotificationGracePeriodEnum,
  EscrowPartyTypeEnum,
  InputMaybe,
} from 'src/graphql/schema';

import { disruptedNotificationsOptions, gracePeriodOptions } from '../escrowNotifications.enum';
import styles from '../../Escrow.module.scss';

type Props = {
  partyType: EscrowPartyTypeEnum;
  selectedDisruptionNotification?: InputMaybe<DisruptionNotificationEnum>;
  selectedGracePeriod?: InputMaybe<DisruptionNotificationGracePeriodEnum>;
  onChangeDisruptionNotification: (value: DisruptionNotificationEnum) => void;
  onChangeGracePeriod: (value: DisruptionNotificationGracePeriodEnum | 'none') => void;
  textBlock: {
    as: 'h2' | 'h4';
    titleColor?: 'light-blue' | 'dark-blue';
  };
};

const DisruptedNotificationSelect: React.FunctionComponent<Props> = ({
  partyType,
  selectedDisruptionNotification,
  selectedGracePeriod,
  onChangeDisruptionNotification,
  onChangeGracePeriod,
  textBlock,
}) => {
  const isGracePeriodDisabled =
    selectedDisruptionNotification === DisruptionNotificationEnum.NoDistruptionNotifications;

  return (
    <Fragment>
      <TextBlock
        className={styles['mb--20']}
        as={textBlock.as}
        title={`${capitalize(partyType)} Disrupted Connection Notifications`}
        text={`Select to send notifications to the ${partyType} if the connection to an automatically synced asset is disrupted, and syncing cannot take place.`}
        titleColor={textBlock.titleColor}
      />
      <Row wrap={false}>
        <div className={styles['select-form-item']}>
          <Select
            placeholder="Select notification"
            className="w-full"
            value={selectedDisruptionNotification}
            options={disruptedNotificationsOptions}
            onChange={onChangeDisruptionNotification}
          />
        </div>
        <div className={styles['select-grace-form-item']}>
          <Select
            placeholder="Select grace period"
            className="w-full"
            disabled={isGracePeriodDisabled}
            onChange={onChangeGracePeriod}
            value={setGracePeriodOption(selectedDisruptionNotification, selectedGracePeriod)}
            options={gracePeriodOptions}
          />
        </div>
      </Row>
    </Fragment>
  );
};

export default DisruptedNotificationSelect;

function setGracePeriodOption(
  disruptedValue?: InputMaybe<DisruptionNotificationEnum>,
  gracePeriodValue?: InputMaybe<DisruptionNotificationGracePeriodEnum>,
) {
  if (disruptedValue === DisruptionNotificationEnum.ImmediateNotification) {
    if (gracePeriodValue) return gracePeriodValue;
    else return 'none';
  } else return undefined;
}
