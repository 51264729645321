import { Link } from 'react-router';
import { ArrowRight, Dot, XIcon } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';

import { Card } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { getCompanyLogoUrl } from 'src/utils/getCompanyLogoUrl';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';
import { AvatarGroup } from '@/components/ui/avatar-group';

type Props = {
  id: string;
  footer?: React.ReactNode;
  statusBadge: {
    label: string;
    color: string;
    bgColor: string;
  };
  users?: {
    total: number;
    nodes: {
      name: string;
      avatar: string;
    }[];
  };
  companies: {
    id: string;
    name: string;
    logo?: string;
    shouldFetchLogo?: boolean;
  }[];
  labelValues: string[];
  softwareIcon:
    | React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>
    | ((props: React.SVGProps<SVGSVGElement>) => React.JSX.Element);
  separator?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  baseUrl: string;
  onClick?: (id: string) => void;
  onRemove?: (id: string) => void;
  onAvatarClick?: () => void;
};

export const SoftwareDashboardItemFooter = ({ children }: { children: React.ReactNode }) => {
  return <div className="py-4 px-4 sm:px-6 border-t border-border">{children}</div>;
};

export const SoftwareDashboardItemSkeleton = () => {
  return (
    <Card className="border-muted p-4 sm:p-6">
      <div className="flex justify-between items-center w-full mb-2">
        <Skeleton className="h-5 w-14" />
        <div className="flex">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="relative bg-white size-5 rounded-full not-last:-mr-2 border border-white">
              <Skeleton className="size-full rounded-full" key={index} />
            </div>
          ))}
        </div>
      </div>
      <div className="py-2.5 mb-2">
        <Skeleton className="h-6 w-[80%]" />
      </div>
      <Skeleton className="h-4 max-w-[120px]" />
    </Card>
  );
};

const ContentWrapper = ({
  children,
  onClick,
  baseUrl,
  id,
}: React.PropsWithChildren<Pick<Props, 'baseUrl' | 'onClick' | 'id'>>) => {
  const handleClick = () => onClick?.(id);

  if (onClick)
    return (
      <div onClick={handleClick} className="cursor-pointer">
        {children}
      </div>
    );

  return <Link to={`${baseUrl}/${id}`}>{children}</Link>;
};

const CompanyLogo = ({ logo, name, shouldFetchLogo }: Props['companies'][number]) => {
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchLogo = async () => {
      const fetchedLogo = shouldFetchLogo && logo ? await getCompanyLogoUrl(logo) : '';
      setLogoUrl(fetchedLogo || logo);
    };

    fetchLogo();
  }, [logo, shouldFetchLogo]);

  return (
    <Avatar className="size-6 border border-muted bg-muted rounded-sm">
      <AvatarImage src={logoUrl} />
      <AvatarFallback className="text-foreground uppercase">{name.charAt(0)}</AvatarFallback>
    </Avatar>
  );
};

const SoftwareDashboardItem = ({
  id,
  footer,
  statusBadge,
  users = { total: 0, nodes: [] },
  companies,
  labelValues,
  softwareIcon,
  separator = ArrowRight,
  baseUrl,
  onClick,
  onRemove,
  onAvatarClick,
}: Props) => {
  const labels = labelValues.map((value, index) => (
    <Fragment key={value}>
      <div className="text-xs font-bold text-primary">{value}</div>
      {index !== labelValues.length - 1 && <Dot className="text-muted-foreground w-4 h-4" />}
    </Fragment>
  ));

  const SoftwareIcon = softwareIcon;
  const Separator = separator;

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onRemove?.(id);
  };

  const userAvatars = users.nodes.map(({ avatar, name }) => ({
    src: avatar,
    fallback: name,
  }));

  return (
    <Card className="border-muted">
      <ContentWrapper baseUrl={baseUrl} id={id} onClick={onClick}>
        <div className="p-4 sm:p-6">
          <div className="flex items-center justify-between mb-5">
            <Badge className={cn(statusBadge.bgColor, statusBadge.color)}>{statusBadge.label}</Badge>

            {onRemove ? (
              <Button variant="ghost" size="icon" className="size-5" onClick={handleRemove}>
                <XIcon className="size-4" />
              </Button>
            ) : (
              Boolean(userAvatars) && (
                <AvatarGroup avatars={userAvatars} max={5} total={users.total} onAvatarClick={onAvatarClick} />
              )
            )}
          </div>

          <div className="flex flex-wrap sm:flex-nowrap items-center gap-3 mb-5">
            {companies.map((company, index) => (
              <Fragment key={company.id}>
                <div className="flex items-center gap-3">
                  <CompanyLogo {...company} />
                  <div className="text-base font-bold text-foreground leading-[1.2] line-clamp-2">{company.name}</div>
                </div>
                {index !== companies.length - 1 && <Separator className="w-5 h-5 text-muted-foreground shrink-0" />}
              </Fragment>
            ))}
          </div>

          <div className="flex items-center gap-1.5">
            <SoftwareIcon className="w-4 h-4" />
            <div className="flex items-center gap-1">{labels}</div>
          </div>
        </div>

        {footer}
      </ContentWrapper>
    </Card>
  );
};

export default SoftwareDashboardItem;
