export const logoMap = {
  azure: '/assets/integrations/logo-azure.svg',
  github: '/assets/integrations/logo-github.svg',
  github_server: '/assets/integrations/logo-gitlab.svg',
  gitlab: '/assets/integrations/logo-gitlab.svg',
  gitlab_server: '/assets/integrations/logo-gitlab.svg',
  bitbucket: '/assets/integrations/logo-bitbucket.svg',
  bitbucket_server: '/assets/integrations/logo-bitbucket.svg',
  codecommit: '/assets/integrations/logo-codecommit.svg',
  share_file_https: '/assets/integrations/logo-sharefile.svg',
  gcp: '/assets/integrations/logo-gcp.svg',
  aws: '/assets/integrations/logo-aws.svg',
  beanstalk: '/assets/integrations/logo-beanstalk.svg',
  azure_devops: '/assets/integrations/logo-azure-devops.svg',
};

export const getIntegrationImage = (provider: keyof typeof logoMap) => {
  return logoMap[provider];
};
