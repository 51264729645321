export const eventTypes = [
  'escrow_invitation_sent',
  'agreement_sent_for_review_beneficiary',
  'agreement_sent_for_review_depositor',
  'signatures_requested',
  'escrow_signed',
  'suggested_change_created',
  'escrow_termination_requested',
  'saas_environment_activation_requested',
  'saas_environment_termination_requested',
  'custodian_profile_sent_for_review',
  'custodian_termination_requested',
] as const;

type IInvitationSentPayload = {
  inviting_company_name: string;
  party_type: string;
};

type ISuggestedChangeCreatedPayload = {
  requester_party: string;
  requester_company_name: string;
};

type IEscrowTerminationRequestedPayload = {
  requester_party: string;
  requester_company_name: string;
};

type IPayload = IInvitationSentPayload | ISuggestedChangeCreatedPayload | IEscrowTerminationRequestedPayload;

export const getNotificationContent = (
  eventType: (typeof eventTypes)[number],
  payload?: IPayload,
): {
  title: string;
  description: string;
} | null => {
  switch (eventType) {
    case 'escrow_invitation_sent': {
      const { inviting_company_name } = payload as IInvitationSentPayload;

      return {
        title: 'You’ve been invited to collaborate on an escrow',
        description: `You've been invited to collaborate on an escrow agreement by ${inviting_company_name}.`,
      };
    }
    case 'agreement_sent_for_review_beneficiary':
    case 'agreement_sent_for_review_depositor':
      return {
        title: 'Your escrow agreement is ready for review',
        description:
          'Your escrow agreement is ready to be reviewed. Please review these terms before approving the agreement for signature.',
      };
    case 'signatures_requested':
      return {
        title: 'Escrow agreement sent for signature',
        description:
          'An e-signature request has been sent to the signatories listed in the escrow. Please check the spam folder if nothing has arrived.',
      };
    case 'escrow_signed':
      return {
        title: 'Deposit assets to activate your escrow',
        description:
          'Your escrow agreement has been signed by all parties. To activate it, please proceed with connecting the assets to escrow.',
      };
    case 'suggested_change_created': {
      const { requester_company_name } = payload as ISuggestedChangeCreatedPayload;

      return {
        title: 'Review suggested changes',
        description: `${requester_company_name} has suggested changes to your escrow. Please review their suggestions before applying or rejecting these changes.`,
      };
    }
    case 'escrow_termination_requested': {
      const { requester_company_name } = payload as IEscrowTerminationRequestedPayload;

      return {
        title: 'Escrow termination requested',
        description: `${requester_company_name} has requested to terminate the escrow. Please review the escrow to confirm whether you approve of this request.`,
      };
    }
    case 'saas_environment_activation_requested':
      return {
        title: 'Complete provider account access setup',
        description:
          'Codekeeper will send email instructions to set up access to your provider accounts. Please be on the lookout for this email, and follow these steps to activate your Continuity Escrow.',
      };
    case 'saas_environment_termination_requested':
      return {
        title: 'Revoke provider account access',
        description:
          "You’ve requested to terminate this escrow. To proceed, please revoke Codekeeper's access to all your provider accounts.",
      };
    case 'custodian_profile_sent_for_review':
      return {
        title: 'Complete provider account access setup',
        description:
          'Codekeeper will send email instructions to set up access to your provider accounts. Please be on the lookout for this email, and follow these steps to activate your Custodian Profile.',
      };
    case 'custodian_termination_requested':
      return {
        title: 'Revoke provider account access',
        description:
          "You’ve requested to terminate this Custodian Profile. To proceed, please revoke Codekeeper's access to all your provider accounts.",
      };
    default:
      return null;
  }
};
