import { toast } from 'sonner';
import { useFragment } from '@apollo/client';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { type IUser, useRemoveUserMutation, UsersDocument } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

import { useUsers, UserFragment } from '../model';

const RemoveDialog = () => {
  const {
    ui: { removeDialogOpen, userId, closeRemoveDialog },
  } = useUsers();
  const [removeUser, { loading }] = useRemoveUserMutation({ refetchQueries: [UsersDocument] });

  const user = useFragment<IUser>({
    fragment: UserFragment,
    from: {
      __typename: 'User',
      id: userId || '',
    },
  });

  if (!user) return null;

  const onRemoveUser = async () => {
    if (!userId) return;

    try {
      const { data } = await removeUser({
        variables: {
          userId,
        },
      });
      if (data?.removeUser?.success) {
        toast.success('Team member removed!', {
          description: 'Their access to your organization’s Codekeeper account has been revoked.',
        });
        closeRemoveDialog();
      } else {
        throw new ClientError(data?.removeUser?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, toast.error);
    }
  };

  const onOpenChange = (open: boolean) => {
    if (!open) closeRemoveDialog();
  };

  return (
    <AlertDialog open={removeDialogOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to remove {user.data.name || user.data.email}?</AlertDialogTitle>
          <AlertDialogDescription>
            They will be deleted as a Codekeeper user, and will no longer be able to access the application.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onRemoveUser} isLoading={loading}>
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveDialog;
