import { UsersContextProvider } from './model';
import { UsersCard, UserDialogManager } from './ui';

const UsersContainer = () => {
  return (
    <UsersContextProvider>
      <UsersCard />
      <UserDialogManager />
    </UsersContextProvider>
  );
};

export default UsersContainer;
