import { capitalize } from 'lodash';

import { type IEscrowsQuery, EscrowPartyTypeEnum, EscrowStatusEnum, InvitationStatusEnum } from 'src/graphql/schema';
import { OtherEscrowStatusEnum } from 'src/shared/constants';
import { getGravatarUrl } from 'src/utils/getGravatarUrl';

import { type IDashboardItem } from '../model';

import { setTrackToStatus } from './setTrackToStatus';

const counterpartyType = {
  [EscrowPartyTypeEnum.Beneficiary]: EscrowPartyTypeEnum.Depositor,
  [EscrowPartyTypeEnum.Depositor]: EscrowPartyTypeEnum.Beneficiary,
};

export function getEscrow(escrow: IEscrowsQuery['escrows']['nodes'][0]): IDashboardItem {
  const startedParty = escrow[escrow.startedBy]!;
  const escrowType = escrow.agreement?.agreementForm ?? '';
  const parties = [
    {
      id: startedParty.id,
      name: startedParty.companyName ?? '',
      logo: startedParty.companyWebsite ?? '',
      shouldFetchLogo: true,
    },
  ];
  const counterparty = escrow[counterpartyType[escrow.startedBy]];

  if (counterparty)
    parties.push({
      id: counterparty.id,
      name: counterparty.companyName ?? '',
      logo: counterparty.companyWebsite ?? '',
      shouldFetchLogo: true,
    });

  let status: EscrowStatusEnum | OtherEscrowStatusEnum = escrow.status;

  if (escrow.status === EscrowStatusEnum.Onboarding && escrow.agreement?.approved)
    status = OtherEscrowStatusEnum.AwaitingSignature;

  if (
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow.status) &&
    escrow.escrowTermination
  )
    status = OtherEscrowStatusEnum.TerminationRequested;

  const members =
    escrow[escrow.currentCompanyRole!]?.escrowMembers?.map(({ id, user }) => ({
      id,
      name: user.name || user.email,
      avatar: getGravatarUrl(user.email),
    })) ?? [];

  const previousStatus = {
    [OtherEscrowStatusEnum.TerminationRequested]: escrow.status,
    [EscrowStatusEnum.PendingTermination]: setTrackToStatus(escrow),
  };

  return {
    id: escrow.id,
    type: capitalize(escrowType),
    status,
    previousStatus: previousStatus[status as keyof typeof previousStatus] ?? null,
    parties,
    members,
    invitationId:
      escrow.escrowInvitation?.status === InvitationStatusEnum.Pending && escrow.escrowInvitation.policy.accept
        ? escrow.escrowInvitation.id
        : undefined,
  };
}
