import { Outlet, createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { ConfigProvider, App as AntdApp, unstableSetRender } from 'antd';
import { createRoot } from 'react-dom/client';

import { apolloClient } from 'src/graphql';
import { LayoutContainer as Layout } from 'src/containers/Layout';

import { theme } from '../config/antd.config.ts';

import { buildProvidersTree } from './utils/buildProvidersTree';
import {
  authRoutes,
  profileRoutes,
  escrowRoutes,
  escrowSingleRoutes,
  invitationsRoutes,
  mainRoutes,
  saasEscrowsRoutes,
  saasSingleRoutes,
  backupsRoutes,
  backupSingleRoutes,
  custodianRoute,
  custodianSingleRoutes,
} from './routes';
import { AuthProvider } from './context/auth/use-auth';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { NotFound } from './components/404';
import { ErrorBoundary, Message } from './components/Misc';

import './assets/styles/app.scss';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

unstableSetRender((node, container) => {
  // @ts-expect-error ant design
  container._reactRoot ||= createRoot(container);
  // @ts-expect-error ant design
  const root = container._reactRoot;
  root.render(node);
  return async () => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    root.unmount();
  };
});

const router = sentryCreateBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    ),
    children: [
      { ...authRoutes },
      { ...invitationsRoutes },
      // Protected routes with main layout
      {
        element: (
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          { ...mainRoutes },
          { ...profileRoutes },
          { ...escrowRoutes },
          { ...saasEscrowsRoutes },
          { ...backupsRoutes },
          { ...custodianRoute },
        ],
      },
      // Protected routes with custom layout
      {
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
        children: [custodianSingleRoutes, backupSingleRoutes, escrowSingleRoutes, saasSingleRoutes],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '404',
    element: <NotFound />,
  },
]);

const AntdWrapper = ({ children }: React.PropsWithChildren) => (
  <AntdApp>
    <Message />
    {children}
  </AntdApp>
);

const ProvidersTree = buildProvidersTree([
  [ApolloProvider, { client: apolloClient }],
  [ConfigProvider, { theme }],
  [AntdWrapper],
  [RouterProvider, { router }],
]);

const App = () => {
  // react-router crashes when there are multiple slashes in the URL
  // This is a workaround to prevent this crash, but it's not a perfect solution
  // TODO: Find a better solution later
  useEffect(() => {
    const { pathname } = window.location;

    if (pathname.match(/\/{2,}/)) window.location.replace(pathname.replace(/\/{2,}/g, '/'));
  }, []);

  return <ProvidersTree />;
};

export default App;
