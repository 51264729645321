import { useState, useRef } from 'react';
import Axios from 'axios';
import * as Sentry from '@sentry/react';

import { message } from 'src/components/Misc';
import {
  useInitSecureUploadMutation,
  useCompleteSecureUploadMutation,
  useCancelSecureUploadMutation,
  IInitSecureUploadPayload,
  IInitSecureUploadMutation,
} from 'src/graphql/schema/index.ts';

import type { UploadFile } from 'antd/lib/upload/interface';
import type { SetNonNullable } from 'type-fest';
import type { AxiosResponse, CancelToken } from 'axios';

const FILE_CHUNK_SIZE = 10000000;

type IUploadResponse = {
  etag: string;
  partNumber: number;
};

const useSecureUpload = (escrowId: string) => {
  const [initSecureUpload] = useInitSecureUploadMutation({
    onCompleted: () =>
      setUploadProgress((upload) => ({
        ...upload,
        percent: 0,
      })),
  });
  const [completeSecureUpload] = useCompleteSecureUploadMutation();
  const [cancelSecureUpload] = useCancelSecureUploadMutation();
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadIdsInProgress, setUploadIdsInProgress] = useState<string[]>([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    percent: 0,
    currentCount: 0,
  });
  const cancelTokenSource = useRef<{
    token: CancelToken;
    cancel: (message: string) => void;
  } | null>(null);

  const secureUpload = async (uploadFile: UploadFile) => {
    const file = uploadFile as IUploadFile;
    const numParts = file.size ? Math.ceil(file.size / FILE_CHUNK_SIZE) : 1;
    const { name: filename } = file;

    setUploadStatus((status) => {
      return { ...status, [file.name]: 'uploading' };
    });

    const { data, data: { initSecureUpload: { uploadId = '' } = {} as IInitSecureUploadPayload } = {} } =
      (await initSecureUpload({
        variables: {
          escrowId,
          numParts,
          filename,
        },
      })) as { data: SetNonNullable<IInitSecureUploadMutation, 'initSecureUpload'> };

    if (uploadId) setUploadIdsInProgress((uploadIds) => [...uploadIds, uploadId]);

    const axios = Axios.create();
    delete axios.defaults.headers.put['Content-Type'];

    const uploadUrls = data.initSecureUpload?.uploadUrls;
    const keys = Object.keys(uploadUrls || {});
    const promises: Promise<AxiosResponse<IUploadResponse>>[] = [];
    let loadedParts = 0;

    for (const indexStr of keys) {
      const index = parseInt(indexStr);
      const start = index * FILE_CHUNK_SIZE;
      const end = (index + 1) * FILE_CHUNK_SIZE;
      const blob = index < keys.length ? file.slice(start, end) : file.slice(start);
      const uploadUrl = uploadUrls?.[index] ?? '';

      if (!uploadUrl) return;

      promises.push(
        axios
          .put(uploadUrl, blob, {
            cancelToken: cancelTokenSource.current?.['token'],
            ...(numParts === 1 && {
              onUploadProgress: ({ progress = 0 }) => {
                const percent = Math.floor(progress * 100);
                setUploadProgress((upload) => ({ ...upload, percent }));
              },
            }),
          })
          .then((res) => {
            if (numParts) {
              loadedParts++;

              setUploadProgress((upload) => ({
                ...upload,
                percent: Math.floor((loadedParts / numParts) * 100),
              }));
            }

            return res;
          }),
      );
    }

    try {
      const resParts = await Promise.all(promises);

      const parts = resParts.map((part: AxiosResponse, index: number) => ({
        etag: part.headers.etag,
        partNumber: index + 1,
      }));

      await completeSecureUpload({
        variables: {
          escrowId,
          uploadId: uploadId || '',
          filename,
          parts,
          size: file.size,
        },
      });
    } catch (e) {
      Sentry.captureException(e);

      await cancelSecureUpload({
        variables: {
          escrowId,
          uploadId: uploadId || '',
        },
      });

      throw e;
    } finally {
      setUploadIdsInProgress((uploadIds) => uploadIds.filter((uid) => uid !== uploadId));
    }

    setUploadStatus((status) => {
      return { ...status, [file.name]: 'uploaded' };
    });
  };

  const cancelUpload = () => {
    cancelTokenSource.current?.cancel('Operation canceled by the user.');

    setUploadProgress({
      percent: 0,
      currentCount: 0,
    });

    uploadIdsInProgress.forEach((uploadId) =>
      cancelSecureUpload({
        variables: {
          escrowId,
          uploadId,
        },
      }),
    );
  };

  const uploadFiles = async (files: UploadFile[]) => {
    if (!escrowId) return;
    if (!files.length) return;

    setUploadCompleted(false);
    setUploadStatus(Object.fromEntries(files.map((file) => [file.name, 'new'])));
    setUploadProgress({
      percent: 0,
      currentCount: 1,
    });
    cancelTokenSource.current = Axios.CancelToken.source();

    try {
      for (const file of files) {
        await secureUpload(file);
        setUploadProgress((upload) => ({
          ...upload,
          currentCount: upload.currentCount === files.length ? files.length : upload.currentCount + 1,
        }));
      }

      setUploadCompleted(true);
      message.success('Your assets are successfully added to this escrow');
    } finally {
      setUploadProgress({
        percent: 0,
        currentCount: 0,
      });
    }
  };

  return [uploadFiles, cancelUpload, uploadStatus, uploadCompleted, uploadProgress] as const;
};

export default useSecureUpload;
