import { ProfilePage, ProfileLayout } from '../pages/Profile';
import Invitations from '../components/Invitations/Invitations';
import FeedbackPage from '../pages/Feedback/FeedbackPage';
import ActivityPage from '../pages/Activity/ActivityPage';

import type { RouteObject } from 'react-router';

export const profileRoutes: RouteObject = {
  element: <ProfileLayout />,
  children: [
    {
      path: '/profile',
      element: <ProfilePage />,
    },
    {
      path: '/activity',
      element: <ActivityPage />,
    },
    {
      path: '/invitations',
      element: <Invitations />,
    },
    {
      path: '/feedback',
      element: <FeedbackPage />,
    },
  ],
};
