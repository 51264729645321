import { startTransition } from 'react';
import { useNavigate } from 'react-router';

import {
  SoftwareDashboardItem,
  SoftwareDashboardItemFooter,
  SoftwareItemProgress,
} from 'src/components/SoftwareDashboard';
import { ImageEscrow } from 'src/components/Svg/Images';
import { EscrowStatusEnum } from 'src/graphql/schema';
import { OtherEscrowStatusEnum } from 'src/shared/constants';

import { statusMap, useDashboard, type IDashboardItem } from '../model';

const mainSteps = [
  {
    value: 'configuration' as const,
    activeLabel: 'Configuration',
    completedLabel: 'Configured',
  },
  {
    value: 'review' as const,
    activeLabel: 'Review',
    completedLabel: 'Reviewed',
  },
  {
    value: 'signature' as const,
    activeLabel: 'Signature',
    completedLabel: 'Signed',
  },
  {
    value: 'activation' as const,
    activeLabel: 'Activation',
    completedLabel: 'Activated',
  },
];

const statusToActiveStep = {
  [EscrowStatusEnum.Draft]: 'configuration',
  [EscrowStatusEnum.Active]: 'validated',
  [EscrowStatusEnum.Onboarding]: 'review',
  [EscrowStatusEnum.Signed]: 'activation',
  [EscrowStatusEnum.Terminated]: 'activation',
  [EscrowStatusEnum.AdminReview]: 'configuration',
  [EscrowStatusEnum.PendingTermination]: 'validated',
  [OtherEscrowStatusEnum.AwaitingSignature]: 'signature',
  [OtherEscrowStatusEnum.TerminationRequested]: 'activation',
} as const;

const Progress = ({ status, previousStatus }: Pick<IDashboardItem, 'status' | 'previousStatus'>) => {
  const isTerminated = status === EscrowStatusEnum.Terminated;

  return (
    <SoftwareDashboardItemFooter>
      <SoftwareItemProgress
        mainSteps={mainSteps}
        activeStep={
          statusToActiveStep[(previousStatus ? previousStatus : status) as keyof typeof statusToActiveStep] ||
          'configuration'
        }
        isDisabled={isTerminated}
      />
    </SoftwareDashboardItemFooter>
  );
};

const DashboardItem = ({ id, status, parties, type, previousStatus, invitationId, members }: IDashboardItem) => {
  const navigate = useNavigate();
  const { setInvitationDialogOpen, setRemoveDialogOpen } = useDashboard();

  const handleClick = () => {
    startTransition(() => {
      if (invitationId) {
        setInvitationDialogOpen(true, invitationId);
      }
    });
  };

  const onRemove = () =>
    startTransition(() => {
      setRemoveDialogOpen(true, id);
    });

  const onAvatarClick = () => navigate(`/escrows/${id}/members`);

  return (
    <SoftwareDashboardItem
      key={id}
      id={id}
      baseUrl="/escrows"
      statusBadge={statusMap[status as keyof typeof statusMap]}
      companies={parties}
      users={{
        total: members.length,
        nodes: members,
      }}
      labelValues={['Software Escrow', type]}
      softwareIcon={ImageEscrow}
      footer={<Progress status={status} previousStatus={previousStatus} />}
      onClick={invitationId ? handleClick : undefined}
      onRemove={status === EscrowStatusEnum.Draft ? onRemove : undefined}
      onAvatarClick={!invitationId ? onAvatarClick : undefined}
    />
  );
};

export default DashboardItem;
