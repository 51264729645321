import React from 'react';
import { Select } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { storageRegionOptions } from 'src/shared/entities';

import styles from '../../Escrow.module.scss';

type Props = {
  selectedRegion?: string;
  onChange: (value: string) => void;
};

const StorageRegionSelect: React.FunctionComponent<Props> = ({ selectedRegion, onChange }) => {
  return (
    <div className={styles.block}>
      <TextBlock
        className={styles['mb--20']}
        title="Select region"
        text="Should deposits be limited to a specific data storage region? (needs Advanced Deposits)"
      />

      <div className={styles['select-form-item']}>
        <Select
          className="w-full"
          placeholder="Select region"
          value={selectedRegion}
          options={storageRegionOptions}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default StorageRegionSelect;
