import { useReadQuery } from '@apollo/client';

import { SoftwareDashboardLayout } from 'src/components/SoftwareDashboard';
import {
  SaasEscrowsContainer,
  useDashboard,
  DashboardContextProvider,
  filterOptions,
} from 'src/containers/SaasEscrows';
import { Main } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';
import { type ISaasEnvironmentsQuery } from 'src/graphql/schema';

import { Intro, CreateNewEnvironmentContainer, RemoveDialog } from './ui';

const Dashboard = () => {
  const { setQuery, queryRef, status, query, setStatusFilter } = useDashboard();
  const {
    data: { saasEnvironments },
  } = useReadQuery<ISaasEnvironmentsQuery>(queryRef);

  const isEmpty = !saasEnvironments.nodesCount && status === 'all' && !query;

  return (
    <Main title="Continuity Escrow" noPaddingOnContent>
      <SoftwareDashboardLayout
        title="Escrows"
        createAction={<CreateNewEnvironmentContainer />}
        onSearch={!isEmpty ? setQuery : undefined}
        filterOptions={filterOptions}
        activeFilter={status}
        setActiveFilter={!isEmpty ? setStatusFilter : undefined}
      >
        {isEmpty ? <Intro /> : <SaasEscrowsContainer />}
      </SoftwareDashboardLayout>
    </Main>
  );
};

const SaasEscrows = () => {
  usePageMeta('Continuity Escrow');

  return (
    <DashboardContextProvider>
      <Dashboard />
      <RemoveDialog />
    </DashboardContextProvider>
  );
};

export default SaasEscrows;
