import { ExternalLink } from 'lucide-react';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

const SurveyCard = () => {
  const onSurveyClick = () => window.open('https://codekeeper.co/customer-onboarding-feedback', '_blank');

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Rate your onboarding experience</CardTitle>
        <CardDescription>
          We’re committed to making our services better, and your feedback plays a crucial role in this effort.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button onClick={onSurveyClick}>
          Take the survey <ExternalLink />
        </Button>
      </CardContent>
    </Card>
  );
};

export default SurveyCard;
