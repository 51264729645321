type Props = {
  title: string;
};

const PageHeader = ({ title }: Props) => (
  <div className="py-4 px-6 border-t border-border">
    <div className="container mx-auto">
      <h1 className="text-2xl text-foreground">{title}</h1>
    </div>
  </div>
);

export default PageHeader;
