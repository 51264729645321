import { message, ModalProceed } from 'src/components/Misc';
import { BackupsDocument, useRemoveBackupMutation } from 'src/graphql/schema';

import type { PartialDeep } from 'type-fest';
import type { IBackup } from 'src/graphql/schema';

type Props = {
  isOpen: boolean;
  backupId: string;
  successCallback?: (data: PartialDeep<IBackup>) => void;
  closeModal: () => void;
};

const BackupRemoveContainer = ({ isOpen, backupId, successCallback, closeModal }: Props) => {
  const [removeBackup, { loading: removing }] = useRemoveBackupMutation({
    refetchQueries: [BackupsDocument],
  });

  const onRemove = async () => {
    try {
      const { data } = await removeBackup({
        variables: {
          id: backupId,
        },
      });

      if (data?.removeBackup?.success) {
        message.success('Backup has been removed');
        closeModal();
        successCallback?.(data.removeBackup.backup as PartialDeep<IBackup>);
      } else {
        throw new Error(data?.removeBackup?.errors?.[0]);
      }
    } catch (error) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong');
    }
  };

  return (
    <ModalProceed
      isOpen={isOpen}
      title="Are you sure you want to remove this backup?"
      text="By confirming, you will be removing all backup versions from our secure storage."
      isLoading={removing}
      onCancel={closeModal}
      onProceed={onRemove}
      proceedText="Remove Backup"
      proceedButtonProps={{
        danger: true,
        ghost: true,
      }}
    />
  );
};

export default BackupRemoveContainer;
