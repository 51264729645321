import { usePageMeta } from 'src/hooks';
import { ProfileContainer } from 'src/containers/Profile';

export default function Profile() {
  usePageMeta('Profile');

  return (
    <div className="container mx-auto">
      <ProfileContainer />
    </div>
  );
}
